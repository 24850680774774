import {removeNaNs, spreadDigits} from '@/js/main';
import {ema as emaFunction} from '@/js/am4charts-plugins/am4charts.main';  // https://medium.com/dailyjs/using-import-aliases-in-javascript-a0b46237601c
import * as am4charts from "@amcharts/amcharts4/charts";

const ema = {
    mounted() {
       // console.log("mixins/ema.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        emaIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "ema");
        },
        emaVisible() {
            return typeof this.emaIndicator !== 'undefined' ? this.emaIndicator.visible : null;
        },
        emaLength() {
            return typeof this.emaIndicator !== 'undefined' ? this.emaIndicator.length : null;
        }
    },
    watch: {
        emaVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addEma() : this.removeEma();
        },
        emaLength: function (length) {
            console.log("watch length=", length);
            if(this.emaVisible){
                this.addEma();
            }
        }
    },
    methods: {
        removeEma() {
            // console.log("removeEma() starting.");
            let chart = this.$options.chart;

            let index = chart.series.indexOf(chart.map.getKey("indicator_ema_series"));
            // console.log("index=", index);
            if (index !== -1) {
                chart.series.removeIndex(index).dispose();
            }

            let data = chart.map.getKey("main-series").data;
            // console.log("data=", data);

            if (typeof data !== 'undefined') {
                data.forEach(x => {
                    let keys = Object.keys(x);
                    //console.log("keys=", keys);

                    let filteredKeys = keys.filter(x => x.includes("indicator_ema"));
                    // console.log("filteredKeys=", filteredKeys);
                    filteredKeys.forEach(key => {
                        delete x[key];
                    });
                });
            }
        },
        addEma() {
            // console.log("addEma() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            this.removeEma(chart);

            let selected = generalForm.selected[0];
            let digits = spreadDigits(selected, generalForm.instrument)+1;
            let formatString = "#." + "0".repeat(digits);

            let mainData = chart.map.getKey("main-series").data;
            //console.log("removeNaNs(mainData)=", removeNaNs(mainData));

            let mainDataPlusIndicators = emaFunction(removeNaNs(mainData), this.emaLength, "close");
            // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

            mainDataPlusIndicators.forEach(x => {
                x["indicator_ema"] = x["ema_" + this.emaLength];
                delete x["ema_" + this.emaLength];
            });
            // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

            let emaSeries = chart.series.push(new am4charts.LineSeries());
            emaSeries.id = "indicator_ema_series";
            emaSeries.data = mainDataPlusIndicators;
            emaSeries.dataFields.valueY = "indicator_ema";
            emaSeries.dataFields.openValueY = "indicator_ema";
            emaSeries.dataFields.dateX = "date";
            emaSeries.name = "EMA_" + this.emaLength;
            emaSeries.tooltipText = generalForm.chartParameters.balloons ? "[{color}]ema_"+this.emaLength+": {valueY.formatNumber('" + formatString + "')}" : "";
            emaSeries.strokeWidth = 1;
            emaSeries.stroke = "red";
            emaSeries.fillOpacity = 0.0;
            emaSeries.tooltip.getFillFromObject = false;
            emaSeries.tooltip.background.fill = "red";
            emaSeries.stacked = false;
           // emaSeries.legendSettings.labelText = "[{color}]ema_20: {valueY}";
            emaSeries.hiddenInLegend = true;
            // emaSeries.yAxis = chart.map.getKey("main-axis");
        },
        setEmaTooltips() {
            console.log("setEmaTooltips() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let selected = generalForm.selected[0];

            let digits = spreadDigits(selected, generalForm.instrument)+1;
            let formatString = "#." + "0".repeat(digits);
            console.log("formatString=", formatString);
        
            let series = this.$options.chart.map.getKey("indicator_ema_series");
            console.log("ema series=", series);
            if (typeof series !== 'undefined') {
                series.tooltipText = this.balloons ? "[{color}]ema_"+this.emaLength+": {valueY.formatNumber('" + formatString + "')}" : "";
            }
        } 
    }
};

export default ema;


