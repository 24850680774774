let spreadCoverageInitialState = {
    "instrument": "future",
    "legs": 1,
    "sampleContract": [
        "S2021H",
        "S2021K",
        "S2021N",
        "S2021U"
    ],
    "selected": [],
    "intracommodity": false,
    "p": [
        1,
        -1,
        1,
        1
    ],
    "unitMove": [
        50,
        50,
        50,
        50
    ],
    "unit": "",
    "y1": 15,
    "generator": "SeasonalsGenerator",
    program: "SpreadCoverage",
  //  "open": "2021-03-21",
  //  "close": "2021-04-21",
    "mult": [
        1,
        1,
        1,
        1
    ],
    "spreadP": 1,
    "overwriteShow": false,
    "addProfitLoss": false,
    "showTradingPeriod": true,
    "aligned": true,
    "user": "A4A-user0",
    "password": "M1-K8xQgmR",
    "constrainContractAligner": false,
    "study": "calculator",
    "watchList": [],
    "longtermChartColors": "yearly",
    "numberOfContractsApart": "0",
    "language": "en",
    "chartParameters": {
        "balloons": true,
        "height": 600,
        "page": 1,
        "showMapHover": false,
        "showMapHoverLocationOnSeasonal": false,
        "parameterToMap": "percentUp",
        "start": "",
        "end": "",
        "levels": [],
        "numberOfCharts": "",
        "initialZoom": true,
        "skipEmptyPeriods": false
    },
    "playback": {
        "i": null
    },
    "search": {
        "sortParameter": "rrr",
        "legs": 1,
        "position": "either",
        "direction": "desc",
        "source": "singleCommodity",
        "minDays": "2",
        "maxDays": "100",
        "userConditions": [
            {
                "parameter": "percentProfitable",
                "comparator": ">",
                "value": 70
            }
        ]
    },
    "legendEnabled": false
};

export {spreadCoverageInitialState};