var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"border-bottom":"1px solid darkgray","border-right":"0px solid darkgray","padding":"10px","background-color":"white","font-size":"1.0rem"}},[_c('portfolio-folders',{attrs:{"namespace":_vm.namespace}}),_c('div',{staticStyle:{"border":"1px solid darkgray"}},[_c('b-table',{staticClass:"portfolio-output-table",staticStyle:{"text-align":"right"},attrs:{"items":_vm.filteredStrategies,"fields":_vm.fields,"sort-icon-right":""},scopedSlots:_vm._u([{key:"head()",fn:function(data){return [_c('b-row',[_c('b-col',{staticClass:"text-sm-right"},[_c('b',[_vm._v(_vm._s(data.label))])])],1)]}},{key:"head(actions)",fn:function(data){return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_c('b',[_vm._v(_vm._s(data.label))])])],1)]}},{key:"head(tools)",fn:function(data){return [_c('b-row',[_c('b-col',{staticClass:"text-sm-center"},[_c('b',[_vm._v(_vm._s(data.label))])])],1)]}},{key:"cell(portfolio.numberOfOpenPositions)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(portfolio.numberOfClosedPositions)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(open)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"cell(close)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"row-details",fn:function(row){return [(row.item.portfolio.detailsType === 'trades')?_c('portfolio-trades-table',{staticStyle:{"margin":"0 0px 16px 30px"},attrs:{"namespace":_vm.namespace,"item":row.item}}):_vm._e(),(row.item.portfolio.detailsType === 'alarms')?_c('alarms-table',{staticStyle:{"margin":"0 0px 16px 30px"},attrs:{"namespace":_vm.namespace,"ticker":row.item.ticker,"portfolioId":row.item.portfolio.id,"alarms":_vm.alarms[row.item.portfolio.id]}}):_vm._e()]}},{key:"cell(portfolio.folder)",fn:function(ref){
var value = ref.value;
var item = ref.item;
var field = ref.field;
return [(_vm.userRow && _vm.userRow.portfolio.id === item.portfolio.id)?_c('b-form-select',{staticStyle:{"border":"1px solid gray","width":"auto","border-radius":"3px","margin":"-7px 0 0 0"},attrs:{"size":"sm","options":_vm.folderOptions},on:{"change":function($event){return _vm.updateStrategies(item)}},model:{value:(item.portfolio.folder),callback:function ($$v) {_vm.$set(item.portfolio, "folder", $$v)},expression:"item.portfolio.folder"}}):[_vm._v(_vm._s(value))]]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('span',{on:{"click":function($event){return _vm.toggleTrades(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('show/hide trades'),expression:"'show/hide trades'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"color":"#404040","margin":"-4px 0px 0px 0px","cursor":"pointer"},attrs:{"icon":"ci:search-magnifying-glass","width":"27","height":"27"}})],1),_c('span',{on:{"click":function($event){return _vm.toggleAlarms(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('show/hide alarms'),expression:"'show/hide alarms'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"color":"#404040","margin":"-4px 4px 0px 0px","cursor":"pointer"},style:({color: item.portfolio.alarmTriggered ? 'red' : item.portfolio.alarmToNotify ? 'green' : 'black'}),attrs:{"icon":"mdi:bell-alert-outline","width":"27","height":"27"}})],1),(_vm.showAllPortfolioFolders)?_c('span',{on:{"click":function($event){return _vm.changeFolder(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('change folder'),expression:"'change folder'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"color":"#404040","margin":"-4px 4px 0px 0px","cursor":"pointer"},attrs:{"icon":"codicon:file-symlink-directory","width":"27","height":"27"}})],1):_vm._e(),_c('span',{on:{"click":function($event){return _vm.removeStrategy(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('delete'),expression:"'delete'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"margin":"-4px 4px 0px 0px","cursor":"pointer"},attrs:{"icon":"ion:trash-outline","width":"25","height":"25"}})],1)])]}},{key:"cell(tools)",fn:function(ref){
var item = ref.item;
return [_c('span',{on:{"click":function($event){return _vm.getCalculator(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('calculator'),expression:"'calculator'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"margin":"-4px 4px 0px 0px","cursor":"pointer"},attrs:{"icon":"fluent:calculator-20-regular","width":"27","height":"27"}})],1),_c('span',{on:{"click":function($event){return _vm.getChart(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('chart'),expression:"'chart'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"color":"#404040","margin":"-4px 4px 0px 0px","cursor":"pointer"},attrs:{"icon":"mdi:graph-line","width":"25","height":"25"}})],1),_c('span',{on:{"click":function($event){return _vm.getMap(item)}}},[_c('Icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.topleft",value:('heatmap'),expression:"'heatmap'",modifiers:{"noninteractive":true,"hover":true,"topleft":true}}],staticStyle:{"color":"#404040","margin":"-4px 3px 0px 0px","cursor":"pointer"},attrs:{"icon":"material-symbols:map-outline-rounded","width":"25","height":"25"}})],1)]}}])})],1),_c('div',{staticStyle:{"padding":"10px 0 0px 0"}},[_c('table',{staticStyle:{"font-size":"1.5rem"}},[_c('tr',[_c('td',{staticStyle:{"padding":"0 60px 0 10px"}},[_vm._v("total realized profit: "+_vm._s(_vm.totalRealizedProfit))]),_c('td',{staticStyle:{"padding":"0 60px 0 10px"}},[_vm._v("total unrealized profit: "+_vm._s(_vm.totalUnrealizedProfit))]),_c('td',[_vm._v("total profit: "+_vm._s(_vm.totalProfit))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }