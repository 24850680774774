import { heightControl } from '@/js/main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const vol = {
    mounted() {
       // console.log("mixins/volume.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        volumeIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "vol");
        },
        volumeVisible() {
            return typeof this.volumeIndicator !== 'undefined' ? this.volumeIndicator.visible : null;
        },
    },
    watch: {
        volumeVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addVolume() : this.removeVolume();
        }
    },
    methods: {
        removeVolume() {
            console.log("removeVolume() starting.");
            let chart = this.$options.chart;
            let volumeSeries = chart.map.getKey("volume-series");
            if (typeof volumeSeries !== 'undefined') {
                chart.map.getKey("volume-series").hide();
                chart.map.getKey("openInterest-series").hide();

                //  console.log("chart.yAxes.values=", chart.yAxes.values);
                let volumeAxis = chart.map.getKey("volume-axis");
                volumeAxis.height = 0.0;
                volumeAxis.disabled = true;

                heightControl("removing", chart);

                /*   let openInterestAxis = chart.map.getKey("openInterest-axis");
                 openInterestAxis.height = 0.0;
                 openInterestAxis.disabled = true;  */

            }
        },
        addVolume() {
            // console.log("addVolume() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            if (generalForm.legs == 1) {
                let axisPercentage = heightControl("adding", chart);
                // console.log("axisPercentage=", axisPercentage);
                let formatString = "#,###.";

                let volumeAxis = chart.map.getKey("volume-axis");
                // let openInterestAxis = chart.map.getKey("openInterest-axis");
                var alreadyPresent = typeof volumeAxis !== 'undefined';
                // console.log("alreadyPresent=", alreadyPresent);

                if (!alreadyPresent) {
                    volumeAxis = chart.yAxes.push(new am4charts.ValueAxis());
                    volumeAxis.id = "volume-axis";
                    // height of axis
                    volumeAxis.height = am4core.percent(axisPercentage);
                    volumeAxis.zIndex = 3
                    // this makes gap between panels
                    volumeAxis.marginTop = 16;
                    volumeAxis.renderer.baseGrid.disabled = true;
                    volumeAxis.renderer.inside = true;
                    volumeAxis.renderer.labels.template.verticalCenter = "bottom";
                    volumeAxis.renderer.labels.template.padding(2, 2, 2, 2);
                    //volumeAxis.renderer.maxLabelPosition = 0.95;
                    volumeAxis.renderer.fontSize = "0.8em"
                    volumeAxis.title.text = generalForm.instrument === "future" ? "Vol. & O.I." : "Vol";
                    volumeAxis.title.fontWeight = "bold";
                    volumeAxis.zoomable = false;

                    /*  openInterestAxis = chart.yAxes.push(new am4charts.ValueAxis());
                     openInterestAxis.id = "openInterest-axis";
                     //  openInterestAxis.renderer.opposite = true;
                     openInterestAxis.height = am4core.percent(20);
                     openInterestAxis.zIndex = 3
                     // this makes gap between panels
                     openInterestAxis.marginTop = 16;
                     openInterestAxis.renderer.baseGrid.disabled = true;
                     openInterestAxis.renderer.inside = true;
                     openInterestAxis.renderer.labels.template.verticalCenter = "bottom";
                     openInterestAxis.renderer.labels.template.padding(2, 2, 2, 2);
                     //volumeAxis.renderer.maxLabelPosition = 0.95;
                     openInterestAxis.renderer.fontSize = "0.8em"
                     openInterestAxis.title.text = "Open Interest";
                     openInterestAxis.title.fontWeight = "bold";
                     openInterestAxis.syncWithAxis = volumeAxis; */

                    let mainData = chart.map.getKey("main-series").data;
                    //console.log("mainData=", mainData);

                    var volumeSeries = chart.series.push(new am4charts.ColumnSeries());
                    volumeSeries.id = "volume-series";
                    volumeSeries.data = mainData;
                    volumeSeries.dataFields.dateX = "date";
                    volumeSeries.clustered = false;
                    volumeSeries.dataFields.valueY = "volume";
                    volumeSeries.yAxis = volumeAxis;
                    volumeSeries.columns.each(function (column) {
                        column.alwaysShowTooltip = false;
                        column.hideTooltip();
                    });
                    volumeSeries.tooltipText = generalForm.chartParameters.balloons ? "volume: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    volumeSeries.name = "volume";
                    volumeSeries.hiddenInLegend = true;
                    volumeSeries.legendSettings.labelText = "[{color}] volume: {valueY}";
                    volumeSeries.fill = "#A2B5CD";
                    volumeSeries.fillOpacity = 0.3;
                    volumeSeries.stroke = "#50729F";
                    // volume should be summed
                    // volumeSeries.groupFields.valueY = "sum";
                    volumeSeries.defaultState.transitionDuration = 0;

                    var openInterestSeries = chart.series.push(new am4charts.LineSeries());
                    openInterestSeries.id = "openInterest-series";
                    openInterestSeries.data = mainData;
                    openInterestSeries.dataFields.dateX = "date";
                    openInterestSeries.clustered = false;
                    openInterestSeries.dataFields.valueY = "openInterest";
                    openInterestSeries.yAxis = volumeAxis;
                    openInterestSeries.tooltipText = generalForm.chartParameters.balloons ? "open interest: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    openInterestSeries.name = "open interest";
                    openInterestSeries.hiddenInLegend = true;
                    openInterestSeries.legendSettings.labelText = "[{color}] open interest: {valueY}";

                    openInterestSeries.fill = "#91a3b0";
                    openInterestSeries.stroke = "black";
                    openInterestSeries.fillOpacity = 0.2;
                    // volume should be summed
                    //  openInterestSeries.groupFields.valueY = "sum";
                    openInterestSeries.defaultState.transitionDuration = 0;
                } else {
                    volumeAxis.disabled = false;
                    volumeAxis.height = am4core.percent(axisPercentage);
                    chart.map.getKey("volume-series").show();

                    // openInterestAxis.disabled = false;
                    // openInterestAxis.height = am4core.percent(axisPercentage);

                    chart.map.getKey("openInterest-series").show();
                }
            }
        },
        setVolumeTooltips() {
            let formatString = "#,###.";
            let chart = this.$options.chart;

            let volumeSeries = chart.map.getKey("volume-series");
            let openInterestSeries = chart.map.getKey("openInterest-series");

            if (typeof volumeSeries !== 'undefined') {
                volumeSeries.tooltipText = this.balloons ? "volume: {valueY.value.formatNumber('" + formatString + "')}" : "";
                openInterestSeries.tooltipText = this.balloons ? "open interest: {valueY.value.formatNumber('" + formatString + "')}" : "";
            }
        }
    }
};

export default vol;