let forwardCurvesInitialState = {
    "program": "ForwardCurves",
    "p": [1, -1, 1, 1],
    "unitMove": [50, 50, 50, 50],
    "sampleContract": ["S2020H", "W2020K", "W2020N", "W2020U"],
    "mult": [1.0, 1.0, 1.0, 1.0],
    "open": "2019-12-10",
    "close": "2020-01-11",
    "lineWidth": 1,
    "legs": 1,
    y1: 10,
    normalization: false,
    "generator": "SeasonalsGenerator",
    forwardCurveStudy: "unaligned",
    "grouping": "off",
    "language": "en",
    "updateState": true,
    "showTradingPeriod": false,
    intracommodity: false,
    chartParameters: {
        chartsPerRow: 2,
        rowsPerPage: 2,
        "balloons": true
    },
    search: {
        sortParameter: "rrr",
        legs: 2,
        position: "either",
        direction: "desc",
        source: "singleCommodity",
        minDays: 2,
        maxDays: 100,
        userConditions: [
            {
                parameter: "percentProfitable",
                comparator: ">",
                value: 70
            }
        ],
    },
    instrument: "future",
    user: "A4A-user0",
    password: "M1-K8xQgmR"
};

export {forwardCurvesInitialState};