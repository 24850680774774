import {heightControl, spreadDigits} from '@/js/main';
import {ema, removeNaNs} from '@/js/am4charts-plugins/am4charts.main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const macd = {
    mounted() {
       // console.log("mixins/macd.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        macdIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "macd");
        },
        macdVisible() {
            return typeof this.macdIndicator !== 'undefined' ? this.macdIndicator.visible : null;
        },
    },
    watch: {
        macdVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addMacdPanel() : this.removeMacdPanel();
        }
    },
    methods: {
        removeMacdPanel() {
            let chart = this.$options.chart;
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("macd-series"))).dispose();
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("signal-series"))).dispose();
            chart.series.removeIndex(chart.series.indexOf(chart.map.getKey("histogram-series"))).dispose();

            chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("macd-axis"))).dispose();
            heightControl("removing", chart);

            let data = chart.map.getKey("main-series").data;
            console.log("data=", data);

            if (typeof data !== 'undefined') {
                data.forEach(x => {
                    let keys = Object.keys(x);
                    //console.log("keys=", keys);

                    let filteredKeys = keys.filter(x => x.includes("macd"));
                    // console.log("filteredKeys=", filteredKeys);
                    filteredKeys.forEach(key => {
                        delete x[key];
                    });
                });
            }
        },
        addMacdPanel() {
            console.log("addPanel() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            let selected = generalForm.selected[0];
            console.log("selected=", selected);
            let digits = spreadDigits(selected, generalForm.instrument);
            let formatString = "#." + "0".repeat(digits);
            console.log("formatString=", formatString);

            let slowPeriod = 26;
            let fastPeriod = 12;
            let fastPropName = "ema_" + fastPeriod;
            let slowPropName = "ema_" + slowPeriod;
            let mainData = chart.map.getKey("main-series").data;

            let emaSeries = ema(removeNaNs(mainData), fastPeriod, "close");
            emaSeries = ema(emaSeries, slowPeriod, "close");
            emaSeries.forEach(x => {
                x.macd = x[fastPropName] - x[slowPropName];
                delete x[fastPropName];
                delete x[slowPropName];
            });
            console.log("emaSeries=", emaSeries);

            let signalPeriod = 9;
            let signal = ema(emaSeries, signalPeriod, "macd");


            signal.forEach(x => {
                x.macd_histogram = (x.macd - x.ema_9);
                x.macd_signal = x.ema_9;
                delete x.ema_9;
            });

            let axisPercentage = heightControl("adding", chart);

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.id = "macd-axis";
            valueAxis.tooltip.disabled = true;
            valueAxis.height = am4core.percent(axisPercentage);
            valueAxis.zIndex = 3
// this makes gap between panels
            valueAxis.marginTop = 16;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.verticalCenter = "bottom";
            valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
            valueAxis.renderer.fontSize = "0.8em"
            valueAxis.renderer.gridContainer.background.fill = "white";
            valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
            valueAxis.title.text = "MACD(12,26,9)";
            valueAxis.title.fontWeight = "bold";
            valueAxis.zoomable = false;

            let macdSeries = chart.series.push(new am4charts.LineSeries());
            macdSeries.id = "macd-series";
            macdSeries.data = signal;
            macdSeries.dataFields.dateX = "date";
            macdSeries.clustered = false;
            macdSeries.dataFields.valueY = "macd";
            macdSeries.yAxis = valueAxis;
            macdSeries.tooltipText = generalForm.chartParameters.balloons ? "macd: {valueY.value.formatNumber('" + formatString + "')}" : "";
            macdSeries.name = "MACD";
            macdSeries.hiddenInLegend = true;
            macdSeries.legendSettings.labelText = "[{color}] macd: {valueY.value.formatNumber('" + formatString + "')}";
            macdSeries.stroke = "#7D0552";
            macdSeries.tooltip.getFillFromObject = false;
            macdSeries.tooltip.background.fill = "#7D0552";

            let signalSeries = chart.series.push(new am4charts.LineSeries());
            signalSeries.data = signal;
            signalSeries.id = "signal-series";
            signalSeries.dataFields.dateX = "date";
            signalSeries.clustered = false;
            signalSeries.dataFields.valueY = "macd_signal";
            signalSeries.yAxis = valueAxis;
            signalSeries.tooltipText = generalForm.chartParameters.balloons ? "signal: {valueY.value.formatNumber('" + formatString + "')}" : "";
            signalSeries.name = "SIGNAL";
            signalSeries.hiddenInLegend = true;
            signalSeries.legendSettings.labelText = "[{color}] ema_9: {valueY.value.formatNumber('" + formatString + "')}";
            signalSeries.stroke = "#6698FF";
            signalSeries.tooltip.getFillFromObject = false;
            signalSeries.tooltip.background.fill = "#6698FF";


            let histogramSeries = chart.series.push(new am4charts.ColumnSeries());
            histogramSeries.data = signal;
            histogramSeries.id = "histogram-series";
            histogramSeries.dataFields.dateX = "date";
            histogramSeries.clustered = false;
            histogramSeries.dataFields.valueY = "macd_histogram";
            histogramSeries.yAxis = valueAxis;
            histogramSeries.tooltipText = generalForm.chartParameters.balloons ? "histogram: {valueY.value.formatNumber('" + formatString + "')}" : "";
            histogramSeries.name = "HISTOGRAM";
            histogramSeries.hiddenInLegend = true;
            histogramSeries.legendSettings.labelText = "[{color}] hist: {valueY.value.formatNumber('" + formatString + "')}";
            histogramSeries.fill = "#FF4500";
            histogramSeries.stroke = "darkred";
            histogramSeries.tooltip.getFillFromObject = false;
            histogramSeries.tooltip.background.fill = "#FF4500";

            console.log("valueAxis.minZoomed=", valueAxis.minZoomed);
            console.log("valueAxis.maxZoomed=", valueAxis.maxZoomed);
            valueAxis.min = valueAxis.minZoomed;
            valueAxis.max = valueAxis.maxZoomed;
        },
        setMacdTooltips() {
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            
            let selected = generalForm.selected[0];
            console.log("selected=", selected);
            let digits = spreadDigits(selected, generalForm.instrument);
            let formatString = "#." + "0".repeat(digits);
            console.log("formatString=", formatString);

            let macdSeries = chart.map.getKey("macd-series");
            let signalSeries = chart.map.getKey("signal-series");
            let histogramSeries = chart.map.getKey("histogram-series");
            if (typeof macdSeries !== 'undefined') {
                macdSeries.tooltipText = this.balloons ? "macd: {valueY.value.formatNumber('" + formatString + "')}" : "";
                signalSeries.tooltipText = this.balloons ? "[{color}] ema_9: {valueY.value.formatNumber('" + formatString + "')}" : "";
                histogramSeries.tooltipText = this.balloons ? "histogram: {valueY.value.formatNumber('" + formatString + "')}" : "";
            }
        }
    }
};

export default macd;