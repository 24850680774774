import {spreadDigits, removeNaNs} from '@/js/main';
import {sma as smaFunction} from '@/js/am4charts-plugins/am4charts.main';  // https://medium.com/dailyjs/using-import-aliases-in-javascript-a0b46237601c
import * as am4charts from "@amcharts/amcharts4/charts";

const sma = {
    mounted() {
       // console.log("mixins/sma.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        smaIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "sma");
        },
        smaVisible() {
            return typeof this.smaIndicator !== 'undefined' ? this.smaIndicator.visible : null;
        },
        smaLength() {
            return typeof this.smaIndicator !== 'undefined' ? this.smaIndicator.length : null;
        }
    },
    watch: {
        smaVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addSma() : this.removeSma();
        },
        smaLength: function (length) {
            console.log("watch length=", length);
            if(this.smaVisible){
                this.addSma();
            }
        }
    },
    methods: {
        removeSma() {
            // console.log("removeSma() starting.");
            let chart = this.$options.chart;

            let index = chart.series.indexOf(chart.map.getKey("indicator_sma_series"));
            // console.log("index=", index);
            if (index !== -1) {
                chart.series.removeIndex(index).dispose();
            }
        },
        addSma() {
            // console.log("addSma() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            this.removeSma();

            let selected = generalForm.selected[0];
            let digits = spreadDigits(selected, generalForm.instrument)+1;
            let formatString = "#." + "0".repeat(digits);
          
            let mainData = chart.map.getKey("main-series").data;
            // console.log("removeNaNs(mainData)=", removeNaNs(mainData));

            let mainDataPlusIndicators = smaFunction(removeNaNs(mainData), this.smaLength, "close");
            //console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

            mainDataPlusIndicators.forEach(x => {
                x["indicator_sma"] = x["sma_" + this.smaLength];
                delete x["sma_" + this.smaLength];
            });
            // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);

            let smaSeries = chart.series.push(new am4charts.LineSeries());
            smaSeries.id = "indicator_sma_series";
            smaSeries.data = mainDataPlusIndicators;
            smaSeries.dataFields.valueY = "indicator_sma";
            smaSeries.dataFields.openValueY = "indicator_sma";
            smaSeries.dataFields.dateX = "date";
            smaSeries.tooltipText = generalForm.chartParameters.balloons ? "[{color}]sma_"+this.smaLength+": {valueY.formatNumber('" + formatString + "')}" : "";
            smaSeries.name = "SMA_" + this.smaLength;
            smaSeries.strokeWidth = 1;
            smaSeries.stroke = "purple";
            smaSeries.tooltip.getFillFromObject = false;
            smaSeries.tooltip.background.fill = "purple";
            smaSeries.fillOpacity = 0.0;
            smaSeries.stacked = false;
            // smaSeries.legendSettings.labelText = "[{color}]sma_20: {valueY.formatNumber('" + formatString + "')}";
            smaSeries.hiddenInLegend = true;
            // smaSeries.yAxis = chart.map.getKey("main-axis");
        },
        setSmaTooltips() {
            console.log("setSmaTooltips() starting.");        
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let selected = generalForm.selected[0];

            let digits = spreadDigits(selected, generalForm.instrument)+1;
            let formatString = "#." + "0".repeat(digits);
            console.log("formatString=", formatString);
        
            let series = this.$options.chart.map.getKey("indicator_sma_series");
            console.log("sma series=", series);
            if (typeof series !== 'undefined') {
                series.tooltipText = this.balloons ? "[{color}]sma_"+this.smaLength+": {valueY.formatNumber('" + formatString + "')}" : "";
            }
        } 
    }
};

export default sma;