let searchInitialState = {
    "sampleContract": ["S2020H", "C2020K", "C2020N", "C2020Z"],
    user: "A4A-user0",
    password: "M1-K8xQgmR",
    intracommodity: true,
    program: "Search",
    tabTitle: "search tab title",
    chartParameters: {
        "page": 1,
        chartsPerRow: 2,
        rowsPerPage: 20,
        chartsPerPage: 4,
        balloons: true,
    },
    "legs": 1,
    group: "grains",
    numberOfContractsApart: 3,
    search: {
        sortParameter: "rrr",
        legs: [1,2,3],
        position: "either",
        direction: "desc",
        source: "group",
        minDays: 10,
        maxDays: 100,
        openStart: "",
        openEnd: "",
        closeStart: "",
        closeEnd: "",
        tradePeriodExcludedSelector: "endingRange",
        numberOfContractsApartComparator: "<=",
        userConditions: [
            {
                parameter: "appd",
                comparator: ">",
                value: 0
            }
        ],
        query: "select SQL_CALC_FOUND_ROWS trade.*\n\
                FROM data.trades AS trade\n\
                            where  DATEDIFF(close, open ) >= 2 and DATEDIFF(close, open ) <= 100 and appd > 5 GROUP BY ticker) AS trade2\n\
            ON trade.ticker = trade2.ticker AND trade.rrr = trade2.rrr HAVING  DATEDIFF(close, open ) >= 2 and DATEDIFF(close, open ) <= 100 and appd > 5 ORDER BY rrr desc LIMIT 0 , 20;"
    }
};

export {searchInitialState};