<template>
    <div>
        <table border="0" style="border-right: 1px solid lightgray; font-weight: normal;">
            <tr>
                <td style="vertical-align: top; margin: 0px;">
            <plotly-heatmap v-bind:namespace="namespace"></plotly-heatmap>
            </td>
            <td style="vertical-align: top; padding-bottom: 0px; width: 800px">

                <div style="margin: 0px 0px 0px 0px; border-right: 0px solid darkgray; border-top: 0px solid darkgray;">
                    <basic-chart-output v-bind:namespace="namespace" ref="basic-chart-output"></basic-chart-output>  
                </div>
                <div style="margin: 0 0px 0px 0px; border: 0px solid darkgray;">
                    <calculator-output v-bind:namespace="namespace"
                                       v-bind:width="'740px'"></calculator-output>  
                </div>  

            </td>
            </tr>
        </table>      



    </div>
</template>

<script>
    // import moment from 'moment';
    import $ from "jquery";
    import basicChartOutput from '@/components/programs/BasicChart/basic-chart-output';
    import calculatorOutput from '@/components/programs/Calculator/calculator-output';
    import plotlyHeatmap from '@/components/plotly-heatmap';
    import {orderContracts, nearestOpenSpread, getCommoditiesArray, areSameUnitsAndUnitMoves, listOpenTickerOptions} from '@/js/main';
    import {decompress} from "@/js/jsonhNew";
    import * as fb from '@/firebase';
    import _ from 'lodash';

    const axios = require('axios');

    export default {
        components: {
            calculatorOutput, basicChartOutput, plotlyHeatmap
        },
        vectorMap: {}, // <-- non-reactive property
        rawData: {}, // <-- non-reactive property
        mounted() {
            // console.log("trade-maps-output.vue mounted() starting. this.ticker=", this.ticker);
            this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
            // console.log("this.ticker=", this.ticker);
            //  this.$store.commit(this.namespace + '/setShowTradingPeriod', true);

            if (this.activeModuleName === this.namespace && !this.initialized && this.ticker !== null) {
                this.getData();
            }
        },
        props: ['namespace'],
        beforeDestroy() {
            console.log("trade-maps-output.vue beforeDestroy() starting.");
            // removeHeatmap(this);
        },
        data: function () {
            return{
                initialized: false
            }
        },
        computed: {
            mapEndpoint: {
                get() {
                    return this.$store.state.user.mapEndpoint;
                },
                set(mapEndpoint) {
                    // console.log("mapEndpoint=", mapEndpoint);
                    this.$store.commit('user/setMapEndpoint', mapEndpoint);
                }
            },
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            displayTicker() {
                return this.$store.getters[this.namespace + "/displayTicker"];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            parameterToMap() {
                return this.$store.state[this.namespace].chartParameters.parameterToMap;
            },
            firebaseStorageDirectory() {
                return this.$store.state.siteData.firebaseStorageDirectory;
            },
            buySell() {
                return this.$store.state[this.namespace].buySell;
            },
            direction() {
                let isBefore = true;
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let contract = generalForm.sampleContract.slice(0, generalForm.legs);
                if (generalForm.legs > 1) {
                    let newOrder = orderContracts(contract[0], contract[1])
                    isBefore = (contract[0] == newOrder[0]);
                }
                // console.log("isBefore=" + isBefore);
                if (!isBefore) {
                    contract = contract.reverse();
                }
                // console.log("contract=", contract);

                let directionCoefficient = 1;
                console.log("this.mapEndpoint=", this.mapEndpoint);
                if (this.mapEndpoint !== 'servlet' && ((generalForm.legs == 1 && generalForm.p[0] == -1) || 
                    (generalForm.legs == 2 && generalForm.p[0] == 1 && !isBefore) ||
                    (generalForm.legs == 2 && generalForm.p[0] == -1 && isBefore))) {
                    directionCoefficient = -1;
                }
                console.log("directionCoefficient=", directionCoefficient);

                let buySellCoefficient = this.buySell === "buy" ? 1 : -1;

                let direction = directionCoefficient * buySellCoefficient === 1 ? "up" : "down";
                console.log("direction=" + direction);
                return direction;
            },
            symbols() {
                return this.$store.state.user.symbols;
            },
            nearestOpen() {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                return nearestOpenSpread(generalForm).value;
            },
            nearestOpenTicker() {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let openOptions = listOpenTickerOptions(generalForm).map(x => x.text);
                // console.log("openOptions=", openOptions);
                return openOptions.length === 0 ? null : openOptions[openOptions.length - 1].replace(/\s/g, '');
            }
        },
        watch: {
            firebaseStorageDirectory(firebaseStorageDirectory) {
                console.log("watch firebaseStorageDirectory=", firebaseStorageDirectory);
                this.getData();
            },
            symbols(symbols) {
                console.log("watch symbols=", symbols);
                this.getCharts();
            },
            ticker: function (newTicker, oldTicker) {
                console.log("watch: newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);
                this.$children[0].removeHeatmap();
                this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
                if (newTicker !== null && newTicker !== oldTicker) {
                    //removeStatChart();
                    this.getData();
                } else {
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("");
                }
            },
            activeModuleName: function (activeModuleName) {
                // console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized && this.ticker !== null) {
                    this.$children[0].removeHeatmap();
                    this.getData();
                }
            },
            parameterToMap() {
                // console.log("watching parameterToMap");
                if (this.activeModuleName === this.namespace) {
                    this.$children[0].runPlotly(this.$options.vectorMap, this.parameterToMap);
                }
            },
            buySell() {
                console.log("watching buySell");
                this.getCharts();
            },
            displayTicker() {
                this.$store.commit(this.namespace + '/setTabTitle', "map: " + this.displayTicker);
            }
        },
        methods: {
            getCharts() {
                this.$options.vectorMap = decompress(JSON.stringify(this.$options.rawData));
                // console.log("this.$options.vectorMap=", JSON.parse(JSON.stringify(this.$options.vectorMap)));

                let openStartDate = Object.keys(this.$options.vectorMap)[0];
                // console.log("openStartDate=", openStartDate);

                if (typeof openStartDate !== 'undefined') {
                    let startingCloseDates = Object.keys(this.$options.vectorMap[openStartDate]);
                    // console.log("startingCloseDates=", startingCloseDates);

                    if (typeof startingCloseDates !== 'undefined'/* && startingCloseDates.length > 0*/) {
                        // console.log("that.$children[0]=", that.$children[0]);
                        let commodity = this.$store.getters[this.namespace + "/c"][0];
                        // console.log("commodity=", commodity);

                        let unitMove = areSameUnitsAndUnitMoves(this.$store.state[this.namespace].selected[0]) ?
                                getCommoditiesArray().find(x => x.symbol === commodity).unitMove : 1;
                        console.log("unitMove=", unitMove);

                        let mult = this.mapEndpoint === "servlet" ? 1 : this.$store.state[this.namespace].mult[0];
                        console.log("mult=", mult);

                        // let totalMult = 

                        let newTradeObject = {};
                        let upValue;
                        let downValue;
                        console.log("this.direction=", this.direction);
                        if (this.direction === "down") {
                            for (let open in this.$options.vectorMap) {
                                let openVector = this.$options.vectorMap[open];
                                // console.log("open=", open + ':', openVector);
                                for (let close in openVector) {
                                    //  console.log(open + ':', closeVector[open]);
                                    upValue = openVector[close];
                                    downValue = Object.assign({}, openVector[close])

                                    downValue["close"] = close;
                                    downValue["avgAppd"] = -(mult * unitMove * upValue.avgChange / upValue.avgDays).toFixed(2);
                                    downValue["avgChange"] = -(mult * unitMove * upValue.avgChange).toFixed(2);
                                    downValue["avgMax"] = -mult * upValue.avgMin;
                                    downValue["bestMax"] = -mult * upValue.worstMin;
                                    downValue["avgMin"] = -mult * upValue.avgMax;
                                    downValue["worstMin"] = -mult * upValue.bestMax;
                                    downValue["rrr"] = -mult * upValue.avgMin / upValue.avgMax;
                                    downValue["percentUp"] = upValue.percentDown;
                                    downValue["percentDown"] = upValue.percentUp;
                                    downValue["rrr"] = -(upValue.avgMin / upValue.avgMax).toFixed(3);
                                    openVector[close] = downValue;
                                }
                                newTradeObject[open] = openVector;
                            }
                            // console.log("newTradeObject=", newTradeObject);
                            this.$options.vectorMap = newTradeObject;
                        } else {
                            for (let open in this.$options.vectorMap) {
                                let openVector = this.$options.vectorMap[open];
                                // console.log("open=", open + ':', openVector);
                                for (let close in openVector) {
                                    // console.log(close + ':', openVector[close]);
                                    upValue = openVector[close];

                                    upValue["open"] = open;
                                    upValue["avgAppd"] = (mult * unitMove * upValue.avgChange / upValue.avgDays).toFixed(2);
                                    upValue["avgChange"] = (mult * unitMove * upValue.avgChange).toFixed(2);
                                    upValue["rrr"] = -(upValue.avgMax / upValue.avgMin).toFixed(3);
                                    openVector[close] = upValue;
                                }
                                if (Object.keys(openVector).length !== 0) {
                                    newTradeObject[open] = openVector;
                                }
                            }
                            //  console.log("newTradeObject=", newTradeObject);
                            this.$options.vectorMap = newTradeObject;
                        }

                        this.$children[0].runPlotly(this.$options.vectorMap, this.parameterToMap);
                    } else {
                        $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>\n\
                                                    No more trades for this years spread. Next years' map will be created after the current spread expires.</H3>");
                    }
                } else {
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>\n\
                                                    No more trades for this years spread. Next years' map will be created after the current spread expires.</H3>");
                }
            },
            getFirebasePathReference() {
                console.log("getFirebasePathReference() starting.");

                return new Promise((resolve/*, reject*/) => {
                    this.initialized = true;
                    this.$store.commit('incrementNumberOfAjaxCalls');
                    $('#' + this.namespace + '-plotlyHeatMapDiv').html("");

                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    generalForm.generator = "TradeMapGenerator";
                    console.log("selected[0]=", generalForm.selected[0]);

                    let contract = generalForm.sampleContract.slice(0, generalForm.legs).sort();
                     console.log("contract=", contract);
                    if (this.nearestOpen !== null) {
                        if (generalForm.legs == 1 || (contract[0] !== contract[1] && generalForm.legs == 2)) {
                             console.log("this.nearestOpen=", this.nearestOpen, " selected[0]=", generalForm.selected[0]);
                            if (this.nearestOpen !== generalForm.selected[0]) {
                                // alert("Put info here.");
                                resolve("no pathReference");
                            } else {
                                //  let splitSpread = nearestOpen.split('/');
                                //  console.log("splitSpread=", splitSpread);
                                let orderedNearestOpen = generalForm.legs == 2 ? orderContracts(...this.nearestOpen.split('/')) : this.nearestOpen;
                                 console.log("orderedNearestOpen=", orderedNearestOpen);

                                let orderedSpread = generalForm.legs == 2 ? orderedNearestOpen.join('-') : orderedNearestOpen;
                                 console.log("orderedSpread=", orderedSpread);

                                let commodity = this.$store.getters[this.namespace + "/c"][0];

                                let storage = fb.storage;
                                let path = this.firebaseStorageDirectory + "/" + commodity + "/" + orderedSpread;
                                let pathReference = storage.ref(path);
                                 console.log("pathReference=", pathReference._delegate.fullPath);

                                pathReference.getDownloadURL()
                                        .then((url) => {
                                            // console.log("url=", url);
                                            resolve(url);
                                        })
                                        .catch((error) => {
                                            // Handle any errors
                                            console.log("error=", error);
                                            resolve("no pathReference");
                                        });
                            }
                        } else if ((contract[0] === contract[1] && generalForm.legs == 2)) {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                            $('#' + this.namespace + '-plotlyHeatMapDiv').html("<div style='white-space: nowrap; color: red; font-size: 14px; padding:5px; font-weight: normal'>" +
                                    "Nothing to map." + "</div>");
                        }
                    } else {
                        console.log("No open position.");
                        this.$store.commit('decrementNumberOfAjaxCalls');
                    }
                });
            },
            getData: _.debounce(function () {
                console.log("getData() starting.");
                this.$store.commit('resetNumberOfAjaxCalls');

                // this.mapEndpoint === "firebaseStorage" ? this.getMapData() : this.createMapData();

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                let c = this.$store.getters[this.namespace + "/c"].slice(0, generalForm.legs);
                console.log("c=", c);
                let sameCommodity = c.every(x => x === c[0]);
                console.log("sameCommodity=", sameCommodity);

                let mult = generalForm.mult.slice(0, generalForm.legs)
                console.log("mult=", mult);
                let sameMult = mult.every(x => x === mult[0]);
                console.log("sameMult=", sameMult);

                if (sameCommodity && sameMult && generalForm.legs <= 2) {
                    this.getFirebasePathReference().then(pathReference => {
                        // console.log("pathReference=", pathReference);
                        if (pathReference !== "no pathReference") {
                            this.getMapData(pathReference);
                        } else {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                            this.createMapData();
                        }
                    });
                } else {
                    this.createMapData();
                }
            }, 50),
            createMapData() {
                console.log("createMapData() starting.");
                this.initialized = true;

                this.$store.commit('incrementNumberOfAjaxCalls');
                $('#' + this.namespace + '-plotlyHeatMapDiv').html("");

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                generalForm.numberOfContractsApart = null; // This prevents loading the data into the trades table in mysql.

                delete generalForm.browserSideOnly;
                delete generalForm.search;
                delete generalForm.indicators;
                delete generalForm.playback;
                delete generalForm.chartParameters;
                // console.log("generalForm=", generalForm);

                let json = JSON.stringify(generalForm, null, 2);
                let that = this;
                $.ajax({
                    url: this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/TradeMapControllerServlet/",
                    type: "POST",
                    data: {"json": json},
                    success: function (data) {
                        // console.log("data=", data);
                        that.$store.commit('decrementNumberOfAjaxCalls');
                        that.mapEndpoint = "servlet";

                        let parsedData = JSON.parse(data);
                        // console.log("parsedData=", parsedData);

                        that.$options.rawData = parsedData;
                        that.getCharts();
                    },
                    fail: function (data) {
                        console.log("call failed.");
                        console.log(data);
                    }
                });
            },
            getMapData(url) {
                // console.log("getMapData() starting.");

                axios.get(url)
                        .then(apiResponse => {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                            // console.log("apiResponse=", apiResponse);
                            this.$options.rawData = apiResponse.data;
                            // console.log("rawData=", JSON.stringify(this.$options.rawData));
                            this.mapEndpoint = "firebaseStorage";
                            this.getCharts();
                        })
                        .catch(() => {
                            this.$store.commit('decrementNumberOfAjaxCalls');
                            $('#' + this.namespace + '-plotlyHeatMapDiv').html("<H4 style='color: red; text-align: left; margin: 20px 0 0 10px'>Cannot make map from current selection.</H3>");
                            this.createMapData();
                        });
            }
        }
    };

</script>
