import {heightControl, spreadDigits, removeNaNs} from '@/js/main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const atr = {
    mounted() {
       // console.log("mixins/atr.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        atrIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "atr");
        },
        atrVisible() {
            return typeof this.atrIndicator !== 'undefined' ? this.atrIndicator.visible : null;
        },
    },
    watch: {
        atrVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addAtrPanel() : this.removeAtrPanel();
        }
    },
    methods: {
        addAtrPanel() {
            console.log("addAtrPanel() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let chart = this.$options.chart;

            if (generalForm.legs == 1) {
                let selected = generalForm.selected[0];
                // console.log("selected=", selected);
                let digits = spreadDigits(selected, generalForm.instrument);
                // console.log("digits=", digits);
                let formatString = "#." + "0".repeat(digits);

                let axisPercentage = heightControl("adding", chart);

                let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
                valueAxis.id = "atr-axis";
                valueAxis.tooltip.disabled = true;
                valueAxis.height = am4core.percent(axisPercentage);
                valueAxis.zIndex = 3
// this makes gap between panels
                valueAxis.marginTop = 16;
                valueAxis.renderer.baseGrid.disabled = true;
                valueAxis.renderer.inside = true;
                valueAxis.renderer.labels.template.verticalCenter = "bottom";
                valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
//valueAxis.renderer.maxLabelPosition = 0.95;
                valueAxis.renderer.fontSize = "0.8em"
                valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
                valueAxis.title.text = "ATR";
                valueAxis.title.fontWeight = "bold";
                valueAxis.zoomable = false;

                /* console.log("valueAxis.minZoomed=", valueAxis.minZoomed);
                 console.log("valueAxis.maxZoomed=", valueAxis.maxZoomed);
                 valueAxis.min = valueAxis.minZoomed;
                 valueAxis.max = valueAxis.maxZoomed;*/
                let mainData = chart.map.getKey("main-series").data;

                let series = chart.series.push(new am4charts.LineSeries());
                series.id = "atr-series";
                series.data = this.atr(removeNaNs(mainData), digits);
                series.dataFields.dateX = "date";
                series.clustered = false;
                series.dataFields.valueY = "atr";
                series.yAxis = valueAxis;
                series.tooltipText = generalForm.chartParameters.balloons ? "atr: {valueY.value.formatNumber('" + formatString + "')}" : "";
                series.name = "ATR";
                series.hiddenInLegend = true;
                series.legendSettings.labelText = "[{color}] atr: {valueY.value.formatNumber('" + formatString + "')}";
                series.stroke = "#996600";
                series.tooltip.getFillFromObject = false;
                series.tooltip.background.fill = am4core.color("#996600");
            }
        },
        atr(objectArray) {
            // console.log("atr() starting.");
            // console.log("objectArray=", objectArray);

            for (let j = 0; j < objectArray.length; j++) {
                let obj = objectArray[j];
                //  obj.highMinusLow = obj.high - obj.low;
                obj.highMinusLow = parseFloat(obj.high) - parseFloat(obj.low);
                if (j == 0) {
                    obj.tr = obj.highMinusLow;
                }
                if (j > 0) {
                    obj.highMinusPreviousClose = Math.abs(parseFloat(obj.high) - parseFloat(objectArray[j - 1].close));
                    obj.lowMinusPreviousClose = Math.abs(parseFloat(obj.low) - parseFloat(objectArray[j - 1].close));
                    obj.tr = Math.max(obj.highMinusLow, obj.highMinusPreviousClose, obj.lowMinusPreviousClose);
                }
                let avg;
                if (j === 14) {
                    let sum = 0.0;
                    for (let n = 0; n < 14; n++) {
                        sum = sum + objectArray[n].tr;
                    }
                    avg = sum / 14;
                    obj.atr = avg;
                }
                if (j > 14) {
                    avg = (13 * objectArray[j - 1].atr + obj.tr) / 14;
                    obj.atr = avg;
                }
            }
            return objectArray;
        },
        removeAtrPanel() {
            let chart = this.$options.chart;
            let series = chart.map.getKey("atr-series");
            if(typeof series !== 'undefined'){
                chart.series.removeIndex(chart.series.indexOf(series)).dispose();
                chart.yAxes.removeIndex(chart.yAxes.indexOf(chart.map.getKey("atr-axis"))).dispose();
                heightControl("removing", chart);
            }
        },
        setAtrTooltips() {
            //console.log("setAtrTooltips() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            
            let selected = generalForm.selected[0];
            // console.log("selected=", selected);
            let digits = spreadDigits(selected, generalForm.instrument);
            // console.log("digits=", digits);
            let formatString = "#." + "0".repeat(digits);

            let series = chart.map.getKey("atr-series");
            // console.log("rsiSeries=", series);
            if (typeof series !== 'undefined') {
                series.tooltipText = this.balloons ? "atr: {valueY.value.formatNumber('" + formatString + "')}" : "";
            }
        }
    }
};

export default atr;