import * as am4charts from "@amcharts/amcharts4/charts";

/*const bullets = {
 mounted() {
 console.log("mixins/bullets.js mounted() starting. this.namespace=", this.namespace);
 },
 computed: {
 showBullets() {
 return this.$store.state[this.namespace].showBullets;
 }
 },
 watch: {
 showBullets: function (showBullets) {
 console.log("watch showBullets = ", showBullets);
 if (typeof this.chart.plot.series !== 'undefined') {
 this.generalForm.showBullets = showBullets;
 showBullets ? this.addBullets(this.chart.plot, this.generalForm) : this.removeBullets(this.chart.plot);
 }
 }
 },
 methods: {*/
function removeBullets(chart) {
    //  console.log("removeBullets() starting.");
    let series = chart.map.getKey("main-series");
    // console.log("bullets=", series.bullets.length);
    series.bullets.getIndex(0).disabled = true;
    series.invalidate();
}

function addBullets(chart) {
    //  console.log("addBullets() starting.");
    let series = chart.map.getKey("main-series");
    // console.log("series.bullets=", series.bullets);

    if (series.bullets.length === 0) {
        var circleBullet = series.bullets.push(new am4charts.CircleBullet());
        circleBullet.circle.fill = "teal";
        circleBullet.circle.stroke = "teal";
        circleBullet.circle.strokeWidth = 1;
        circleBullet.circle.radius = 1.5;
    } else {
        //  console.log("series.bullets.getIndex(0)=", series.bullets.getIndex(0));
        series.bullets.getIndex(0).disabled = false;
        // console.log("bullet =", series.bullets.length);
    }
    series.invalidate();
}

export {addBullets, removeBullets};