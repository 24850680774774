import {spreadDigits, removeNaNs} from '@/js/main';
import {sd} from '@/js/am4charts-plugins/am4charts.main';
import * as am4charts from "@amcharts/amcharts4/charts";

const bb = {
    mounted() {
       // console.log("mixins/bollinger.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        bbIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "bb");
        },
        bbVisible() {
            return typeof this.bbIndicator !== 'undefined' ? this.bbIndicator.visible : null;
        },
        bbPeriod() {
            return typeof this.bbIndicator !== 'undefined' ? this.bbIndicator.period : null;
        }
    },
    watch: {
        bbVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addBollingerBands() : this.removeBollingerBands();
        },
        bbPeriod: function (period) {
            console.log("watch period=", period);
            if(this.bbVisible){
                this.addBollingerBands();
            }
        }
    },
    methods: {
        removeBollingerBands() {
            //console.log("removeBollingerBands() starting.");
            let chart = this.$options.chart;

            let series = chart.map.getKey("bollinger_sma");
            if (chart.series.indexOf(series) > -1) {
                chart.series.removeIndex(chart.series.indexOf(series)).dispose();
            }
        
            series = chart.map.getKey("bollinger_sd_minus");
            if (chart.series.indexOf(series) > -1) {
                chart.series.removeIndex(chart.series.indexOf(series)).dispose();
            }
        
            series = chart.map.getKey("bollinger_sd_plus");
            if (chart.series.indexOf(series) > -1) {
                chart.series.removeIndex(chart.series.indexOf(series)).dispose();
            }    
           // console.log("chart.series.values=", chart.series.values);
        
            let data = chart.map.getKey("main-series").data;
           // console.log("data=", data);
        
            if(typeof data !== 'undefined'){
            data.forEach(x => {
                let keys = Object.keys(x);
                //console.log("keys=", keys);
        
                let filteredKeys = keys.filter(x => x.includes("bollinger"));
               // console.log("filteredKeys=", filteredKeys);
                filteredKeys.forEach(key => {
                    delete x[key];
                });
            });
        }
        },
         addBollingerBands() {
            console.log("addBollingerBands() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let chart = this.$options.chart;

            let period = this.bbPeriod;
            this.removeBollingerBands();

            let smaSeries = chart.map.getKey("bollinger_sma");
            let alreadyPresent = typeof smaSeries !== 'undefined';
          //  console.log("alreadyPresent=", alreadyPresent);
        
            if (!alreadyPresent) {
                let selected = generalForm.selected[0];
                let digits = spreadDigits(selected, generalForm.instrument);
                let formatString = "#." + "0".repeat(digits);
        
                let mainData = chart.map.getKey("main-series").data;
                // console.log("removeNaNs(mainData)=", removeNaNs(mainData));
        
                let mainDataPlusIndicators = sd(removeNaNs(mainData), period);
                //console.log("mainDataPlusIndicators=", mainDataPlusIndicators);
        
                // chart.dataSets = chart.dataSets.filter(x => !["SMA_20:", "-2 SD:", "+2 SD:"].includes(x.title));
                mainDataPlusIndicators.forEach(x => {
                    if (typeof x["sma_" + period] !== 'undefined') {
                        x["bollinger_sma"] = (x["sma_" + period]);
                        x["bollinger_sd_plus"] = (x["sma_" + period] + 2 * x["sd_" + period]);
                        x["bollinger_sd_minus"] = (x["sma_" + period] - 2 * x["sd_" + period]);
                        delete x["sma_" + period];
                        delete x["sd_" + period];
                    }
                });
               // console.log("mainDataPlusIndicators=", mainDataPlusIndicators);
        
                smaSeries = chart.series.push(new am4charts.LineSeries());
                smaSeries.id = "bollinger_sma";
                smaSeries.data = mainDataPlusIndicators;
                smaSeries.dataFields.valueY = "bollinger_sma";
                smaSeries.dataFields.openValueY = "bollinger_sma";
                smaSeries.dataFields.dateX = "date";
               // smaSeries.tooltipText = generalForm.chartParameters.balloons ? "[{darkblue}]bollinger_sma_"+period+": {valueY.formatNumber('" + formatString + "')}" : "";
                smaSeries.name = "bollinger_sma";
                smaSeries.strokeWidth = 1;
                smaSeries.stroke = "darkblue";
                smaSeries.fillOpacity = 0.0;
                smaSeries.stacked = false;
                smaSeries.legendSettings.labelText = "[{color}]sma_" + period + ": {valueY.formatNumber('" + formatString + "')}";
                smaSeries.hiddenInLegend = true;
                // smaSeries.yAxis = chart.map.getKey("main-axis");
        
                let sdPlusSeries = chart.series.push(new am4charts.LineSeries());
                sdPlusSeries.id = "bollinger_sd_plus";
                sdPlusSeries.data = mainDataPlusIndicators;
                sdPlusSeries.dataFields.openValueY = "bollinger_sd_minus";
                sdPlusSeries.dataFields.valueY = "bollinger_sd_plus";
                sdPlusSeries.dataFields.dateX = "date";
               // sdPlusSeries.tooltipText = generalForm.chartParameters.balloons ? "[{lightblue}]bollinger_sd_plus_"+period+": {valueY.formatNumber('" + formatString + "')}" : "";
                sdPlusSeries.name = "bollinger_sd_plus";
                sdPlusSeries.strokeWidth = 1;
                sdPlusSeries.fillOpacity = 0.3;
                sdPlusSeries.fill = "#D6EAF8";
                sdPlusSeries.stroke = "lightblue";
                sdPlusSeries.stacked = false;
                sdPlusSeries.legendSettings.labelText = "[{color}]+sd: {valueY.formatNumber('" + formatString + "')}";
                sdPlusSeries.hiddenInLegend = true;
        
                let sdMinusSeries = chart.series.push(new am4charts.LineSeries());
                sdMinusSeries.id = "bollinger_sd_minus";
                sdMinusSeries.data = mainDataPlusIndicators;
                sdMinusSeries.dataFields.valueY = "bollinger_sd_minus";
                sdMinusSeries.dataFields.dateX = "date";
              //  sdMinusSeries.tooltipText = generalForm.chartParameters.balloons ? "[{lightblue}]bollinger_sd_minus_"+period+": {valueY.formatNumber('" + formatString + "')}" : "";
                sdMinusSeries.name = "bollinger_sd_minus";
                sdMinusSeries.strokeWidth = 1;
                sdMinusSeries.stroke = "lightblue";
                sdMinusSeries.legendSettings.labelText = "[{color}]-sd: {valueY.formatNumber('" + formatString + "')}";
                sdMinusSeries.hiddenInLegend = true;
            } else {
                smaSeries.show();
                chart.map.getKey("bollinger_sd_plus").show();
                chart.map.getKey("bollinger_sd_minus").show();
            }
        },
        setBollingerTooltips() {
            console.log("setBollingerTooltips() starting.");        
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
            let selected = generalForm.selected[0];

            let digits = spreadDigits(selected, generalForm.instrument)+1;
            let formatString = "#." + "0".repeat(digits);
            console.log("formatString=", formatString);
        
            let series = this.$options.chart.map.getKey("bollinger_sma");
            console.log("sma series=", series);
            if (typeof series !== 'undefined') {
              //  series.tooltipText = this.balloons ? "[{color}]sma_"+this.smaLength+": {valueY.formatNumber('" + formatString + "')}" : "";
            }
        } 
    }
};

export default bb;


