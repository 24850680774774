<template>
    <div style="border: 0px solid #505050; width: 100%;">

        <b-button-group style="margin: 6px 10px 0 14px; font-size: 1.1rem">
            <span style="padding: 6px 30px 0 10px">
                number of results: {{numberOfTrades}}
            </span>
            <span style="padding: 6px 3px 0 10px">page:
            </span>
            <b-pagination size="md" style="margin: 0px 30px 0px 0; border: 0px solid gray; padding: 0px"
                          v-model="page"
                          :total-rows="totalPages*rowsPerPage"
                          :per-page="rowsPerPage">
            </b-pagination>

            <span style="margin: -2px 0px 2px 0; "> &nbsp;rows/page:
                <b-form-select size="md" style="border: 1px solid lightgray; width: auto;" v-model="rowsPerPage" :options="rowsPerPageOptions"></b-form-select>
            </span>
        </b-button-group>

        <table id="trades" style="width: 100%;">
            <tr>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'ticker' ? 'red' : 'white' }">ticker</th>
                <th style="text-align: center; backgroundColor: white">legs</th>
                <th style="text-align: center; backgroundColor: white">group</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'position' ? 'red' : 'white' }">buy/sell</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'open' ? 'red' : 'white' }">open</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'close' ? 'red' : 'white' }">close</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'days' ? 'red' : 'white' }">days</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'percentUp' ? 'red' : 'white' }">% profitable</th>
                <th style="text-align: center;" v-bind:style="{backgroundColor: sortParameter == 'rsi' ? 'red' : 'white' }">
                    current <b-form-select size="sm" style="border:1px solid gray; width: auto; padding: -5px 0 5px 0; margin-top: -1px"
                                           v-model="rsiPeriod" :options="rsiPeriodOptions"></b-form-select>-day rsi
                </th>
                <th style="text-align: center;">actions</th>
            </tr>
            <tr v-for="n in trades" v-bind:key="n.id" style="white-space: nowrap;">
                <td v-bind:style="{textAlign: 'center', color: 'black'}">{{n.ticker}}</td>
                <td v-bind:style="{textAlign: 'center', color: 'black'}">{{n.legs}}</td>
                <td v-bind:style="{textAlign: 'center', color: 'black'}">{{n.group}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'position' ? 'red' : 'black' }">{{n.position === "LONG" ? "BUY" : "SELL"}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'open' ? 'red' : 'black' }">{{n.open}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'close' ? 'red' : 'black' }">{{n.close}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'close' ? 'red' : 'black' }">{{Math.round(1000*n.days)/1000}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'percentProfitable' ? 'red' : 'black' }">{{Math.round(10*n.profitability)/10}}</td>
                <td v-bind:style="{textAlign: 'center', color: sortParameter == 'rsi' ? 'red' : 'black' }">{{n.rsi}}</td>
                <td style=" text-align: center"> <b-form-select style="border: 1px solid gray; width: auto; margin: -1px 0 0 0; padding: 0px 25px; background-color: #05386B;
                                     color: white; height: 25px;"
                                   v-model="newProgram" :options="programOptions"
                                   size="sm" @change="getChart(n.id)" class="program-select"></b-form-select></td>
            </tr>
        </table>      

    </div>
</template>

<style>
    #trades {
        /* font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;*/
        font-weight: normal;
        color: darkgray;
        border-collapse: collapse;
        width: 100%;
    }

    #trades td {
        padding: 2px 14px 2px 14px;
        border: 1px solid #ddd;
    }

    #trades tr:nth-child(even){background-color: #f2f2f2;}

    #trades tr:hover {background-color: #ddd;}

    #trades th {
        border: 1px solid #ddd;
        padding: 4px;
        text-align: center;
        background-color: white;
        color: white;
    }

    .linkable {
        cursor: pointer;
        color: darkblue;
    }

</style>

<script>
    var ajaxRequest = null;

    import axios from "axios";
    import moment from 'moment';
    import {tickerToGeneralForm, createContractLists, ContractWeightedN_tuplets, contractNameDecomposer, getGroup, isTickerInDatabase}  from "@/js/main.js";
    import { bus } from '@/main';
    import $ from "jquery";

    export default {
        components: {
        },
        mounted() {
            console.log("trade-explorer-output.vue mounted() starting.");

            if (this.activeModuleName === this.namespace && !this.initialized) {
                this.initialized = true;
                this.getTrades();
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                initialized: false,
                trades: [],
                numberOfTrades: 0,
                totalPages: 1,
                rowsPerPageOptions: Array(10).fill(null).map((x, i) => ({value: 10 * (i + 1), text: 10 * (i + 1)})),
                newProgram: null,
                rsiPeriodOptions: Array(13).fill(null).map((x, i) => ({value: i + 2, text: i + 2})),
                filteredResult: []
            }
        },
        computed: {
             programOptions() {
                return [
                    { value: null, text: 'Select Chart' },
                    { value: 'Calculator', text: 'Calculator' },
                    { value: 'BasicCharts', text: 'Futures Charts' },
                    { value: 'TradeMaps', text: 'Trade Maps' }/*,
                    { value: 'LongTermCharts', text: 'Long Term Charts' } */
                ];
            },
            sortParameter: {
                get() {
                    return this.$store.state[this.namespace].search.sortParameter;
                },
                set(sortParameter) {
                    this.$store.commit(this.namespace + '/search/set', {key: "sortParameter", value: sortParameter});
                }
            },
            rowsPerPage: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.rowsPerPage;
                },
                set(rowsPerPage) {
                    this.$store.commit(this.namespace + '/chartParameters/setRowsPerPage', rowsPerPage);
                }
            },
            page: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.page;
                },
                set(page) {
                    this.$store.commit(this.namespace + '/chartParameters/setPage', page);
                }
            },
            query() {
                return this.$store.state[this.namespace].search.query;
            },
            activeModuleName() {
                return  this.$store.getters['activeModuleName'];
            },
            rsiPeriod: {
                get() {
                    return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "rsi").period;
                },
                set(period) {
                    this.$store.commit(this.namespace + '/indicators/set', {indicator: "rsi", key: "period", value: period})
                }
            },
        },
        watch: {
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace,
                        " this.initialized=", this.initialized);
                // console.log("this.query=", this.query);
                if (activeModuleName === this.namespace && !this.initialized && typeof this.query !== 'undefined' && !this.initialized) {
                    this.initialized = true;
                    this.getTrades();
                }
            },
            rsiPeriod(rsiPeriod){
                console.log("watching rsiPeriod=", rsiPeriod);
                this.getTrades();
            },
            rowsPerPage: function (rowsPerPage) {
                console.log("watch: rowsPerPage=", rowsPerPage);

            },
            page: function (page) {
                console.log("watch: page=", page);
                this.loadTrades();
            }
        },
        methods: {
             displayMoneyFormat: function (number) {
                // console.log("number=", number);
                let returnValue;
                if (isNaN(number) || null == number) {
                    // console.log("missing data.");
                    returnValue = "------\u00A0\u00A0\u00A0 ";
                } else {
                    returnValue = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                    }).format(number);
                }
                // console.log("returnValue=", returnValue);
                return returnValue;
            },
            getChart(index) {
                console.log("getChart() starting. index=", index);
              //  this.mouseClicked(index);
              /*  let ticker = element.target.textContent;
               console.log("element=", element);
                console.log("ticker=", ticker);*/

                let program = this.newProgram;
                this.newProgram = null;
                console.log("program=", program, " this.newProgram=", this.newProgram);

                let trade = this.trades[index];
                console.log("trade=", trade);
                let open = moment(trade.open, "MMM DD, YYYY").format("YYYY-MM-DD");
                let close = moment(trade.close, "MMM DD, YYYY").format("YYYY-MM-DD");
                console.log("open=", open, " close=", close);

                let generalForm = tickerToGeneralForm(trade.ticker);
                generalForm.open = open;
                generalForm.close = close;

               /* if (trade.position === "SHORT") {
                    generalForm.p = [-1, 1, 1, 1];
                } */

                if(program === "LongTermCharts"){
                    generalForm.aligned = true;
                    generalForm.normalization = "off";
                    generalForm.longtermChartColors= "yearly";

                    let contractListsObject = createContractLists(generalForm.sampleContract, generalForm.legs);
                    console.log("contractListsObject=", contractListsObject);          

                    let alignerOptionsObject = ContractWeightedN_tuplets(generalForm.sampleContract.slice(0, generalForm.legs), contractListsObject.contractsArray.slice());
                    console.log("alignerOptionsObject=", alignerOptionsObject);
                }

               // delete generalForm.program;
                generalForm.program = program;
                generalForm.buySell = trade.position === "LONG" ? "buy" : "sell";
                generalForm.intracommodity = true;
                generalForm.showTradingPeriod = true;
                generalForm.addRSIPanel = true;
                generalForm.rsiPeriod = this.rsiPeriod; 
                generalForm.chartParameters = {};
                generalForm.chartParameters.parameterToMap = this.sortParameter === "appd" ? "avgAppd" : this.sortParameter;
                generalForm.chartParameters.hiddenFeatures = {
                    expiration: false,
                    title: false,
                    legends: false,
                    horizontalScrollbar: true,
                    verticalScrollbar: true
                };
                console.log("generalForm=", generalForm);

                this.$store.commit("user/setInitializingState", generalForm);
                bus.$emit('newTab', program);


                /*let mergedForm = {...tradeMapsInitialState, ...generalForm};
                console.log("mergedForm=", mergedForm);
                this.$emit('newTabWithInitialState', mergedForm); */
            },
            loadTrades(){
                console.log("loadTrades() starting.");
                let that = this;
                let truncatedResult = this.filteredResult.slice((this.page - 1)*this.rowsPerPage, this.page*this.rowsPerPage);
                                let postData = truncatedResult.map(x => {
                                   // console.log("x=", x);
                                    let form = tickerToGeneralForm(x.ticker);
                                    form.rsiPeriod = this.rsiPeriod;
                                    form.ticker = x.ticker;
                                    return form;
                                });
                                console.log("postData=",postData);

                               // let url = this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/RsiArrayControllerServlet";
                                let url = "https://djscarr.com/a4a/RsiArrayControllerServlet";
                                $.ajax({
                                    url: url,
                                    type: "POST",
                                    data: {"json": JSON.stringify(postData)},
                                    success: function (data) {
                                       // that.$store.commit('decrementNumberOfAjaxCalls');
                                        let parsedData = JSON.parse(data);
                                        console.log("parsedData=", parsedData);

                                        let mergedData = truncatedResult.map(x => {
                                           // console.log("x=", x);
                                            x.rsi = parsedData.find(y => y.ticker === x.ticker).rsi;
                                            return x;
                                        });
                                       // console.log("mergedData=", mergedData);
                                        
                                        that.trades = mergedData;
                                        
                                    }
                                });
            },
            getTrades: function () {
                console.log("getTrades() starting.");
                this.$store.commit('resetNumberOfAjaxCalls');
                this.$store.commit('incrementNumberOfAjaxCalls');

                    this.trades = [];
                    let url = "https://app.charts.alpha4all.it/trade_explorer.php?Service=Explorer&Action=Get&SecureCode=@fpnr3bu5b3lud3";
                    let that = this;

                    if (ajaxRequest) {
                        console.log("ajaxRequest=", ajaxRequest);
                        ajaxRequest.cancel();
                    }

                    ajaxRequest = axios.CancelToken.source();

                    axios.get(url, {
                        cancelToken: ajaxRequest.token
                    })
                            .then(apiResponse => {
                                this.$store.commit('decrementNumberOfAjaxCalls');
                                console.log("apiResponse=", apiResponse);
                                let data = apiResponse.data;
                               // console.log("data=", data);
                                let temp = data.explorers;
                               // console.log("temp=", temp);

                                let result = temp.map(function (el, index) {
                                    let o = Object.assign({}, el);
                                   // console.log("o.ticker=", o.ticker);
                                    if (isTickerInDatabase(o.ticker)) {
                                        // let contract = spread.replace(/ /g, '').split("/");
                                        // console.log("contract=", contract);

                                        o.id = index;
                                        let open = moment(el.open_date, "YYYYMMDD");
                                        let close = moment(el.close_date, "YYYYMMDD");
                                        //  let days = el.days;
                                        o.open = open.format("MMM D, YYYY");
                                        o.close = close.format("MMM D, YYYY");
                                        o.days = el.days;
                                        o.avgMae = el.avgMin;
                                        o.worstMae = el.worstMin;
                                        o.avgMpe = el.avgMax;
                                        o.worstMpe = el.bestMax;
                                        o.rrr = el.rrr;
                                       // o.rsi = (el.position === "LONG" ? el.rsi : (100 - el.rsi)).toFixed(1);

                                        o.ticker = el.ticker;
                                        let generalForm = tickerToGeneralForm(o.ticker);
                                        o.legs = generalForm.legs;

                                        let commodity = contractNameDecomposer(generalForm.sampleContract[0]).commoditySymbol;
                                        o.group = getGroup(commodity);

                                        delete o.avgMin;
                                        delete o.worstMin;
                                        delete o.avgMax;
                                        delete o.bestMax;
                                       // console.log("o=", o);
                                    } else {
                                      //  console.log("Bad symbol, o.ticker=", o.ticker);
                                        o = "null";
                                    }
                                    return o;
                                });
                               // console.log("result=", result);
                                this.filteredResult = result.filter(x => x !== "null");
                               // console.log("this.filteredResult=", JSON.parse(JSON.stringify(this.filteredResult)));
                               
                                that.numberOfTrades = that.filteredResult.length;
                                that.totalPages = Math.ceil(that.numberOfTrades / that.rowsPerPage);
                               // console.log("numberOfTrades=", that.numberOfTrades, " that.rowsPerPage=", that.rowsPerPage +" that.totalPages=", that.totalPages);

                               this.loadTrades();
                            })
                            .catch((err) => {
                                this.$store.commit('decrementNumberOfAjaxCalls');
                                console.log("err=", err);
                            });
            }
        }
    };

</script>
