<template>
    <div id="subapp" style="background-color: white; font-weight: normal">

        <playback
            v-if="$store.state[namespace].showPlaybackControl"
            v-bind:namespace="namespace"
            v-bind:parsedData="parsedData"
            v-bind:chart="$options.chart"
            style="width: 100%; margin: 0px; padding: 0px"
            ></playback>

        <b-button size="sm" class="btn" variant="success" @click="addIndicators" style="border: 1px solid gray; margin: 1px 2px 1px 15px;">add indicator</b-button>

        <div
            v-bind:id="divName"
            v-bind:style="styleObject"
            style=" width: 100%; height: 72vh;"
            ></div>
    </div>
</template>

<style>
    #subapp {
        display: flex;
        justify-content: left;
        align-items: left;
        flex-wrap: wrap;
    }

</style>

<script>

    var seriesSwitcher;
    var valueSeries;
    var valueLegend;
    var indicatorControl;

    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import * as am5stock from "@amcharts/amcharts5/stock";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

    // import $ from "jquery";
    import moment from "moment";

    import {getSpreadExpiration, spreadDigits, waitForElm} from "@/js/main";
    import {addExpirationGuide} from "@/js/am4charts-plugins/am4charts.expiration-guide.js";
    import {changePeriod} from "@/js/am4charts-plugins/periodSelector";
    import {addBullets, removeBullets} from "@/js/am4charts-plugins/bullets";
    import {addLevels, removeLevels} from "@/js/am4charts-plugins/levels";
    import {getSeasonalData, removeSeasonal, setSeasonalTooltips} from "@/js/am4charts-plugins/seasonals";

    // import {showTradingPeriodIndicator, hideTradingPeriod} from "@/js/am4charts-plugins/tradingPeriod";

    import playback from "@/components/playback.vue";

    import profitLoss from "@/mixins/profitLoss";
    import atr from "@/mixins/atr";
    import bb from "@/mixins/bollinger";
    import cci from "@/mixins/cot";
    import cot from "@/mixins/cci";
    import macd from "@/mixins/macd";
    import rsi from "@/mixins/rsi";
    import sma from "@/mixins/sma";
    import ema from "@/mixins/ema";
    import vol from "@/mixins/volume";
    import vp from "@/mixins/volumeProfile";

    import _ from 'lodash';

    export default {
        components: {
            playback
        },
        root: {}, // <-- non-reactive property
        mixins: [profitLoss, atr, bb, cci, cot, macd, rsi, sma, ema, vol, vp],
        created: function () {
            // console.log("this.$data=", this.$data);
        },
        mounted() {
            //  console.log("am4charts-basic-chart.vue mounted.");
            this.$root.$on("change-period", this.doChangePeriod);
        },
        beforeDestroy() {
            console.log("beforeDestroy() starting.");
            //  this.$options.chart.dispose && this.$options.chart.dispose(); //Not sure if this works.
            if (this.$options.chart.dispose) {
                //  console.log("disposing chart.");
                this.$options.chart.dispose();
            }
            this.$root.$off("change-period", this.doChangePeriod);
        },
        props: {
            divName: {
                type: String,
                default: "chartDiv",
            },
            namespace: {
                type: String,
                default: "",
            },
            parsedData: {
                type: Object,
            },
        },
        data: function () {
            return {
                checkingDate: "",
                expiration: "",
                //  chart: {},
                chartData: [],
                seasonalDataArray: [],
            };
        },
        computed: {
            styleObject() {
                return this.$store.state[this.namespace].program === "TradeMaps"
                        ? {height: "374px"}
                : {};
            },
            stockGraphType() {
                return this.$store.state[this.namespace].stockGraphType;
            },
            indicators() {
                console.log("testing here.");
                return  this.$store.state[this.namespace].indicators.indicators;
            },
            selectedIndicators() {
                return this.$store.getters[this.namespace + "/indicators/selectedIndicators"].map(x => x.shortName);
            },
            addBollinger() {
                return this.$store.state[this.namespace].addBollinger;
            },
            bollingerPeriod() {
                return this.$store.state[this.namespace].bollingerPeriod;
            },
            addVolumeProfile() {
                return this.$store.state[this.namespace].addVolumeProfile;
            },
            volumeProfileColumns() {
                return this.$store.state[this.namespace].volumeProfileColumns;
            },
            loading() {
                return this.$store.state[this.namespace].loading;
            },
            skipEmptyPeriods() {
                return this.$store.state[this.namespace].chartParameters.skipEmptyPeriods;
            },
            balloons() {
                return this.$store.state[this.namespace].chartParameters.balloons;
            },
            showBullets() {
                return this.$store.state[this.namespace].showBullets;
            },
            showLevels() {
                return this.$store.state[this.namespace].addHorizontalLine;
            },
            levels: {
                get() {
                    //  console.log("this.$store.state[this.namespace].chartParameters=", this.$store.state[this.namespace].chartParameters);
                    return this.$store.state[this.namespace].chartParameters.levels;
                },
                set(level) {
                    console.log("levels set(). level=", level);
                }
            },
            availableSeasonals() {
                return this.$store.getters[this.namespace + "/availableSeasonals"];
            },
            showTradingPeriod() {
                return this.$store.state[this.namespace].showTradingPeriod;
            },
            open() {
                return this.$store.state[this.namespace].open;
            },
            close() {
                return this.$store.state[this.namespace].close;
            },
            locale() {
                return this.$store.state.settings.locale;
            },
            showPlaybackControl() {
                return this.$store.state[this.namespace].showPlaybackControl;
            },
            horizontalScrollbar() {
                return this.$store.state[this.namespace].chartParameters.hiddenFeatures.horizontalScrollbar;
            },
            buySell() {
                return this.$store.state[this.namespace].buySell;
            },
            symbols() {
                return this.$store.state.user.symbols;
            },
            zoomToSavedCoordinates() {
                return this.$store.state[this.namespace].zoomToSavedCoordinates;
            },
            verticalAdaptiveScaling: {
                get() {
                    return this.$store.state[this.namespace].chartParameters.verticalAdaptiveScaling;
                },
                set(verticalAdaptiveScaling) {
                    console.log("verticalAdaptiveScaling.set() starting. verticalAdaptiveScaling=", verticalAdaptiveScaling);
                    this.$store.commit(this.namespace + '/chartParameters/setVerticalAdaptiveScaling', verticalAdaptiveScaling);
                }
            }
        },
        watch: {
            levels: {
                deep: true,

                handler() {
                    // console.log("watch levels. this.activeModuleName=", this.activeModuleName, " this.namespace=", this.namespace, " this.initialized=", this.initialized);
                    if (this.showLevels && Object.keys(this.$options.chart).length > 0) {
                        let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                        addLevels(this.$options.chart, generalForm);
                    }
                }
            },
            symbols(symbols) {
                console.log("watch symbols=", symbols);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createChart(generalForm, this.divName);
            },
            verticalAdaptiveScaling(verticalAdaptiveScaling) {
                console.log("watch verticalAdaptiveScaling=", verticalAdaptiveScaling);
                let mainAxis = this.$options.chart.map.getKey("main-axis");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (!generalForm.chartParameters.verticalAdaptiveScaling) {
                    //  mainAxis.min = mainAxis.minZoomed;
                    //  mainAxis.max = mainAxis.maxZoomed;
                } else {
                    mainAxis.min = null;
                    mainAxis.max = null;
                }
            },
            parsedData: function (parsedData) {
                console.log("watch parsedData = ", JSON.parse(JSON.stringify(parsedData)));
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (parsedData !== null) {
                    this.createChart(generalForm, this.divName);
                } else {
                    if (this.$options.chart.dispose) {
                        //  console.log("disposing chart.");
                        this.$options.chart.dispose();
                    }
                }
            },
            loading: function (loading) {
                // console.log("loading watch!");
                // console.log("this.divName=", this.divName);
                if (loading) {
                    // document.getElementById(this.divName).innerHTML = "";
                }
            },
            stockGraphType: function () {
                // console.log("watching stockGraphType");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                //console.log("generalForm=", generalForm);
                this.createChart(generalForm, this.divName);
            },
            skipEmptyPeriods: function () {
                let dateAxis = this.$options.chart.map.getKey("date-axis");
                if (typeof dateAxis !== 'undefined') {
                    dateAxis.skipEmptyPeriods = this.$store.state[this.namespace].chartParameters.skipEmptyPeriods;
                }
            },
            balloons: function (balloons) {
                console.log("balloons.set() started.");
                let series = this.$options.chart.map.getKey("main-series");
                // console.log("series=", series);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                let tooltipText = "";
                if (generalForm.stockGraphType === "line") {
                    tooltipText = balloons ? "close: {valueY.value}" : "";
                } else {
                    tooltipText = balloons
                            ? "open: {openValueY.value}\nlow: {lowValueY.value}\nhigh: {highValueY.value}\nclose: {valueY.value}"
                            : "";
                }
                series.tooltipText = tooltipText;

                this.setRsiTooltips();
                this.setVolumeTooltips();
                this.setVolumeProfileTooltips();
                this.setCciTooltips();
                this.setMacdTooltips();
                this.setAtrTooltips();
                this.setCotTooltips();
                this.setSmaTooltips();
                this.setEmaTooltips();
                this.setBollingerTooltips();
                setSeasonalTooltips(this.$options.chart, balloons, generalForm);
            },
            showBullets: function (showBullets) {
                // console.log("watch showBullets = ", showBullets);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                showBullets ? addBullets(this.$options.chart, generalForm) : removeBullets(this.$options.chart);
            },
            showLevels: function (showLevels) {
                // console.log("watch showLevels = ", showLevels);
                if (Object.keys(this.$options.chart).length > 0) {
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    showLevels && this.showPlaybackControl ? addLevels(this.$options.chart, generalForm) : removeLevels(this.$options.chart);
                }
            },
            showPlaybackControl: function (showPlaybackControl) {
                console.log("watch showPlaybackControl=", showPlaybackControl);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.showLevels && showPlaybackControl ? addLevels(this.$options.chart, generalForm) : removeLevels(this.$options.chart);
            },

            /*  levels: {
             deep: true,
             
             handler() {
             // console.log("watch levels. this.activeModuleName=", this.activeModuleName, " this.namespace=", this.namespace, " this.initialized=", this.initialized);
             if (this.showLevels && Object.keys(this.$options.chart).length > 0) {
             let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
             addLevels(this.$options.chart, generalForm);
             }
             }
             },*/
            availableSeasonals: function (newAvailableSeasonals, oldAvailableSeasonals) {
                console.log("watch newAvailableSeasonals=", newAvailableSeasonals.slice(0), " oldAvailableSeasonals=", oldAvailableSeasonals.slice(0), " namespace=", this.namespace);
                let seasonalsToRemove = oldAvailableSeasonals.filter(x => !this.availableSeasonals.includes(x));
                // console.log("seasonalsToRemove =", seasonalsToRemove);
                removeSeasonal(seasonalsToRemove, this.$options.chart);

                if (newAvailableSeasonals.length > oldAvailableSeasonals.length) {
                    let seasonalToAdd = newAvailableSeasonals.find((x) => !oldAvailableSeasonals.includes(x));
                    console.log("seasonalToAdd=", seasonalToAdd);
                    let seasonalColorOptions = this.$store.getters[this.namespace + "/seasonalOptions"];
                    console.log("seasonalColorOptions=", seasonalColorOptions);
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    this.callGetSeasonalData([seasonalToAdd], this.$options.chart, generalForm, this.seasonalDataArray, seasonalColorOptions, this);
                }

                if (newAvailableSeasonals.length < oldAvailableSeasonals.length) {
                    //  oldSeasonals = this.seasonals.slice(0);
                }
            },

            showTradingPeriod: function (showTradingPeriod) {
                console.log("watch showTradingPeriod = ", showTradingPeriod);
                //  let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // showTradingPeriod ? showTradingPeriodIndicator(this.$options.chart, generalForm) : hideTradingPeriod(this.$options.chart);
            },
            /*  showCOTPanel: function (addCOTPanel) {
             let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
             if (addCOTPanel) {
             addCotPanel(this.$options.chart, this.parsedData, generalForm, this);
             } else {
             $("#no_cot_message").remove();
             removeCotPanel(this.$options.chart);
             }
             },*/
            open: function (newOpen, oldOpen) {
                console.log("watch: newOpen=", newOpen, " oldOpen=", oldOpen);
                //  let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("this.$options.root=", this.$options.root);
                if (Object.keys(this.$options.root).length > 0) {
                    // showTradingPeriodIndicator(this.$options.root, generalForm);
                }
            },
            close: function (newClose, oldClose) {
                console.log("watch: newClose=", newClose, " oldClose=", oldClose);
                //  let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (Object.keys(this.$options.root).length > 0) {
                    // showTradingPeriodIndicator(this.$options.root, generalForm);
                }
            },
            locale: function (locale) {
                console.log("watch: locale=", locale);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                this.createChart(generalForm, this.divName);
            },
            horizontalScrollbar: function (horizontalScrollbar) {
                console.log("watch: horizontalScrollbar=", horizontalScrollbar);
                if (typeof this.$options.chart.scrollbarX !== 'undefined') {
                    this.$options.chart.scrollbarX.disabled = horizontalScrollbar;
                }
            },
            buySell() {
                console.log("watching buySell");
                // let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // showTradingPeriodIndicator(this.$options.chart, generalForm);
            }
        },
        methods: {
            clickHandler() {
                console.log("clickHandler() starting.");
                //  console.log("this.$options.chart.plugins=", this.$options.chart.plugins);
                //  console.log("this.$options.chart.plugins.values[0].data=", this.$options.chart.plugins.values[0].data);
                let annotation_data = this.$options.chart.plugins.values[0].data;
                console.log("annotation_data=", annotation_data);
                if (annotation_data.objects.length > 0) {
                    //  this.verticalAdaptiveScaling = false;
                }
                this.$store.dispatch(this.namespace + "/chartParameters/storeAnnotationData", annotation_data);

            },
            callGetSeasonalData: _.debounce(function (seasonalToAdd, chart, generalForm, seasonalDataArray, seasonalColorOptions, that) {
                console.log("callGetSeasonalData() starting.");
                getSeasonalData(seasonalToAdd, chart, generalForm, seasonalDataArray, seasonalColorOptions, that);
            }, 100),

            doChangePeriod(id) {
                // console.log("doChangePeriod() starting. id=", id);
                //  if (this.activeModuleName === this.namespace) {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                //  console.log("this.$options.chart=", this.$options.chart);
                if (Object.keys(this.$options.chart).length > 0) {
                    changePeriod(id, this.$options.chart, generalForm);
                }
            },
            removeChart: function () {
                console.log("removeChart() starting.");
                //  console.log("divName=", this.divName);
                if (document.getElementById(this.divName) !== null) {
                    document.getElementById(this.divName).innerHTML = "";
                    // document.getElementById(this.divName).style.border = '0px solid gray';
                }
            },
            addData(bar) {
                console.log("addData() starting. bar=", JSON.parse(JSON.stringify(bar)));
                //  console.log("this.$options.chart=", this.$options.chart);
                // console.log("this.$options.chart.series=", this.$options.chart.series);

                let mainSeries = this.$options.chart.series.values.find(x => x.id === "main-series");
                console.log("mainSeries.data=", mainSeries.data);
                let lastBar = mainSeries.data[mainSeries.data.length - 1];
                console.log("bar.date=", moment(bar.date).format("YYYYMMDD"), " lastBar.date=", moment(lastBar.date).format("YYYYMMDD"));

                if (moment(bar.date).format("YYYYMMDD") === moment(lastBar.date).format("YYYYMMDD")) {
                    console.log("Replacing last bar.");

                    mainSeries.data[mainSeries.data.length - 1] = bar;

                    this.$options.chart.series.each(function (series) {
                        if (!series.id.includes("-y") && !series.id.includes("scrollbar") && !series.id.includes("profit-loss") && !series.id.includes("horizontal")) {
                            console.log("series.id=", series.id);
                            series.invalidateData();
                        }
                    });
                    // console.log("lastBar=", lastBar);
                    // console.log("mainSeries.data=", mainSeries.data);
                } else {
                    console.log("Adding new bar.");
                    mainSeries.addData(JSON.parse(JSON.stringify(bar)), 0);
                }

            },
            createChart: function (generalForm, divName = "chartDiv") {
                console.log("createChart() starting. divName=", divName);
                //  this.$options.chart.dispose && this.$options.chart.dispose(); //Not sure if this works.
                /*   if (this.$options.chart.dispose) {
                 //  console.log("disposing chart.");
                 this.$options.chart.dispose();
                 }*/

                let parsedData = this.parsedData;

                if (parsedData === null || ["none", "Not enough data."].includes(parsedData.values)) {
                    document.getElementById(this.divName).innerHTML =
                            "<p style='color: red; font-size: 12px; height: 100%; margin-top: 100px'>Unable to make a chart from the current selections.</p>";
                    return;
                }

                //  this.generalForm = generalForm;
                // this.$store.commit(this.namespace + "/browserSideOnly/setMinUnreturnedSeasonal", 40);

                if (document.getElementById(divName) !== null) {
                    // document.getElementById(divName).style.border = '1px solid gray';
                }

                Object.values(parsedData.values).forEach((x) => {
                    if (generalForm.program === "TimeSeriesCharts") {
                        x.date = new Date(x.date);
                    } else {
                        x.date = moment(x.date, "YYYYMMDD").toDate();
                    }
                });

                if (generalForm.instrument === "future" && typeof parsedData.seasonal !== "undefined" && parsedData.seasonal !== "Not enough data.") {
                    let seasonalDataArray = Object.keys(parsedData.seasonal.values).map(
                            (date) => ({date: moment(date, "YYYYMMDD").format("YYYY-MM-DD"), ...parsedData.seasonal.values[date]})
                    );
                    // console.log("seasonalDataArray=", [...seasonalDataArray]);
                    parsedData.seasonal.values = seasonalDataArray;
                }
                // console.log("parsedData=", parsedData);


                console.log("this.$options.root=", this.$options.root);
                if (Object.keys(this.$options.root).length > 0)
                    this.$options.root.dispose();
                if (document.getElementById("chartcontrols") !== null) {
                    document.getElementById("chartcontrols").innerHTML = "";
                }

                this.$options.root = am5.Root.new(this.divName);
                this.$options.root.setThemes([am5themes_Animated.new(this.$options.root)]);
                var stockChart = this.$options.root.container.children.push(am5stock.StockChart.new(this.$options.root, {}));

                let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
                // console.log("digits=", digits);
                let formatString = "#,###." + "0".repeat(digits);
                this.$options.root.numberFormatter.set("numberFormat", formatString);

                var mainPanel = stockChart.panels.push(am5stock.StockPanel.new(this.$options.root, {
                    wheelY: "zoomX",
                    panX: true,
                    panY: true,
                    height: am5.percent(70)
                }));

                var valueAxis = mainPanel.yAxes.push(am5xy.ValueAxis.new(this.$options.root, {
                    renderer: am5xy.AxisRendererY.new(this.$options.root, {
                        pan: "zoom"
                    }),
                    tooltip: am5.Tooltip.new(this.$options.root, {}),
                    numberFormat: formatString,
                    extraTooltipPrecision: 2
                }));

                var dateAxis = mainPanel.xAxes.push(am5xy.DateAxis.new(this.$options.root, {
                    baseInterval: {
                        timeUnit: "day",
                        count: 1
                    },
                    groupData: false,
                    renderer: am5xy.AxisRendererX.new(this.$options.root, {}),
                    tooltip: am5.Tooltip.new(this.$options.root, {})
                }));

                let that = this;
                valueSeries = mainPanel.series.push(am5xy.CandlestickSeries.new(this.$options.root, {
                    name: that.parsedData.title,
                    valueXField: "date",
                    valueYField: "close",
                    highValueYField: "high",
                    lowValueYField: "low",
                    openValueYField: "open",
                    calculateAggregates: false,
                    xAxis: dateAxis,
                    yAxis: valueAxis,
                    legendValueText: "{valueY}"
                }));

                stockChart.set("stockSeries", valueSeries);

                var volumePanel = stockChart.panels.push(am5stock.StockPanel.new(this.$options.root, {
                    wheelY: "zoomX",
                    panX: true,
                    panY: true,
                    height: am5.percent(30)
                }));

                var volumeValueAxis = volumePanel.yAxes.push(am5xy.ValueAxis.new(this.$options.root, {
                    numberFormat: "#.#a",
                    renderer: am5xy.AxisRendererY.new(this.$options.root, {
                        pan: "zoom"
                    })
                }));

                var volumeAxisRenderer = am5xy.AxisRendererX.new(this.$options.root, {});
                var volumeDateAxis = volumePanel.xAxes.push(am5xy.DateAxis.new(this.$options.root, {
                    baseInterval: {
                        timeUnit: "day",
                        count: 1
                    },
                    renderer: volumeAxisRenderer,
                    tooltip: am5.Tooltip.new(this.$options.root, {
                        forceHidden: false,
                        animationDuration: 200
                    })
                }));

// hide labels
// volumeAxisRenderer.labels.template.set("forceHidden", true);

                var volumeSeries = volumePanel.series.push(am5xy.ColumnSeries.new(this.$options.root, {
                    name: "volume",
                    valueXField: "date",
                    valueYField: "volume",
                    xAxis: volumeDateAxis,
                    yAxis: volumeValueAxis,
                    legendValueText: "{valueY}"
                }));

                stockChart.set("volumeSeries", volumeSeries);

                valueLegend = mainPanel.plotContainer.children.push(am5stock.StockLegend.new(this.$options.root, {
                    stockChart: stockChart
                }));
                valueLegend.data.setAll([valueSeries]);

                var volumeLegend = volumePanel.topPlotContainer.children.push(am5stock.StockLegend.new(this.$options.root, {
                    stockChart: stockChart
                }));
                volumeLegend.data.setAll([volumeSeries]);

                mainPanel.set("cursor", am5xy.XYCursor.new(this.$options.root, {
                    yAxis: valueAxis,
                    xAxis: dateAxis,
                    snapToSeries: [valueSeries],
                  //  snapToSeriesBy: "y!"
                }));

                var volumeCursor = volumePanel.set("cursor", am5xy.XYCursor.new(this.$options.root, {
                    yAxis: volumeValueAxis,
                    xAxis: volumeDateAxis,
                    snapToSeries: [volumeSeries],
                  //  snapToSeriesBy: "y!"
                }));

// hide y line on volume panel
                volumeCursor.lineY.set("forceHidden", true);

                var scrollbar = mainPanel.set("scrollbarX", am5xy.XYChartScrollbar.new(this.$options.root, {
                    orientation: "horizontal",
                    height: 50
                }));
                stockChart.toolsContainer.children.push(scrollbar);

                var sbDateAxis = scrollbar.chart.xAxes.push(am5xy.GaplessDateAxis.new(this.$options.root, {
                    baseInterval: {
                        timeUnit: "day",
                        count: 1
                    },
                    renderer: am5xy.AxisRendererX.new(this.$options.root, {})
                }));

                var sbValueAxis = scrollbar.chart.yAxes.push(am5xy.ValueAxis.new(this.$options.root, {
                    renderer: am5xy.AxisRendererY.new(this.$options.root, {})
                }));

                var sbSeries = scrollbar.chart.series.push(am5xy.LineSeries.new(this.$options.root, {
                    valueYField: "close",
                    valueXField: "date",
                    xAxis: sbDateAxis,
                    yAxis: sbValueAxis
                }));

                sbSeries.fills.template.setAll({
                    visible: true,
                    fillOpacity: 0.3
                });

                function loadData(ticker, series) {

                    let values = JSON.parse(JSON.stringify(Object.values(that.parsedData.values)));
                    // console.log("values=", values);

                    var data = am5.JSONParser.parse(values);

                    // Process data (convert dates and values)
                    var processor = am5.DataProcessor.new(that.$options.root, {
                        dateFields: ["date"],
                        dateFormat: "yyyyMMdd",
                        numericFields: ["open", "high", "low", "close", "volume", "openInterest"]
                    });
                    processor.processMany(data);
                    console.log("data=", data);

                    // Set data
                    am5.array.each(series, function (item) {
                        item.data.setAll(data);
                    });
                    volumeSeries.data.setAll(data);

                }

// Load initial data for the first series
                loadData(that.parsedData.title, [valueSeries, sbSeries]);

// Custom settings control
// https://www.amcharts.com/docs/v5/charts/stock/toolbar/series-type-control/
                seriesSwitcher = am5stock.SeriesTypeControl.new(this.$options.root, {
                    stockChart: stockChart
                });

                seriesSwitcher.events.on("selected", function (ev) {
                    setSeriesType(ev.item.id);
                });

                function getNewSettings(series) {
                    var newSettings = [];
                    am5.array.each(["name", "valueYField", "highValueYField", "lowValueYField", "openValueYField", "calculateAggregates",
                     "valueXField", "xAxis", "yAxis", "legendValueText", "stroke", "fill"], function (setting) {
                        newSettings[setting] = series.get(setting);
                    });
                    return newSettings;
                }

                function setSeriesType(seriesType) {
                    // Get current series and its settings
                    var currentSeries = stockChart.get("stockSeries");
                    var newSettings = getNewSettings(currentSeries);

                    // Remove previous series
                    var data = currentSeries.data.values;
                    mainPanel.series.removeValue(currentSeries);

                    // Create new series
                    var series;
                    switch (seriesType) {
                        case "line":
                            series = mainPanel.series.push(am5xy.LineSeries.new(that.$options.root, newSettings));
                            break;
                        case "candlestick":
                        case "procandlestick":
                            newSettings.clustered = false;
                            series = mainPanel.series.push(am5xy.CandlestickSeries.new(that.$options.root, newSettings));
                            if (seriesType == "procandlestick") {
                                series.columns.template.get("themeTags").push("pro");
                            }
                            break;
                        case "ohlc":
                            newSettings.clustered = false;
                            series = mainPanel.series.push(am5xy.OHLCSeries.new(that.$options.root, newSettings));
                            break;
                    }

                    // Set new series as stockSeries
                    if (series) {
                        valueLegend.data.removeValue(currentSeries);
                        series.data.setAll(data);
                        stockChart.set("stockSeries", series);
                        var cursor = mainPanel.get("cursor");
                        if (cursor) {
                            cursor.set("snapToSeries", [series]);
                        }
                        valueLegend.data.insertIndex(0, series);
                    }
                }

                waitForElm('.some-class').then(() => {
                    console.log('Element is ready');
                    this.loadChartControls();
                });

            },
            addIndicators() {
                console.log("addIndicators() starting.");
                // Define indicator class
                let that = this;
                class MyIndicator extends am5stock.Indicator {

                    _editableSettings = [{
                            key: "margin",
                            name: "Margin",
                            type: "number"
                        }, {
                            key: "seriesColor",
                            name: "Color",
                            type: "color"
                        }, {
                            key: "seriesStyle",
                            name: "Line Style",
                            type: "dropdown",
                            options: ["Solid", "Dashed"]
                        }];

                    _afterNew() {

                        // Set default indicator name
                        this._setDefault("name", "My Indicator");
                        this._setDefault("margin", 100);
                        this._setDefault("seriesColor", am5.color(0x045153));
                        this._setDefault("seriesStyle", "Solid");
                        this._setDefault("showFill", false);

                        // Setting up indicator elements
                        var stockSeries = this.get("stockSeries");
                        var chart = stockSeries.chart;

                        if (chart) {
                            var series = chart.series.push(am5xy.LineSeries.new(this._root, {
                                valueXField: "date",
                                valueYField: "5",
                                groupDataDisabled: true,
                                calculateAggregates: false,
                                xAxis: stockSeries.get("xAxis"),
                                yAxis: stockSeries.get("yAxis"),
                                themeTags: ["indicator"],
                                name: "My indicator",
                                legendLabelText: "{name}",
                                legendValueText: "5 [bold]{valueY}[/]",
                                legendRangeValueText: "",
                                stroke: this.get("seriesColor"),
                               // fill: this.get("seriesColor")
                            }));

                            series.fills.template.setAll({
                                fillOpacity: 0.3,
                                visible: true
                            });

                            this.series = series;
                            this._handleLegend(series);
                        }

                        // Don't forget inherited stuff
                        super._afterNew();
                    }

                    _beforeChanged() {

                        if (this.isDirty("margin")) {
                            this.prepareData();
                        }

                        if (this.isDirty("seriesStyle")) {
                            var style = this.get("seriesStyle");
                            if (style == "Dashed") {
                                this.series.strokes.template.set("strokeDasharray", [4, 4]);
                            } else {
                                this.series.strokes.template.remove("strokeDasharray");
                            }
                        }

                        if (this.isDirty("showFill")) {
                            this.series.fills.template.set("visible", this.get("showFill"));
                        }

                        // Don't forget inherited stuff
                        super._beforeChanged();
                    }

                    prepareData() {
                        console.log("prepareData() starting.");
                        // Setting up data
                      //  var stockSeries = this.get("stockSeries");
                       // var dataItems = stockSeries.dataItems;
                       // var data = this._getDataArray(dataItems);
                       // console.log("that.seasonalDataArray=", that.seasonalDataArray);
                       // var margin = this.get("margin", 0);

                      /*  am5.array.each(data, function (item, i) {
                           // console.log("that.seasonDataArray[i]=", that.seasonalDataArray[i]);
                           // let baseValue = dataItems[i].get("valueY", 0);
                           // console.log("baseValue= ", baseValue);
                            if(typeof that.seasonalDataArray[i] !== 'undefined'){
                            item.valueY1 = that.seasonalDataArray[i][5];
                            item.valueY2 = that.seasonalDataArray[i][15];
                            }
                        }); */

                        let seasonalData = that.seasonalDataArray.map(bar => {
                           // console.log("bar=", JSON.parse(JSON.stringify(bar)));

                            let momentDate = moment(bar.date, "YYYYMMDD").format("YYYY-MM-DD");
                           // console.log("bar.date=", bar.date, " momentDate=", momentDate);

                            let timestamp =  Date.parse(momentDate);
                           // console.log("timestamp=", timestamp);

                            let valid = (new Date(timestamp)).getTime() > 0;

                            if(valid){
                            bar.date = timestamp;
                            }
                            //console.log("bar=", JSON.parse(JSON.stringify(bar)));
                            return bar;
                        })
                        console.log("seasonalData=", JSON.parse(JSON.stringify(seasonalData)));

                        this.series.data.setAll(seasonalData);
                    }

                    _dispose() {
                        this.series.dispose();
                        super._dispose();
                    }
                }

// Add indicator

                let stockChart = this.$options.root.container.children.values[0];

                /*var myIndicator = */stockChart.indicators.push(MyIndicator.new(this.$options.root, {
                    stockChart: stockChart,
                    stockSeries: valueSeries,
                    legend: valueLegend
                }));

// Create indicator control
                indicatorControl = am5stock.IndicatorControl.new(this.$options.root, {
                    stockChart: stockChart,
                    legend: valueLegend
                });

// Get current indicators
                var indicators = indicatorControl.get("indicators", []);

// Add custom indicator to the top of the list
              //  let that = this;
                indicators.unshift({
                    id: "myIndicator",
                    name: "My indicator",
                    callback: function () {
                        var myIndicator = stockChart.indicators.push(MyIndicator.new(that.$options.root, {
                            stockChart: stockChart,
                            stockSeries: valueSeries,
                            legend: valueLegend
                        }));
                        return myIndicator;
                    }
                });

// Set indicator list back
                indicatorControl.set("indicators", indicators);
            },
            loadChartControls() {
                console.log("loadChartControls() starting.");
               // this.addIndicators();
                let stockChart = this.$options.root.container.children.values[0];


                /* var toolbar = */  am5stock.StockToolbar.new(this.$options.root, {
                    container: document.getElementById("chartcontrols"),
                    stockChart: stockChart,
                    controls: [
                        seriesSwitcher,
                        am5stock.ResetControl.new(this.$options.root, {
                            stockChart: stockChart
                        }),
                        am5stock.SettingsControl.new(this.$options.root, {
                            stockChart: stockChart
                        }),
                        am5stock.IndicatorControl.new(this.$options.root, {
                            stockChart: stockChart
                        }),
                        am5stock.DrawingControl.new(this.$options.root, {
                            stockChart: stockChart
                        }),
                        am5stock.PeriodSelector.new(this.$options.root, {
                            stockChart: stockChart,
                            periods: [
                                {timeUnit: "month", count: 1, name: "1M"},
                                {timeUnit: "month", count: 6, name: "6M"},
                                {timeUnit: "year", count: 1, name: "1Y"},
                                {timeUnit: "max", name: "Max"},
                            ]
                        })
                    ]
                });
                //  console.log("toolbar=", toolbar);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (generalForm.instrument === "future" && generalForm.program !== "TimeSeriesCharts") {
                    //  console.log("isSpreadOpen(generalForm.selected[0])=", isSpreadOpen(generalForm.selected[0]));
                    //  console.log("generalForm.showSeasonals=", generalForm.showSeasonals);
                    // console.log("generalForm.seasonals=", generalForm.seasonals);
                    //  removeSeasonal(generalForm.seasonals, that.$options.chart);

                    if (generalForm.showSeasonals && this.availableSeasonals.slice(0).length > 0) {
                        this.seasonalDataArray = [];
                        let seasonalColorOptions = this.$store.getters[this.namespace + "/seasonalOptions"];
                        //  console.log("seasonalColorOptions=", seasonalColorOptions);
                        let that = this;
                        getSeasonalData(this.availableSeasonals.slice(0), this.$options.chart, generalForm, this.seasonalDataArray,
                         seasonalColorOptions, this).then(data => {
                            console.log("seasonalDataArray=", data);
                            that.seasonalDataArray = data;
                           // that.addIndicators();
                         });
                    } else {
                        this.initialZoom();
                    }
                }
            },
            truncateData: function () {
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                // console.log("truncateData() starting. generalForm.chartParameters=", generalForm.chartParameters);

                const {truncate, selected} = generalForm;
                // console.log("truncate=", truncate, "  selected=", selected);

                this.expiration = typeof this.parsedData.expiration !== "undefined" ? this.parsedData.expiration : getSpreadExpiration(selected[0]);
                // console.log("this.expiration=", this.expiration);

                let seasonalDataObject = this.parsedData.seasonal;
                // console.log("seasonalDataObject=", {...seasonalDataObject});

                let lastDateOfSeasonal;
                if (typeof seasonalDataObject !== "undefined" && seasonalDataObject !== "Not enough data.") {
                    lastDateOfSeasonal = seasonalDataObject.values[seasonalDataObject.values.length - 1].date;
                } else {
                    lastDateOfSeasonal = this.expiration;
                }

                let end = moment(Math.min(this.expiration, lastDateOfSeasonal.toString().replace(/-/g, "")), "YYYYMMDD").add(1, "days").format("YYYY-MM-DD");
                // console.log("end=", end);
                generalForm.chartParameters.end = end;
                this.$store.commit(this.namespace + "/chartParameters/setEnd", end);

                this.checkingDate = moment(this.expiration, "YYYYMMDD").subtract(6, "months");
                // console.log("this.checkingDate=", this.checkingDate.format("MMM D, YYYY"));

                let currentDate = moment();
                // console.log("currentDate=", currentDate);

                let start;
                if (!currentDate.isBefore(this.checkingDate)) {
                    // console.log("currentDate is after this.checkingDate");
                    start = moment(end).subtract(truncate, "year").format("YYYY-MM-DD");
                } else {
                    // console.log("currentDate is NOT after this.checkingDate");
                    start = currentDate.clone().subtract(6 + (truncate - 1) * 12, "months").format("YYYY-MM-DD");
                }
                // console.log("start=", start, " end=", end);
                this.$store.commit(this.namespace + "/chartParameters/setStart", start);
                // this.generalForm.chartParameters.start = start;
                //console.log("this.parsedData.values=", this.parsedData.values);
                let values = Object.values(this.parsedData.values).filter(
                        x => !moment(x.date).isBefore(moment(start))
                // (x) => x.date >= start
                );
                // console.log("values=", values);
                return values;
            },
            verticalZoom() {
                console.log("verticalZoom() starting.");
                let mainAxis = this.$options.chart.map.getKey("main-axis");
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

                let minYZoomed = generalForm.chartParameters.minYZoomed;
                let maxYZoomed = generalForm.chartParameters.maxYZoomed;
                console.log("minYZoomed=", minYZoomed + " maxYZoomed=", maxYZoomed);
                mainAxis.zoomToValues(minYZoomed, maxYZoomed);

            },
            initialZoom() {
                console.log("initialZoom() starting.");
                let dateAxis = this.$options.chart.map.getKey("date-axis");
                // let mainAxis = this.$options.chart.map.getKey("main-axis");

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                let minZoomed = generalForm.chartParameters.minZoomed;
                let maxZoomed = generalForm.chartParameters.maxZoomed;
                console.log("minZoomed=", minZoomed + " maxZoomed=", maxZoomed);

                console.log("this.zoomToSavedCoordinates=", this.zoomToSavedCoordinates);
                if (this.zoomToSavedCoordinates) {
                    this.$store.commit(this.namespace + "/chartParameters/setStart", minZoomed);
                    this.$store.commit(this.namespace + "/chartParameters/setEnd", maxZoomed);

                    console.log("Zooming to saved coordinates.");
                    console.log("start.toDate()=", moment(minZoomed).toDate());
                    console.log("end.toDate()=", moment(maxZoomed).toDate());

                    dateAxis.zoomToDates(moment(minZoomed).toDate(), moment(maxZoomed).toDate());
                } else if (this.$store.state[this.namespace].chartParameters.initialZoom) {
                    let {truncate} = generalForm;
                    //  console.log("this.checkingDate=", this.checkingDate.format("MMM D, YYYY"));
                    let currentDate = moment();
                    // console.log("currentDate=", currentDate);

                    //  console.log("this.chartData=", this.chartData);
                    let firstChartDate = moment(this.chartData[0].date);
                    //  this.$store.commit('generalForm/chartParameters/setFirstChartDate', firstChartDate.format('YYYY-MM-DD'));
                    //  this.$store.commit('generalForm/chartParameters/setLastChartDate', this.chartData[this.chartData.length - 1].date);

                    // console.log("firstChartDate =", firstChartDate);
                    // console.log("truncate=", truncate);

                    let start, end;
                    if (!currentDate.isBefore(this.checkingDate)) {
                        //  console.log("currentDate is after checkingDate");
                        end = moment(this.expiration, "YYYYMMDD");  //.add(1, "days");
                        start = moment(end).subtract(truncate, "year");
                    } else {
                        // console.log("currentDate is NOT after checkingDate");
                        end = currentDate.clone().add(6, "months");  //.add(1, "days");
                        start = currentDate.clone().subtract(6 + (truncate - 1) * 12, "months");
                    }

                    if (start.isBefore(firstChartDate)) {
                        start = firstChartDate.clone();
                    }

                    console.log("start=", start.format('YYYY-MM-DD'));
                    // console.log("end=", end.format('YYYY-MM-DD'));
                    //   console.log("Zooming....................");
                    //  console.log("this.chartData=", this.chartData);

                    this.$store.commit(this.namespace + "/chartParameters/setStart", start.clone().add(6, "months").format("YYYY-MM-DD"));
                    this.$store.commit(this.namespace + "/chartParameters/setEnd", end.format("YYYY-MM-DD"));

                    console.log("Zooming to 'startup' coordinates.");
                    console.log("start.toDate()=", start.toDate());
                    console.log("end.toDate()=", end.toDate());
                    //  dateAxis.zoomToDates(start.toDate(), end.clone().add(4, "days").toDate());
                    //  console.log("initialZoom() done.");
                }
                let that = this;
                if (!generalForm.chartParameters.hiddenFeatures.expiration) {
                    addExpirationGuide(this.$options.chart, generalForm);
                    setTimeout(function () {
                        that.verticalZoom();
                    }, 500);
                }
            }
        }
    };
</script>
