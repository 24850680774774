import moment from 'moment';
import {getSpreadExpiration} from '@/js/main';

function changePeriod(id, chart, generalForm) {
    console.log("changePeriod() starting. id=", id);
    let timePeriod = 365;
    let dateAxis = chart.map.getKey("date-axis");

    let  timeSpan = id.split("-");
    console.log("timeSpan=", timeSpan);

    if (timeSpan[1] == "YYYY") {
        timePeriod = 365*timeSpan[0];
    } else if (timeSpan[0] == 6) {
        timePeriod = 188;
    } else if (timeSpan[0] == 3) {
        timePeriod = 94;
    }
    // console.log("timePeriod=" + timePeriod);
    if (timeSpan[1] == "MAX") {
        // dateAxis.zoom(0, 1);
        dateAxis.start = 0.0;
        dateAxis.end = 1;
        dateAxis.keepSelection = false;
    } else {
        let lastChartDate = moment(generalForm.chartParameters.end, "YYYYMMDD");
        console.log("lastChartDate =", lastChartDate);

        let dateAxis = chart.map.getKey("date-axis");
        // console.log("dateAxis=", dateAxis);
        // console.log("dateAxis._maxZoomed=", dateAxis._maxZoomed, "   ", moment(dateAxis._maxZoomed));
        let minReal = moment(dateAxis._minReal).format("YYYY-MM-DD");
        let maxReal = moment(dateAxis._maxReal).format("YYYY-MM-DD");
        console.log("minReal=", minReal, " maxReal=", maxReal);

        let spread = generalForm.selected[0];
        let expiration = getSpreadExpiration(spread);
        console.log("expiration=", expiration);

        var end;
        if (generalForm.instrument === "future") {
            end = moment(maxReal, "YYYYMMDD").add(3, 'days');
        } else {
            end = moment();
        }

       /* if (lastChartDate.isBefore(end)) {
            end = lastChartDate.clone();
        }*/

        let start = end.clone().subtract(timePeriod, 'days');
        console.log("start=", start.toDate());
        console.log("end=", end.toDate());

        console.log("Zooming....................");
        dateAxis.zoomToDates(start.toDate(), end.toDate());
    }
}

export  {changePeriod};