<template>
    <div>

        <div v-bind:id="namespace+'-error'"></div> 
        <basic-chart v-bind:ref="namespace"
                     v-bind:namespace="namespace"  
                     v-bind:div-name="namespace+'-chartDiv'"
                     v-bind:parsedData="parsedData"></basic-chart>  
    </div>
</template>

<script>

    import basicChart from '@/components/am5charts-basic-chart';
    // import {addSingleSeasonal} from "@/js/am4charts-plugins/am4charts.single-seasonal";
    import $ from "jquery";
 
    export default {
        components: {
            basicChart
        },
        mounted() {
            console.log("basic-chart-output.vue mounted() starting.");
            this.$store.commit(this.namespace + '/setTabTitle', this.displayTickerWithUnitMoves);
            if (this.activeModuleName === this.namespace && !this.initialized) {
                this.getData();
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                parsedData: null,
                initialized: false
            }
        },
        computed: {
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            displayTicker() {
                return this.$store.getters[this.namespace + "/displayTicker"];
            },
            displayTickerWithUnitMoves() {
                return this.$store.getters[this.namespace + "/displayTickerWithUnitMoves"];
            },
            activeModuleName() {
                return  this.$store.getters['activeModuleName'];
            },
            showPlaybackControl() {
                return this.$store.state[this.namespace].showPlaybackControl;
            },
            symbols() {
                return this.$store.state.user.symbols;
            }
        },
        watch: {
            displayTickerWithUnitMoves: function (newTicker, oldTicker) {
                console.log("watch newTicker=", newTicker, " oldTicker=", oldTicker, " namespace=", this.namespace);

                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (newTicker !== oldTicker && newTicker !== null) {
                    this.getData();
                    if (generalForm.program === "BasicCharts") {
                        this.$store.commit(this.namespace + '/setTabTitle', this.displayTickerWithUnitMoves);
                    }
                } else {
                    this.parsedData = null;
                }
            },
            symbols(symbols) {
                console.log("watch: symbols=", symbols);
                console.log("this.displayTickerWithUnitMoves=", this.displayTickerWithUnitMoves);
                let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                if (generalForm.program === "BasicCharts") {
                    this.$store.commit(this.namespace + '/setTabTitle', this.displayTickerWithUnitMoves);
                }
            },
            activeModuleName: function (activeModuleName) {
                console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace,
                        " this.initialized=", this.initialized, " this.$store.state[this.namespace].selected[0]=", this.$store.state[this.namespace].selected[0],
                        " this.ticker=", this.$store.getters[this.namespace + "/ticker"]);

                if (activeModuleName === this.namespace && !this.initialized
                        && typeof this.$store.state[this.namespace].selected[0] !== 'undefined') {
                    // console.log("CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC");
                    this.getData();
                }
            }
        },
        methods: {
            normalizeForUnitMoveDifferencesBetweenBarchartAndEod(data, commodity) {
                console.log("normalizeForUnitMoveDifferencesBetweenBarchartAndEod() starting. commodity=", commodity);

                let normalizationRatios = {
                    HG: 100,
                    SI: 100
                };

                let normalizedData;
                if (Object.keys(normalizationRatios).includes(commodity)) {
                    //  console.log("Normalizating data. data=", data);
                    normalizedData = data.map(x => {
                        //  console.log("x=", x);
                        ["open", "high", "low", "price"].forEach(y => {
                            x[y] = normalizationRatios[commodity] * x[y];
                        });
                        return x;
                    });
                } else {
                    normalizedData = data;
                }
                return normalizedData;
            },
            addData(bar) {
                // console.log("addData() starting.");
                this.$refs[this.namespace].addData(bar);


            },
            getData: function () {
                console.log("getData() starting.");
                // console.trace();

                this.$store.commit('resetNumberOfAjaxCalls');
                this.initialized = true;
                // console.log("this.$refs=", this.$refs);
                this.$refs[this.namespace].removeChart();

                if (this.ticker !== null) {
                    this.$store.commit('incrementNumberOfAjaxCalls');
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    console.log("generalForm=", generalForm);
                    console.log("generalForm.selected[0]=", generalForm.selected[0]);

                    // let isOpen = isSpreadOpen(generalForm.selected[0]);
                    // console.log("isOpen=", isOpen);

                    /*  if (isOpen) {
                     this.$store.commit(this.namespace + '/setGeneralForm', generalFormToBarchartTicker(generalForm));
                     } else {
                     this.$store.commit(this.namespace + '/setBarchartTicker', "");
                     } */
                    generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    // console.log("barchartTicker=", generalForm.barchartTicker);

                    generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
                    delete generalForm.browserSideOnly;
                    delete generalForm.chartParameters;
                    delete generalForm.playback;
                    generalForm.generator = "BasicBarsGenerator";
                    generalForm.study = "basic";
                    //  console.log("generalForm=", generalForm);

                    let json = JSON.stringify(generalForm, null, 2);
                    if (generalForm.selected.length > 0) {
                        let that = this;
                        var currentRequest = $.ajax({
                            url: this.$store.state.siteData.baseUrl + this.$store.state.siteData.contextName + "/BasicEodBarsControllerServlet/",
                            type: "POST",
                            data: {"json": json},
                            beforeSend: function () {
                                //  console.log("beforeSend starting.");
                                if (currentRequest != null) {
                                    //  console.log("aborting previous request.");
                                    currentRequest.abort();
                                }
                            },
                            success: function (data) {
                                // console.log("data=", data);
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                let parsedData = JSON.parse(data)[0];
                                console.log("parsedData=", parsedData);
                                let dataSource = JSON.parse(data)[1].dataSource;
                               // console.log("dataSource=", dataSource, " parsedData=", JSON.parse(JSON.stringify(parsedData)));

                                that.$store.commit(that.namespace + '/setDataSource', dataSource);

                                if (parsedData.values === "none") {
                                    console.log('parsedData.values === "none"');
                                } else {
                                    //     $('#' + that.namespace + "-error").hide();

                                    console.log("data source: eod");

                                }

                              /*  that.getLastData(parsedData).then(newBar => {
                                    console.log("newBar=", newBar);
                                    if (!["not_defined", "no_data", "not_open", "no_current_data"].includes(newBar)) {
                                        console.log("newBar=", JSON.parse(JSON.stringify(newBar)));
                                        let values = Object.values(parsedData.values);
                                        let lastBar = values[values.length - 1];
                                        console.log("lastBar=", JSON.parse(JSON.stringify(lastBar)));

                                        console.log("newBar.date=", moment(newBar.date).format("YYYYMMDD"), " lastBar.date=", lastBar.date);

                                        console.log("Inserting new bar.");
                                        parsedData.values[moment(newBar.date).format("YYYYMMDD")] = newBar;
                                    }
                                    that.parsedData = parsedData;
                                });*/
                                that.parsedData = parsedData;
                            },
                            fail: function (data) {
                                that.$store.commit('decrementNumberOfAjaxCalls');
                                console.log("ajax call failed.");
                                $("#progress").hide();
                                $("#button").show();
                                console.log(data);
                            }
                        });
                    }
                }
            }
        }
    };

</script>
