<template>
    <div style="background-color: #c7d5e7; border: 0px solid black; width: auto">
        <!--  <div style="width: 100%;"> activeModuleIndex:
              <input style="width: 35px; border:1px solid gray; text-align: right" v-model.number="activeModuleIndex">
              </div>
             program:<span style=" margin-left: 3px; margin-right: 16px">{{program}}</span>
             activeModuleName:<span style=" margin-left: 3px; margin-right: 16px">{{activeModuleName}}</span> -->
        <table style="height: calc(100vh);    width: 100%;">
            <tr>
                <td class="sidebar">
                    <sidebar
                    v-bind:namespace="activeModuleName"
                    @newTabWithInitialState="newTabWithInitialState"
                    ref="savedCharts"
                    style="padding: 0px 0 0 0; border: 0px solid gray; display: none"
                    ></sidebar>
                </td>

            <td
                v-bind:style="{ width: width }"
                style="
                vertical-align: top;
                border: 0px solid gray;
                background-color: whitesmoke;
                "
                >

            <b-tabs
                tabs
                id="tabs"
                :vertical="isMobile() ? true : false"
                v-model="activeModuleIndex"
                style="
                margin-top: 10px;
                margin-top: -35px;
                border-bottom: 1px solid darkgray;
                font-size: 0.95rem;
                overflow-y: scroll;
                height: calc(95%);
                overflow-x: hidden;
                "
                >
                <template v-slot:tabs-start >
                    <b-nav-item class="close_all" v-if="moduleArray.length > 0" style="margin-left: 3px;padding-left: 0px;border-top-left-radius: 5px;border-top-right-radius: 5px;1px solid transparent;border-top-right-radius: 0.42rem;color: #5E6278;background-color: #ffffff;border-color: #E4E6EF;">
                        <b v-on:click="closeAllTabs" style="color: red;"> {{ $t('TABS.close_all') }}</b>
                    </b-nav-item>
                </template>

                <div id="v-step-0"></div>

                <component
                    v-if="!hideInput"
                    v-bind:is="inputComponent"
                    v-bind:namespace="activeModuleName"
                    v-bind:id="activeModuleName + 'InputDiv'"
                    style="
                    margin-top: 0px;
                    padding: 2px 15px 3px 15px;
                    border-bottom: 0px solid darkgray;
                    width: 100%;
                    border-radius: 10px;
                    "
                    ></component>

                <b-tab
                    v-for="module in moduleArray"
                    v-bind:key="module.moduleName"
                    v-bind:ref="module.moduleName + 'Output'"
                    active
                    style="border-top: 0px solid black;"
                    >
                    <template #title >
                        <b-container  fluid style="width: auto; margin-top: 0px; margin: -7px -28px -1px -9px; color: black">
                            <b-row align-h="center" style="margin: 0 0 0 0">

                                <b-col cols="16" style="margin: 8px 5px 0 -12px; padding: 0px;
                                       height: 20px; font-weight: 500; border: 0px solid gray; margin-right: 5px;">
                                     <!--  module.tabTitleMode:{{module.tabTitleMode}} -->
                                    <span v-if="module.tabTitleMode === 'auto'">{{ module.tabTitle}} </span>
                                    <span v-else>{{module.manualTabTitle}} </span>
                                </b-col>

                                <b-col cols="1" md="auto"
                                       v-if="typeof $store.state[module.moduleName] !== 'undefined' && activeModuleName === module.moduleName" 
                                       style="width: auto; margin: 0 0 0 -4px"
                                       v-b-modal.tab-title-modal>

                                    <tab-title-modal v-bind:namespace="module.moduleName"></tab-title-modal>

                                    <i class="far fa-edit" v-if="autoTabTitle" @click="toggleTabTitleInput" 
                                       v-b-tooltip.hover.topleft="'Edit tab title.'" style="color: black; margin: 7px -8px 1px -6px"></i>
                                       <!--  <span @click="toggleTabTitleInput">
                                          <Icon icon="mdi:automatic" v-if="!autoTabTitle" width="18" height="18" 
                                          v-b-tooltip.hover.topleft="'Currently in automatic tab title mode. Click for custom tab title mode to set your own title.'" style="color: black; margin: 6px -8px 1px -7px"/>
                                      </span> -->
                                </b-col>
                                <b-col cols="1" md="auto"
                                       v-if="typeof $store.state[module.moduleName] !== 'undefined' && activeModuleName === module.moduleName" style="width: auto">
                                    <b-button id="popover-3" variant="light" @click="toggleHideInput" style="background-color: white;
                                              height: auto; margin: 1px 3px 2px 1px; border: 0px solid darkgray; padding: 0 4px 0px 4px;">
                                        <i v-if="!hideInput" class="far fa-eye-slash" style="margin: 8px 0px 0 -18px; color: black"></i>
                                        <i v-if="hideInput"  class="far fa-thin fa-eye" style="margin: 8px 3px 0 -18px; color: black"></i>
                                    </b-button>

                                    <b-popover target="popover-3" triggers="hover" placement="top">
                                        <span style="font-size: 12px">{{
                        (hideInput ? "show" : "hide") + " input"
                                            }}</span>
                                    </b-popover>
                                </b-col>

                                <b-col cols="1" md="auto"
                                       v-if="typeof $store.state[module.moduleName] !== 'undefined' && activeModuleName === module.moduleName" 
                                       style="width: auto; margin-right: -25px">
                                    <b-button
                                        size="sm"
                                        @click="closeTab(module.moduleName)"
                                        variant="dark"
                                        style="
                                        height: 15px;
                                        width: 10px;
                                        margin: 0 -18px -11px -30px;
                                        padding-right: 2px;">
                                        <span class="h5 mb-2" style="margin: 0;" id="v-step-3">
                                            <b-icon
                                                icon="x-square-fill"
                                                style="margin: -7px 0px 10px -12px;border-radius: 4px;border: solid 2px black;"
                                              ></b-icon>
                                        </span>
                                    </b-button>

                                </b-col>
                            </b-row>
                        </b-container>
                    </template>

                    <div style="background-color: white">
                             <basic-chart-output 
                            v-if="module.program === 'BasicCharts'"
                            v-bind:windowHeight="windowHeight"
                            v-bind:namespace="module.moduleName"
                            v-bind:ref="module.moduleName + '-basic-chart-output'"
                            ></basic-chart-output>
                        <basic-chart-output-new 
                            v-if="module.program === 'BasicChartsNew'"
                            v-bind:windowHeight="windowHeight"
                            v-bind:namespace="module.moduleName"
                            v-bind:ref="module.moduleName + '-basic-chart-output'"
                            ></basic-chart-output-new>
                        <calculator-output 
                            v-if="module.program === 'Calculator'" 
                            v-bind:namespace="module.moduleName"
                            ></calculator-output>
                        <stock-chart-output
                            v-if="module.program === 'StockCharts'"
                            v-bind:namespace="module.moduleName"
                            ></stock-chart-output>
                        <test-output
                            v-if="module.program === 'TestCharts'"
                            v-bind:namespace="module.moduleName"
                            ></test-output>
                        <forward-curves-output
                            v-if="module.program === 'ForwardCurves'"
                            v-bind:namespace="module.moduleName"
                            ></forward-curves-output>
                        <saved-charts-admin-output
                            v-if="module.program === 'SavedChartsAdmin'"
                            v-bind:namespace="module.moduleName"
                            ></saved-charts-admin-output>
                        <search-output
                            v-if="module.program === 'Search'"
                            v-bind:namespace="module.moduleName"
                            @newTabWithInitialState="newTabWithInitialState"
                            ></search-output>
                        <trade-maps-output
                            v-if="module.program === 'TradeMaps'"
                            v-bind:namespace="module.moduleName"
                            ></trade-maps-output>
                        <spread-coverage-output
                            v-if="module.program === 'SpreadCoverage'"
                            v-bind:namespace="module.moduleName"
                            ></spread-coverage-output>
                        <history-output
                            v-if="module.program === 'History'"
                            v-bind:namespace="module.moduleName"
                            ></history-output>
                        <multiple-series-charts-output
                            v-if="
                            ['LongTermCharts', 'OpenSpreads'].includes(module.program)
                            "
                            v-bind:namespace="module.moduleName"
                            ></multiple-series-charts-output>
                        <watch-list-output
                            v-if="module.program === 'WatchList'"
                            v-bind:namespace="module.moduleName"
                            ></watch-list-output>
                        <trade-explorer-output
                            v-if="module.program === 'TradeExplorer'"
                            v-bind:namespace="module.moduleName"
                            ></trade-explorer-output>
                        <firestore-search-output
                            v-if="module.program === 'FirestoreSearch'"
                            v-bind:namespace="module.moduleName"
                            @newTabWithInitialState="newTabWithInitialState"
                            ></firestore-search-output>
                        <portfolio-output
                            v-if="module.program === 'Portfolio'"
                            v-bind:namespace="module.moduleName"
                            ></portfolio-output>
                        <quotes-output
                            v-if="module.program === 'Quotes'"
                            v-bind:namespace="module.moduleName"
                            ></quotes-output>

                    </div>
                </b-tab>

                <template v-slot:tabs-end>
                    <b-nav-item v-b-modal.add-tab-modal class="new_tab">
                        <b style='border: 0px solid darkgray; padding: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    line-height: 1.5em !important;color: blue;' id="v-step-2">{{ $t('TABS.new_tab') }}</b>
                    </b-nav-item>

                    <b-modal
                        size="lg"
                        id="add-tab-modal"
                        ref="add-tab-modal"
                        :title="$t('TABS.chart_selection')"
                        >
                        <table border="0">
                            <tr>
                                <td>
                                    <div>
                                        <b-container class="bv-example-row" >
                                            <b-row> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(0,3)" v-bind:key="i">
                                                    <b-card v-on:click="newTab(programOptions[i].value)" v-bind:title="programOptions[i].text" v-bind:img-src="'media/img/'+programOptions[i].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small >{{$t('last_updated') }}: 13 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                            <b-row> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(3,6)" v-bind:key="i">
                                                    <b-card v-on:click="newTab(programOptions[i+3].value)" v-bind:title="programOptions[i+3].text" v-bind:img-src="'media/img/'+programOptions[i+3].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small>{{$t('last_updated') }}: 13 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                            <b-row> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(6,9)" v-bind:key="i">
                                                    <b-card v-on:click="newTab(programOptions[i+6].value)" v-bind:title="programOptions[i+6].text" v-bind:img-src="'media/img/'+programOptions[i+6].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small>{{$t('last_updated') }}: 15 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="programOptions.length"> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(9,12)" v-bind:key="i" class="col-4">
                                                    <b-card v-on:click="newTab(programOptions[i+9].value)" v-bind:title="programOptions[i+9].text" v-bind:img-src="'media/img/'+programOptions[i+9].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small>{{$t('last_updated') }}: 16 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                            <b-row v-if="programOptions.length"> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(12,15)" v-bind:key="i" class="col-4">
                                                    <b-card v-on:click="newTab(programOptions[i+12].value)" v-bind:title="programOptions[i+12].text" v-bind:img-src="'media/img/'+programOptions[i+12].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small>{{$t('last_updated') }}: 16 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                              <b-row v-if="programOptions.length"> 
                                                <b-col v-model="newProgram" v-for="(value, i) in programOptions.slice(15,18)" v-bind:key="i" class="col-4">
                                                    <b-card v-on:click="newTab(programOptions[i+15].value)" v-bind:title="programOptions[i+15].text" v-bind:img-src="'media/img/'+programOptions[i+15].text+'.png'" img-alt="Image" img-top style="cursor: pointer">
                                                    <template #footer>
                                                        <small>{{$t('last_updated') }}: 16 {{$t('MONTHS.april') }} 2023</small>
                                                    </template>
                                                    </b-card>
                                                </b-col>
                                            </b-row>
                                        </b-container>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </b-modal>
                </template>
            </b-tabs>
            </td>
            </tr>
        </table>
    </div>
</template>

<style >
.card-img-top {
    height: 108.8px;
}
</style>

<script>
    import generalForm from "@/store/generalFormModule";

    import basicChartOutput from "@/components/programs/BasicChart/basic-chart-output";
    import basicChartOutputNew from "@/components/programs/BasicChartNew/basic-chart-output-new";
    import stockChartOutput from "@/components/programs/StockChart/stock-chart-output";
    import calculatorOutput from "@/components/programs/Calculator/calculator-output";
    import forwardCurvesOutput from "@/components/programs/ForwardCurves/forward-curves-output";
    import searchOutput from "@/components/programs/Search/search-output";
    import startPageOutput from "@/components/programs/StartPage/start-page-output";
    import tradeMapsOutput from "@/components/programs/TradeMaps/trade-maps-output";
    import historyOutput from "@/components/programs/History/history-output";
    import spreadCoverageOutput from "@/components/programs/SpreadCoverage/spread-coverage-output";
    import multipleSeriesChartsOutput from "@/components/programs/multiple-series-charts-output";
    import watchListOutput from "@/components/programs/WatchList/watch-list-output";
    import tradeExplorerOutput from "@/components/programs/TradeExplorer/trade-explorer-output";
    import firestoreSearchOutput from "@/components/programs/FirestoreSearch/firestore-search-output";
    import portfolioOutput from "@/components/programs/Portfolio/portfolio-output";
    import SavedChartsAdminOutput from "@/components/programs/SavedChartsAdmin/saved-charts-admin-output";
    import KTUtil from "@/assets/js/components/util";
    import tabTitleModal from "@/components/tab-title-modal";
    import quotesOutput from "@/components/programs/Quotes/quotes-output";

    import { baseInitialState } from "@/components/programs/base-initial-state";
    const sidebar = () => import("@/components/sidebar");
    import {storeTab, programCompositionArray, setCommoditiesArray, changeCommodityLeg, contractNameDecomposer, 
                getProgramComposition, nearestOpenSpread} from "@/js/main";
    import * as fb from "@/firebase";
    import { bus } from "@/main";
    import _ from "lodash";
    import VueInputAutowidth from 'vue-input-autowidth'
    import Vue from 'vue';
    import { Icon } from '@iconify/vue2';

    Vue.use(VueInputAutowidth);

    export default {
        components: {
            sidebar,
            basicChartOutput,
            basicChartOutputNew,
            quotesOutput,
            stockChartOutput,
            calculatorOutput,
            forwardCurvesOutput,
            searchOutput,
            startPageOutput,
            tradeMapsOutput,
            spreadCoverageOutput,
            historyOutput,
            multipleSeriesChartsOutput,
            watchListOutput,
            tradeExplorerOutput,
            firestoreSearchOutput,
            tabTitleModal,
            portfolioOutput,
            Icon,
            SavedChartsAdminOutput //, usersOutput, playbackOutput
        },
        created() {
            /* this.$nextTick(() => {
             fb.db.collection("tabs").doc(this.$store.state.user.email).collection("activeModuleName").doc("activeModuleName-document")
             .get().then((doc) => {
             if (doc.exists) {
             let activeModuleName = doc.data().activeModuleName;
             console.log("activeModuleName=", activeModuleName);
             let moduleArray = this.$store.getters['moduleArray'];
             console.log("moduleArray=", JSON.parse(JSON.stringify(moduleArray)));
             let index = moduleArray.map(x => x.moduleName).indexOf(activeModuleName);
             console.log("index=", index);
             this.$store.commit("setActiveModuleIndex", index);
             //  this.activeModuleIndex = 2;
             
             } else {
             // doc.data() will be undefined in this case
             console.log("No such document!");
             }
             }).catch((error) => {
             console.log("Error getting document:", error);
             });
             
             }); */
        },
        mounted() {
            
            // console.log("mounted() started.");
            window.addEventListener("resize", this.windowResizeEventHandler);

            /*document.getElementsByClassName('nav-tabs').$on("mouseover", () => {
                if(document.getElementById('kt_header').style.display=='none') {
                    document.getElementById('kt_header').style.display = 'block';
                }
            });

            document.getElementsByClassName('tab-content').$on("mouseout", () => {
                if(document.getElementById('kt_header').style.display=='block') {
                    document.getElementById('kt_header').style.display = 'none';
                }
            });*/

            bus.$on("changeTab", (program) => {
                console.log("from bus, changeTab: program=", program);
                // console.trace();
                this.changeTab(program);
            });

            bus.$on("newTab", (program) => {
                console.log("from bus, newTab: program=", program);
                // console.trace();
                this.newTab(program);
            });

             bus.$on("newTabWithInitialState", (form) => {
                console.log("from bus, newTabWithInitialState: form=", form);
                this.newTabWithInitialState(form);
            });


        },
        destroyed() {
            console.log("tabs-box.vue destroyed() starting.");
            this.$store.commit("setActiveModuleIndex", null);
            window.removeEventListener("resize", this.windowResizeEventHandler);

            bus.$off();
        },
        data: function () {
            return {
                windowHeight: window.innerHeight,
                lastCheckTime: 0,
                newProgram: null,
                playbackControlKey: 0,
                autoTabTitle: true
            };
        },
        computed: {
            minAutoWidth() {
                console.log("minAutoWidth() starting. this.autoTabTitle=", this.autoTabTitle);
                let width;
                if (!this.autoTabTitle) {
                    width = 1.05 * (this.displayTickerWithUnitMoves.length) + 'ch';
                } else {
                    width = 0;
                }
                console.log("minAutoWidth=", width);
                return width;
            },
            width() {
                return "100%";
                /*["TradeMaps", "Calculator"].includes(this.program)  
                 ? "fit-content" 
                 :  "100%";*/
            },
            programOptions() {
                return programCompositionArray(this.role);
            },
            role() {
                return this.$store.state.user.role;
            },
            email() {
                return this.$store.state.user.email;
            },
            showStateEditor() {
                return this.$store.state.showStateEditor;
            },
            hideInput: {
                get() {
                    let hideInput;
                    if (typeof this.activeModule !== "undefined" && this.activeModule !== null) {
                        hideInput = this.activeModule.hideInput;
                    } else {
                        hideInput = false;
                    }
                    //  console.log("hideInput=", hideInput);
                    return hideInput;
                },
                set(hideInput) {
                    this.$store.commit(this.activeModuleName + "/setHideInput", hideInput);
                },
            },
            showPlaybackControl() {
                if (
                        typeof this.activeModule !== "undefined" &&
                        this.activeModule !== null
                        ) {
                    return this.activeModule.showPlaybackControl;
                } else {
                    return false;
                }
            },
            activeModuleIndex: {
                get() {
                    // console.log("activeModuleIndex() get starting. this.$store.state.activeModuleIndex=", this.$store.state.activeModuleIndex);
                    return this.$store.state.activeModuleIndex;
                },
                set(activeModuleIndex) {
                    console.log("set activeModuleIndex()  starting. activeModuleIndex=", activeModuleIndex);
                    // console.trace();
                    //  console.log("arguments.callee.caller.name=", arguments.callee.caller.name); Can't use in srict mode.
                    this.$store.commit("setActiveModuleIndex", activeModuleIndex);
                    /*  let currentTime = (new Date).getTime();   This block causes ugly problems!!!!!
                     // console.log("currentTime=", currentTime, " this.lastCheckTime=", this.lastCheckTime);
                     let timeIncrement = currentTime - this.lastCheckTime;
                     // console.log("timeIncrement=", timeIncrement);
                     
                     if (timeIncrement > 200) {
                     this.$store.commit('setActiveModuleIndex', activeModuleIndex);
                     }
                     this.lastCheckTime = (new Date).getTime();*/
                },
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters["activeModuleName"];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            activeModule: {
                get() {
                    let activeModule = this.$store.state[this.activeModuleName];
                    // console.log("activeModule=", activeModule);
                    return typeof activeModule !== "undefined" ? activeModule : null;
                },
                set() {},
            },
            activeModuleJson() {
                console.log("activeModuleJson() starting.");
                let generalForm = JSON.parse(JSON.stringify(this.activeModule));
                console.log("generalForm=", generalForm);
                if(typeof generalForm !== 'undefined' && generalForm !== null){
                    delete generalForm.playback;
                    delete generalForm.browserSideOnly;
                    return JSON.stringify(generalForm);
                }else{
                    return null;
                }
            },
            moduleArray: {
                get() {
                    let moduleArray = this.$store.getters["moduleArray"];
                    // console.log("moduleArray=", moduleArray);
                    return moduleArray;
                },
                set() {
                    // console.log("program set() starting.");
                    // console.log("moduleArray=", moduleArray);
                },
            },
            inputComponent: {
                get() {
                    // console.log("inputComponent get() starting. this.program=", this.program);
                    if (typeof this.moduleArray[this.activeModuleIndex] !== "undefined") {
                        let role = this.$store.state.user.role;
                        // console.log("getProgramComposition(this.program, role)=", getProgramComposition(this.program, role));
                        let returnComponent = getProgramComposition(this.program, role).input;
                        // console.log("returnComponent=", returnComponent);
                        return returnComponent;
                    } else {
                        return null;
                    }
                },
            },
            program: {
                get() {
                    // console.log("program get() starting. this.moduleArray[this.activeModuleIndex]=", this.moduleArray[this.activeModuleIndex]);
                    if (typeof this.moduleArray[this.activeModuleIndex] !== "undefined") {
                        return this.moduleArray[this.activeModuleIndex].program;
                    } else {
                        return null;
                    }
                },
                set() {
                    // console.log("program set() starting.");
                    // console.log("program=", program);
                },
            },
            inputDivHeight() {
                // console.log("inputDivHeight() starting. this.activeModule=", this.activeModule);
                if (typeof this.activeModule !== "undefined" && this.activeModule !== null) {
                    let inputDivHeight = this.activeModule.browserSideOnly.inputDivHeight;
                    //  console.log("inputDivHeight=", inputDivHeight);
                    return inputDivHeight;
                } else {
                    return null;
                }
            },
            displayTickerWithUnitMoves() {
                return this.$store.getters[this.activeModuleName + "/displayTickerWithUnitMoves"];
            }
        },
        watch: {
            /* activeModuleIndex: function () {
             // console.log("watch activeModuleIndex=", activeModuleIndex);
             let refName = this.$store.getters["activeModuleName"] + "Output";
             // console.log("activeModuleName=", activeModuleName); 
             console.log("refName=", refName);
             console.log("this.$refs=", this.$refs);
             console.log("this.$refs[refName]=", this.$refs[refName]);
             console.log("this.$refs[refName].length=", this.$refs[refName].length);
             console.log("this.$refs[refName][0]=", this.$refs[refName][0]);
             
             this.$refs[refName][0].activate();
             },*/
            "$store.state.siteData.commoditiesArray"(newCommoditiesArray/*, oldCommoditiesArray*/) {
                // console.log("watch newCommoditiesArray=", newCommoditiesArray, " oldCommoditiesArray=", oldCommoditiesArray);
                // console.log("this.activeModuleName=", this.activeModuleName);
                setCommoditiesArray(newCommoditiesArray);
                this.$store.dispatch(this.activeModuleName + "/getContractListsFromServer");
            },
            windowHeight: function (newWindowHeight, oldWindowHeight) {
                // console.log("watch newWindowHeight=", newWindowHeight, " oldWindowHeight=", oldWindowHeight);
                if (newWindowHeight !== oldWindowHeight) {
                    // console.log("watch windowHeight=", newWindowHeight);
                    this.setChartHeight();
                }
            },
            inputDivHeight: function (/*inputDivHeight*/) {
                // console.log("watch inputDivHeight=", inputDivHeight + " this.windowHeight=", this.windowHeight);
                this.setChartHeight();
            },
            showPlaybackControl: function (/*showPlaybackControl*/) {
                // console.log("watch showPlaybackControl=", showPlaybackControl);
                this.setChartHeight();
            },
            /* activeModule: function (activeModule) {
             console.log("watch activeModule = ", activeModule);
             // this.setChartHeight();
             },*/
            activeModuleJson: function (newActiveModuleJson, oldActiveModuleJson) {
                console.log("watch activeModuleJson");
                if (newActiveModuleJson !== oldActiveModuleJson) {
                    // const jsondiffpatch = require('jsondiffpatch').create();
                    // const diff = jsondiffpatch.diff(newActiveModuleJson, oldActiveModuleJson);
                    // console.log("diff=", diff);
                    // console.log("diff=", JSON.parse(diff));

                    //  console.log("watching activeModuleJson");
                    storeTab(JSON.parse(newActiveModuleJson), this.email);
                    this.updateInitializingState();
                }
            },
        },
        methods: {
            //  onInput(/*value*/) {
            /*  console.log("onInput() starting.");
             if(!this.visitedTabs.includes(this.activeModuleName)){
             let arr = this.visitedTabs.slice();
             arr.push(this.activeModuleName);
             this.visitedTabs = arr.sort();
             }
             }, */
            toggleTabTitleInput() {
                console.log("toggleTabTitleInput() starting. this.autoTabTitle=", this.autoTabTitle);
                /*  if(this.autoTabTitle){
                 console.log("this.displayTickerWithUnitMoves=", this.displayTickerWithUnitMoves);
                 this.$store.commit(this.activeModuleName + '/setTabTitle', this.displayTickerWithUnitMoves);
                 }
                 this.autoTabTitle = !this.autoTabTitle;*/
            },
            updateInitializingState() {
                console.log("updateInitializingState() starting. this.program=", this.program);
               // console.trace();
                if (!["Search", "StockCharts"].includes(this.program) && typeof this.$store.state[this.activeModuleName] !== 'undefined') {
                    let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.activeModuleName]));
                    // console.log("generalForm=", JSON.parse(JSON.stringify(generalForm)));

                    if (["OpenSpreads"].includes(this.program)) {
                        // generalForm.selected = [generalForm.sampleContract[0]];
                        console.log("generalForm.selected[0]=", generalForm.selected[0]);
                        generalForm.selected = [generalForm.selected[0]];
                        let contract = generalForm.selected[0].split('/');
                        generalForm.legs = contract.length;
                        contract.forEach((x, i) => {
                            generalForm.sampleContract[i] = x;
                        })
                    } else if(["Calculator"].includes(this.program)) {
                        let temp = nearestOpenSpread(generalForm);
                       // console.log("temp=", temp);
                        generalForm.selected = [temp.value];
                    }
                    console.log("generalForm=", generalForm);
                    this.$store.commit("user/setInitializingState", generalForm);
                }
            },
            windowResizeEventHandler(e) {
                //  console.log("window height=", e.target.innerHeight);
                this.windowHeight = e.target.innerHeight;
            },
            setChartHeight: _.debounce(function () {
                // console.log("this.activeModule.showPlaybackControl");
                /*  console.log("setChartHeight() starting. this.inputDivHeight=", this.inputDivHeight,
                 " this.windowHeight=", this.windowHeight + " this.activeModuleName=", this.activeModuleName); */
                //console.trace();
                if (
                        this.program !== "TradeMaps" &&
                        this.activeModuleName !== null &&
                        this.inputDivHeight !== null
                        ) {
                    let elem;
                    if (this.program === "History") {
                        elem = document.getElementById(this.activeModuleName + "-chartTable");
                    } else {
                        elem = document.getElementById(this.activeModuleName + "-chartDiv");
                    }

                    const elem2 = document.getElementById(
                            this.activeModuleName + "-plotlyRibbonChartDiv"
                            );
                    const elem3 = document.getElementById(
                            this.activeModuleName + "-plotlyRibbonChartInstructions"
                            );
                    let playbackControlHeight = this.showPlaybackControl ? 33 : 0;
                    let pagePadding = 2 * 10; // See $page-padding in _variables.scss
                    let newHeight =
                            this.windowHeight -
                            this.inputDivHeight -
                            playbackControlHeight -
                            pagePadding -
                            95;
                    console.log('newHeight:', newHeight);
                    // console.log("elem=", elem);

                    if (elem !== null) {
                        elem.style.height = newHeight + "px";
                        //  console.log("elem=", elem);
                    }
                    if (elem2 !== null) {
                        let instructionsHeight = elem2 !== null ? elem3.offsetHeight : 0;
                        elem2.style.height = newHeight - instructionsHeight + "px";
                    }
                }
            }, 200),
            toggleHideInput() {
                console.log("toggleHideInput() starting.");
                console.log("this.hideInput=", this.hideInput);
                this.$store.commit(
                        this.activeModuleName + "/setHideInput",
                        !this.hideInput
                        );
            },
            transformLegacyState(state) {
                console.log("transformLegacyState() starting.");
                if (state.program === "StackedSpreads") {
                    state.program = "LongTermCharts";
                    state.aligned = true;
                }
                console.log("state=", state);
                return state;
            },
            newTabWithInitialState(state) {
                console.log("newTabWithInitialState() starting. state=", JSON.parse(JSON.stringify(state)));

                if (state === "No such document!") {
                    alert("Error! No such document!");
                } else if (this.checkForHistoryTab(state.program)) {
                    alert("Sorry. Only one History tab is allowed.");
                } else {
                    let initialState = this.transformLegacyState(state); //_.merge(baseInitialState, state);

                    let nextModuleName = this.$store.getters["nextModuleName"];
                    console.log("nextModuleName=", nextModuleName);
                    if (!this.$store.hasModule(nextModuleName)) {
                        this.$store.registerModule(nextModuleName, generalForm);
                        this.$store.commit(nextModuleName + "/setGeneralForm", initialState);
                        this.$store.commit(nextModuleName + "/setModuleName", nextModuleName);
                        this.$store.commit(
                                nextModuleName + "/setConstrainSampleContract",
                                false
                                );
                        this.$store.commit("resetNumberOfAjaxCalls");
                        this.$store.dispatch(nextModuleName + "/getContractListsFromServer");
                        setTimeout(() => {
                            // console.log("this.$refs=", this.$refs);
                            let refName = nextModuleName + "Output";
                            //  console.log("refName=", refName);
                            // console.log("this.$refs[refName]=", this.$refs[refName]);

                            this.$refs[refName][this.$refs[refName].length - 1].activate();
                        }, 100);
                    }
                }
            },
            checkForHistoryTab(program) {
                let programs = this.moduleArray.map((x) => x.program);
                // console.log("programs=", programs);
                return program === "History" && programs.includes(program);
            },
            newTab(program) {
                //console.log("newTab() starting.");

                if (this.checkForHistoryTab(program)) {
                    alert("Sorry. Only one History tab is allowed.");
                } else {
                    let initialState = this.createInitialState(program);
                    console.log("initialState=", JSON.parse(JSON.stringify(initialState)));
                    this.$refs["add-tab-modal"].hide();
                    this.newProgram = null;
                    let nextModuleName = this.$store.getters["nextModuleName"];
                    console.log("nextModuleName=", nextModuleName);
                    if (!this.$store.hasModule(nextModuleName)) {
                        // console.log("generalForm=", generalForm);
                        this.$store.registerModule(nextModuleName, generalForm);
                        this.$store.commit(nextModuleName + "/setGeneralForm", initialState);
                        this.$store.commit(nextModuleName + "/setModuleName", nextModuleName);
                        this.$store.commit(nextModuleName + "/setConstrainSampleContract", false);
                        this.$store.commit("resetNumberOfAjaxCalls");
                        // console.log("JSON.parse(JSON.stringify(this.$store.state[nextModuleName]))=", this.$store.state[nextModuleName]);
                        this.$store.dispatch(nextModuleName + "/getContractListsFromServer");
                        setTimeout(() => {
                            // console.log("this.$refs=", this.$refs);
                            let refName = nextModuleName + "Output";
                            console.log("refName=", refName);
                            console.log("this.$refs[refName]=", this.$refs[refName]);
                            console.log("this.$refs[refName].length=", this.$refs[refName].length);
                            console.log("this.$refs[refName][this.$refs[refName].length - 1]=", this.$refs[refName][this.$refs[refName].length - 1]);

                            this.$refs[refName][this.$refs[refName].length - 1].activate();
                        }, 100);
                    }
                }
            },
            createInitialState(program) {
                console.log("createInitialState() starting. program=", program);
                let programInitialstate = getProgramComposition(program, this.role).initialState;
                console.log("programInitialstate=", programInitialstate);
                let initialState = _.merge(JSON.parse(JSON.stringify(baseInitialState)), programInitialstate);

                // console.log("this.$store.state.user=", JSON.parse(JSON.stringify(this.$store.state.user)));
                // console.log("this.activeModuleName=", this.activeModuleName);
                let initializingState = JSON.parse(JSON.stringify(this.$store.state.user.initializingState));
                console.log("initializingState=", initializingState);
                if (initializingState !== null && this.activeModuleName !== null && Object.keys(initializingState).length > 0) {
                    if (["TradeMaps", "OpenSpreads"].includes(program)) {
                        initialState.intracommodity = true;
                        let commodityFirstLeg = contractNameDecomposer(initializingState.sampleContract[0]).commoditySymbol;
                        let commoditySecondLeg = contractNameDecomposer(initializingState.sampleContract[1]).commoditySymbol;
                        console.log("commodityFirstLeg=", commodityFirstLeg + " commoditySecondLeg=", commoditySecondLeg);
                        if (commodityFirstLeg !== commoditySecondLeg) {
                            let index = 1;
                            initializingState = changeCommodityLeg(commodityFirstLeg, index, JSON.parse(JSON.stringify(initialState)));
                        }
                        /*  if (program === "OpenSpreads") { // Don't remove this block!
                         initialState.selected = initializingState.selected.map(spread => {
                         let contracts = spread.replace(/\s/g, '').split('/');
                         // console.log("contracts=", contracts);
                         return contracts[0];
                         })
                         initialState.legs = 1;
                         
                         console.log("initialState.selected=", initialState.selected);
                         }*/
                    }

                    initialState.unitMove = initializingState.unitMove;
                    initialState.sampleContract = initializingState.sampleContract;
                    initialState.selected = initializingState.selected;

                    console.log("initialState.unitMove=", initialState.unitMove);

                    initialState.mult = ["TradeMaps", "StockCharts", "OpenSpreads"].includes(program) ? [1, 1, 1, 1] : initializingState.mult;
                    initialState.legs = ["TradeMaps", "StockCharts"].includes(program) && initializingState.legs > 2 ? 2 : initializingState.legs;
                    initialState.p = ["StockCharts", "OpenSpreads"].includes(program) ? [1, -1, 1, 1] : initializingState.p;
                    if (program === "TradeMaps") {
                        initialState.p = initializingState.p[0] > 0 ? [1, -1, 1, 1] : [-1, 1, 1, 1];
                    }

                    initialState.open = initializingState.open;
                    initialState.close = initializingState.close;
                    initialState.buySell = initializingState.buySell;
                    if (program != "TradeMaps") { //This block handles Search actions.
                        initialState.addRSIPanel = initializingState.addRSIPanel;
                        initialState.rsiPeriod = initializingState.rsiPeriod;
                    }

                    if (["TradeMaps", "Calculator"].includes(program)) {
                        initialState.showTradingPeriod = true;
                    } else {
                        initialState.showTradingPeriod = initializingState.showTradingPeriod;
                    }
                }

                initialState.hideInput = false;
                console.log("initialState=", initialState);
                return initialState;
            },
            changeTab(program) {
                console.log("changeTab() starting. program=", program, " this.program=", this.program);

                if (this.checkForHistoryTab(program)) {
                    alert("Sorry. Only one History tab is allowed.");
                } else {
                    if (this.activeModuleName === null) {
                        this.newTab(program);
                    } else {
                        let initialState = this.createInitialState(program);
                        this.$store.commit(this.activeModuleName + "/setGeneralForm", initialState);
                        this.$store.commit(this.activeModuleName + "/setConstrainSampleContract", false);
                        this.$store.commit("resetNumberOfAjaxCalls");
                        console.log("this.$store.state[this.activeModuleName]=", JSON.parse(JSON.stringify(this.$store.state[this.activeModuleName])));
                        this.$store.dispatch(this.activeModuleName + "/getContractListsFromServer");
                    }
                }
            },
            closeTab(moduleName) {
                console.log("closeTab() starting. moduleName=", moduleName);
                //   this.activeModuleIndex--;
                //  console.log("this.activeModuleIndex=", this.activeModuleIndex);

                this.$store.commit("resetNumberOfAjaxCalls");
                this.$store.unregisterModule(moduleName);
                /*   if (this.moduleArray.length === 0) {
                 this.$refs['add-tab-modal'].show();
                 }*/

                let numberOfTabs = this.$store.getters["moduleArray"].length;
                console.log("numberOfTabs=", numberOfTabs);
                if (numberOfTabs === 0) {
                    this.$store.commit("user/setInitializingState", {});
                }

                fb.db
                        .collection("tabs")
                        .doc(this.$store.state.user.email)
                        .collection("tabs")
                        .doc(moduleName)
                        .delete()
                        .then(() => {
                            console.log("tab deleted.");
                        })
                        .catch((error) => {
                            console.error("Error removing document: ", error);
                        });
            },
            closeAllTabs() {
                console.log("closeAllTabs() starting.");
                let arr = this.$store.getters["moduleArray"];
                console.log("arr=", arr);
                arr.forEach((x) => this.closeTab(x.moduleName));
                this.$store.commit("user/setInitializingState", {});
            },
            isMobile() {
                let isMobileDevice = KTUtil.isMobileDevice();
                console.log("isMobileDevice? " + isMobileDevice);
                return isMobileDevice;
            }
        },
    };
</script>