import Vue from "vue";
import Vuex from "vuex";
import store from "@/store";
import * as fb from "@/firebase.js";
import router from "@/router.js";
import { firestoreAction } from 'vuexfire';

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {
        uid: "",
        name: "",
        username: "",
        email: "",
        role: "",
        subscriptions: [],
        authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
        // loading: false,

        selectedSidebarComponent: null,
        showTour: true,
        symbols: "new",
        tickerInputType: "text",
        mapEndpoint: "firebaseStorage",
        initializingState: null,

        strategies: [],
        portfolioDirectory: "",
        showAllPortfolioFolders: false
    },
    getters: {
      user: (state) => state,
      portfolioFolders(state){
        let arr;
        if(state.strategies.length>0){
            let folders = state.strategies.map(x => x.portfolio!=undefined && x.portfolio.folder).filter(y => y !== undefined && y);
            let set = new Set(folders);
            arr = Array.from(set);
            if(arr.length === 0){
                arr = ['main'];
                state.portfolioDirectory = 'main'
            }
            console.log("arr=", arr);
        } else {
            arr = ['main'];
            state.portfolioDirectory = 'main'   
        }
        return arr
      }
    },
    mutations: {
        setUser: function (state, newState) {
            // console.log("setUser() starting.");
            Object.assign(state, newState);
        },
        setUid: function (state, uid) {
            state.uid = uid;
        },
        setName: function (state, name) {
            state.name = name;
        },
        setEmail: function (state, email) {
            // console.log("setEmail() starting.");
            state.email = email;
        },
        setRole: function (state, role) {
            state.role = role;
        },
        setSubscriptions: function (state, subscriptions) {
            state.subscriptions = subscriptions;
        },
        setShowTour: function (state, payload) {
            state.showTour = payload;
        },
        setInitializingState(state, payload) {
            // console.log("setInitializingState() starting. payload.selected=", payload.selected);
            // console.trace();
            state.initializingState = payload;
        },
        setSymbols(state, payload) {
            state.symbols = payload;
        },
        setTickerInputType(state, payload) {
            state.tickerInputType = payload;
        },
        setMapEndpoint: function (state, payload) {
            state.mapEndpoint = payload;
        },
        setPortfolioDirectory: function (state, payload) {
            console.log("setPortfolioDirectory() starting. payload=", payload);
            state.portfolioDirectory = payload;
        },
        setPortfolioFolders: function (state, payload) {
            state.portfolioFolders = payload;
        },
        setShowAllPortfolioFolders: function (state, payload) {
            state.showAllPortfolioFolders = payload;
        }
    },
    actions: {
        bindStrategies: firestoreAction(({ bindFirestoreRef, state }) => {
            console.log("bindStrategies() starting. state=", state);
            // return the promise returned by `bindFirestoreRef`
            return bindFirestoreRef('strategies', fb.db.collection('portfolio-alpha').doc(state.email).collection('strategies'));
        }),
        async logout( { commit }) {
            console.log("logout() starting.");
            commit("setTabsInitialized", false, {root: true});
            commit("setRole", "");

            await fb.auth.signOut();
            router.currentRoute.path !== "/auth" && router.push("/auth");
        },
        async fetchUserProfile( { commit }, user) {
            console.log("async fetchUserProfile() starting. user=", user);
            // commit('setLoading', true);
            // console.log("store.state.user=", {...store.state.user});

            return new Promise((resolve) => {
                if (store.state.user.role !== "") {
                    // console.log("Getting userInfo from the store.");
                    let userInfo = {
                        role: store.state.user.role,
                        email: store.state.user.email,
                        name: store.state.user.name,
                        uid: store.state.user.uid,
                        subscriptions: store.state.user.subscriptions,
                        tickerInputType: store.state.user.tickerInputType
                    };
                    //  console.log("userInfo=", userInfo);
                    resolve(userInfo);
                } else {
                    console.log("Checking firebase.");
                    fb.usersCollection.doc(user.email).onSnapshot((firebaseData) => {
                        console.log("user.email=", user.email, " store.state.user.email=", store.state.user.email);
                        const userInfo = firebaseData.data();
                        console.log("userInfo=", userInfo);

                        if (userInfo && [user.email, ""].includes(store.state.user.email)) {
                            commit("setUser", userInfo);
                        }
                        resolve(userInfo);
                    });
                }
            });
        },
    }
};
