import $ from "jquery";
import { spreadDigits } from "@/js/main";
import * as am4charts from "@amcharts/amcharts4/charts";
import { volatility } from '@/js/am4charts-plugins/am4charts.main';
import moment from "moment";

function removeSeasonal(years, chart) {
    // console.log("removeSeasonal() starting. years=", years);
    if (years.length > 0) {
        years.forEach(x => {
            // console.log("removing " + x + "-year seasonal.");
            let series = chart.map.getKey(x + "-y");
            let series_adjusted = chart.map.getKey(x + "-y_adjusted");
            if (chart.series.indexOf(series) > -1) {
                chart.series.removeIndex(chart.series.indexOf(series)).dispose(); //series.hide();  
            }
            if (chart.series.indexOf(series_adjusted) > -1) {
                chart.series.removeIndex(chart.series.indexOf(series_adjusted)).dispose(); //series.hide();  
            }
        });
    }
}

function addSeasonals(seasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions/*, chartObject*/) {
   // console.log("addSeasonals() starting. seasonals=", seasonals, " chartObject=", chartObject);
    //console.log("Object.keys(chart)=", Object.keys(chart));

    return new Promise((resolve) => {
        if (Object.keys(chart).length > 0) {
            // console.log("seasonalDataArray=", seasonalDataArray);
            //  console.log("generalForm=", generalForm);
            // console.log("seasonalColorOptions=", seasonalColorOptions);
            let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
            // console.log("digits=", digits);
            let formatString = "#." + "0".repeat(digits);
            let seasonalColors = ["orange", "darksalmon", "crimson", "brown", "black"];

            //  if (typeof seasonalDataArray !== 'undefined') {
            //let selected = generalForm.selected[0];
            //  console.log("selected=", selected);

            // let digits = 1 + spreadDigits(selected);
            // console.log("digits=", digits);
            // console.log("chart=", chart);
            removeSeasonal(seasonals, chart);
            let colorOption;

            let mainData = chart.map.getKey("main-series").data;

            for (var i = 0; i < seasonals.length; i++) {
                seasonalDataArray.forEach(bar => {
                    let date = moment(bar.date, "YYYYMMDD").format("YYYY-MM-DD")
                    // console.log("bar=", bar, "date=", date);

                    let newBar = mainData.find(x => x.date === date);
                    if (typeof newBar === 'undefined' && !isNaN(bar[seasonals[i]])) {
                        newBar = {
                            date: date,
                            [seasonals[i]+'-y']: parseFloat(bar[seasonals[i]]).toFixed(digits)
                        };
                        mainData.push(newBar);
                    } else if (!isNaN(bar[seasonals[i]])) {
                        newBar = mainData.find(x => x.date === date);
                        newBar[seasonals[i]+'-y'] = parseFloat(bar[seasonals[i]]).toFixed(digits);
                        // console.log(date, " newBar=", JSON.parse(JSON.stringify(newBar)));
                    }
                });
                mainData.sort((a, b) => new Date(a.date) - new Date(b.date));
               // console.log("mainData=", JSON.parse(JSON.stringify(mainData)));

                let seasonalData = seasonalDataArray.map(x => {
                   // console.log("x=", JSON.parse(JSON.stringify(x)));
                   // console.log("parseFloat(x[seasonals[i]]).toFixed(digits)=", parseFloat(x[seasonals[i]]).toFixed(digits));
                    return {
                        date: moment(x.date, "YYYYMMDD").format("YYYY-MM-DD"),
                        [seasonals[i]]: parseFloat(x[seasonals[i]]).toFixed(digits)
                    };
                }).filter(x => !isNaN(x[seasonals[i]]));
               // console.log("seasonalData=", seasonalData);

                // console.log("generalForm=", generalForm);
                if (generalForm.seasonalTypes.includes("unadjusted")) {
                    let id = seasonals[i] + "-y";
                    var series = chart.series.push(new am4charts.LineSeries());
                    series.id = id;
                    series.dataFields.valueY = seasonals[i]+'-y';
                    series.dataFields.dateX = "date";
                    series.name = seasonals[i] + "-year seasonal:";
                    series.strokeWidth = 1;
                    colorOption = seasonalColorOptions.find(x => x.value == seasonals[i]);
                    series.stroke = typeof colorOption === 'undefined' ? seasonalColors[i] : colorOption.color;
                    series.fill = series.stroke;
                    series.fillOpacity = 0.0;
                    series.stacked = false;
                    series.legendSettings.labelText = "[{color}] {id}: {valueY}";
                    series.tooltipText = generalForm.chartParameters.balloons ? seasonals[i] + "-y: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    series.data = mainData;

                    series.events.on("rangechangeended", () => {
                        console.log("rangechangeended");
                       // chartObject.verticalZoom();
                    });
                }

                if (generalForm.seasonalTypes.includes("adjusted")) {
                    let mainDataSubset = mainData.map(bar => {
                        return {
                            date: parseInt(moment(bar.date).format("YYYYMMDD")),
                            close: bar.close
                        }
                    }).filter(x => typeof x.close !== 'undefined');
                    // console.log("mainDataSubset=", mainDataSubset);

                    let mainDataLastDate = mainDataSubset[mainDataSubset.length - 1].date;
                    // console.log("mainDataLastDate=", mainDataLastDate);
                    let mainDataStartDate = moment(mainDataLastDate, "YYYYMMDD").subtract(1, "years").format("YYYYMMDD");
                    // console.log("mainDataStartDate=", mainDataStartDate);

                    let truncatedMainData = mainDataSubset.filter(bar => {
                        //console.log("bar.date=", bar.date);
                        return moment(bar.date, "YYYYMMDD").isAfter(moment(mainDataStartDate, "YYYYMMDD"));
                    })
                    // console.log("truncatedMainData=", truncatedMainData);

                    let dailyPriceVolatility = volatility(truncatedMainData, "close");
                   //  console.log("dailyPriceVolatility=", dailyPriceVolatility);

                    let seasonalVolatility = volatility(seasonalData, seasonals[i]);
                    // console.log("seasonalVolatility=", seasonalVolatility);

                    let volatilityFactor = dailyPriceVolatility / seasonalVolatility;
                  //  console.log("volatilityFactor=", volatilityFactor);

                    let seasonalDataAdjustedForVolatility = seasonalData.map(bar => {
                        return {
                            date: bar.date,
                            adjustedForVolatilitySeasonal: volatilityFactor * parseFloat(bar[seasonals[i]])
                        }
                    });
                   // console.log("seasonalDataAdjustedForVolatility=", seasonalDataAdjustedForVolatility);

                    /*  var adjustedForVolatilitySeries = chart.series.push(new am4charts.LineSeries());
                     let adjustedForVolatilityId = seasonals[i] + "-adjustedForVolatility";
                     
                     adjustedForVolatilitySeries.id = adjustedForVolatilityId;
                     adjustedForVolatilitySeries.dataFields.valueY = "adjustedForVolatilitySeasonal";
                     adjustedForVolatilitySeries.dataFields.dateX = "date";
                     adjustedForVolatilitySeries.name = "adjustedForVolatility"+ seasonals[i] + "-year seasonal:";
                     adjustedForVolatilitySeries.strokeWidth = 1;
                     adjustedForVolatilitySeries.strokeDasharray = "1,1";
                     
                     let color = seasonalColorOptions.find(x => x.value == seasonals[i]);
                     console.log("color=", color);
                     colorOption = color;
                     adjustedForVolatilitySeries.stroke = typeof colorOption === 'undefined' ? seasonalColors[i] : colorOption.color;
                     adjustedForVolatilitySeries.fill = series.stroke;
                     adjustedForVolatilitySeries.fillOpacity = 0.0;
                     adjustedForVolatilitySeries.stacked = false;
                     adjustedForVolatilitySeries.legendSettings.labelText = "[{color}] {id}: {valueY}";
                     adjustedForVolatilitySeries.tooltipText = generalForm.chartParameters.balloons ? seasonals[i] + "-y: {valueY.value.formatNumber('" + formatString + "')}" : "";
                     adjustedForVolatilitySeries.data = seasonalDataAdjustedForVolatility; */

                    // This version uses best-fit horizontal line.

                    let seasonalDataForRegression = seasonalDataAdjustedForVolatility.map(x => {
                        //console.log("x.date=", x.date);
                        let date = parseInt(moment(x.date, "YYYY-MM-DD").format("YYYYMMDD"));
                        // console.log("date=", date);

                        let mainDataBar = mainDataSubset.find(y => y.date === date);
                        /* console.log("mainDataBar=", mainDataBar);
                         console.log("typeof mainDataBar=", typeof mainDataBar);
                         console.log("parseFloat(x[seasonals[i]])=", parseFloat(x[seasonals[i]])); */

                        return {
                            date: date,
                            [seasonals[i]+'-y_adjusted']: typeof mainDataBar !== 'undefined' ? mainDataBar.close - parseFloat(x.adjustedForVolatilitySeasonal) : null
                        };
                    }).filter(x => x[seasonals[i]+'-y_adjusted'] !== null);
                    //console.log("seasonalDataForRegression=", JSON.parse(JSON.stringify(seasonalDataForRegression)));

                    let deviationArray = seasonalDataForRegression.map(bar => bar[seasonals[i]+'-y_adjusted']).filter(x => !isNaN(x));
                    //console.log("deviationArray=", deviationArray);

                    let deviationAverage = deviationArray.reduce((sum, x) => sum + x) / deviationArray.length;
                   // console.log("deviationAverage=", deviationAverage);

                    let adjustedSeasonalData = seasonalData.map(bar => {
                        // console.log("bar=", bar);
                        // let seasonalBar = seasonalData.find(x => x.date === moment(bar.date, "YYYYMMDD").format("YYYY-MM-DD"));
                        // console.log("seasonalBar=", seasonalBar);
                        if (typeof bar !== 'undefined' && !isNaN(bar[seasonals[i]])) {
                            return {
                                date: bar.date,
                                adjustedSeasonal: parseFloat(volatilityFactor * parseFloat(bar[seasonals[i]]) + deviationAverage).toFixed(digits)
                            }
                        } else {
                            return;
                        }
                    }).filter(x => typeof x !== 'undefined');
                   // console.log("adjustedSeasonalData=", adjustedSeasonalData);

                    adjustedSeasonalData.forEach(bar => {
                        let date = moment(bar.date, "YYYYMMDD").format("YYYY-MM-DD")
                        // console.log("bar=", bar, "date=", date);

                        let newBar = mainData.find(x => x.date === date);
                        if (typeof newBar === 'undefined') {
                            newBar = {
                                date: date,
                                [seasonals[i]+'-y_adjusted']: parseFloat(bar['adjustedSeasonal']).toFixed(digits)
                            };
                            mainData.push(newBar);
                        } else {
                            newBar = mainData.find(x => x.date === date);
                            newBar[seasonals[i]+'-y_adjusted'] = parseFloat(bar['adjustedSeasonal']).toFixed(digits);
                            //  console.log(date, " newBar=", JSON.parse(JSON.stringify(newBar)));
                        }
                    });
                    mainData.sort((a, b) => new Date(a.date) - new Date(b.date));
                    // console.log("mainData=", JSON.parse(JSON.stringify(mainData)));

                    var adjustedSeries = chart.series.push(new am4charts.LineSeries());
                    let adjustedId = seasonals[i] + "-y_adjusted";

                    adjustedSeries.id = adjustedId;
                    adjustedSeries.dataFields.valueY = [seasonals[i]+'-y_adjusted'];
                    adjustedSeries.dataFields.dateX = "date";
                    adjustedSeries.name = "adjusted_" + seasonals[i] + "-year seasonal:";
                    adjustedSeries.strokeWidth = 1;
                    adjustedSeries.strokeDasharray = "1,1";

                    let color = seasonalColorOptions.find(x => x.value == seasonals[i]);
                   // console.log("color=", color);
                    colorOption = color;
                    adjustedSeries.stroke = typeof colorOption === 'undefined' ? seasonalColors[i] : colorOption.color;
                    adjustedSeries.fill = adjustedSeries.stroke;
                    adjustedSeries.fillOpacity = 0.0;
                    adjustedSeries.stacked = false;
                    adjustedSeries.legendSettings.labelText = "[{color}] {id}: {valueY}";
                    adjustedSeries.tooltipText = generalForm.chartParameters.balloons ? seasonals[i] + "-y_adjusted_: {valueY.value.formatNumber('" + formatString + "')}" : "";
                    adjustedSeries.data = mainData;

                    adjustedSeries.events.on("rangechangeended", () => {
                        console.log("rangechangeended");
                       // chartObject.verticalZoom();
                    });
                }
            }
           // chartObject.verticalZoom();
            // chartObject.initialZoom();
            resolve("Seasonals added.");
            // }
        }
    });
}

function getSeasonalData(newSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject) {
    console.log("getSeasonalData() starting. newSeasonals=", newSeasonals);
    // console.trace();
    return new Promise((resolve) => {
        if (newSeasonals.length > 0) {
            chartObject.$store.commit('incrementNumberOfAjaxCalls');
            generalForm.seasonals = newSeasonals;
            delete generalForm.browserSideOnly;
            delete generalForm.playback;
            delete generalForm.search;
            delete generalForm.indicators;
            let json = JSON.stringify(generalForm, null, 2);

            //  return new Promise((resolve) => {
            $.ajax({
                url: chartObject.$store.state.siteData.baseUrl + chartObject.$store.state.siteData.contextName + "/StandardSeasonalsServlet/",
                type: "POST",
                data: { "requestJson": json },
                success: function (data) {
                    chartObject.$store.commit('decrementNumberOfAjaxCalls');
                    // console.log("data=", data);
                    let parsedData = JSON.parse(data);
                    // console.log("seasonal parsedData=", parsedData);

                    if (typeof parsedData.dataset !== 'undefined') {
                        let returnedSeasonals = parsedData.dataset.map(x => parseInt(x.split('-')[0]));
                        // console.log("returnedSeasonals=", returnedSeasonals);

                        let additionalSeasonalDataArray = Object.keys(parsedData.values).map(date => ({ date: parseInt(date), ...parsedData.values[date] }));
                        // console.log("additionalSeasonalDataArray=", [...additionalSeasonalDataArray]);
                        let concatenatedSeries = [...seasonalDataArray, ...additionalSeasonalDataArray];
                        //console.log("concatenatedSeries=", concatenatedSeries);
                        let uniqueDates = [...new Set(concatenatedSeries.map(x => x.date))].sort();
                        // console.log("uniqueDates=", uniqueDates);

                        seasonalDataArray = uniqueDates.map(date => (
                            concatenatedSeries.filter(x => x.date == date)
                                .reduce((combinedBar, bar) => ({ ...combinedBar, ...bar }), {})
                        ));
                        //console.log("seasonalDataArray=", seasonalDataArray);
                        //   resolve(seasonalDataArray);
                        chartObject.seasonalDataArray = seasonalDataArray;
                        addSeasonals(returnedSeasonals, chart, generalForm, seasonalDataArray, seasonalColorOptions, chartObject)
                            .then(/*result*/ () => {
                               // console.log("result=", result);
                                resolve("Data retrieved and seasonals added.");
                            });
                    } else {
                        resolve("No seasonal data");
                    }
                },
                fail: function (data) {
                    console.log("ajax call failed.");
                    chartObject.$store.commit('decrementNumberOfAjaxCalls');
                    $("#progress").hide();
                    $("#button").show();
                    console.log(data);
                }
                // });
            });
        } /*else {
         chart.series.events.on("rangechangeended", () => {
         console.log("rangechangeended");
         chartObject.verticalZoom();
         });
         }*/
    });
}

function setSeasonalTooltips(chart, balloons, generalForm) {
    let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
    // console.log("digits=", digits);
    let formatString = "#." + "0".repeat(digits);

    let seasonalSeries = chart.series.values.filter(series => series.id.includes("-y"));
    console.log("seasonalSeries=", seasonalSeries);

    seasonalSeries.forEach(series => {
        series.tooltipText = balloons ? series.id + ": {valueY.value.formatNumber('" + formatString + "')}" : "";
    });
}

export { getSeasonalData, removeSeasonal, setSeasonalTooltips };