import { getSpreadExpiration, getSpreadFND } from '@/js/main';
import * as am4core from "@amcharts/amcharts4/core";
import moment from "moment";

function removeExpirationGuide(chart) {
    //  console.log("removeExpirationGuide() starting.");
    let dateAxis = chart.map.getKey("date-axis");
    let fndRange = dateAxis.axisRanges.values.filter(x => x.id === "fnd-range");
    let expirationRange = dateAxis.axisRanges.values.filter(x => x.id === "expiration-range");

    fndRange.forEach(x => dateAxis.axisRanges.removeValue(x));
    expirationRange.forEach(x => dateAxis.axisRanges.removeValue(x));
}

function addExpirationGuide(chart, generalForm) {
    // console.log("addExpirationGuide() starting.");
    removeExpirationGuide(chart);
    let spread = generalForm.selected[0];
    let expiration = getSpreadExpiration(spread);
    // console.log("expiration=", expiration);
    let expirationObject = moment(expiration, "YYYY-MM-DD").add(12, 'hours');

    let dateAxis = chart.map.getKey("date-axis");
    let fndTest = moment(getSpreadFND(spread), "YYYYMMDD").add(60, 'days').isAfter(moment(expiration, "YYYYMMDD"));
    // console.log("fndTest=", fndTest);
    let showFND = (!isNaN(getSpreadFND(spread)) && getSpreadFND(spread) <= expiration && fndTest);
    // console.log("showFND=", showFND);

    let labelText = "";
    if (showFND) {
        labelText = "First Notice Date: " + moment(getSpreadFND(spread), "YYYYMMDD").format("MMM D, YYYY") + "\n";
    }
    labelText = labelText + "Last Trading Date: " + expirationObject.format("MMM D, YYYY");
    let labelDate = moment(expiration, "YYYY-MM-DD").subtract(13, 'days');
    console.log("labelDate=", labelDate.format("YYYY-MM-DD"));

    let dummyRange = dateAxis.axisRanges.create();
    dummyRange.id = "dummy-range";
    dummyRange.date = labelDate.toDate();
    dummyRange.grid.stroke = "white";

    dummyRange.label.text = labelText;
    dummyRange.label.fill = am4core.color("#42647F");
    dummyRange.label.inside = true;
    dummyRange.label.valign = "top";
    dummyRange.label.rotation = 0;
    dummyRange.label.verticalCenter = "top";
    dummyRange.label.horizontalCenter = "right";
    dummyRange.label.fontSize = 10;
    dummyRange.label.fontWeight = "normal";

    let fndRange;
    if (showFND) {
        fndRange = dateAxis.axisRanges.create();
        fndRange.id = "fnd-range";
        fndRange.date = moment(getSpreadFND(spread), "YYYYMMDD").add(12, 'hours').toDate();
        fndRange.endDate = expirationObject.toDate();
        fndRange.grid.stroke = "#42647F";
        fndRange.grid.strokeWidth = 2;
        fndRange.grid.strokeDasharray = "2,2";
        fndRange.grid.strokeOpacity = 1;

        fndRange.axisFill.fill = am4core.color("#42647F");
        fndRange.axisFill.fillOpacity = 0.20;
    }

    let expirationRange = dateAxis.axisRanges.create();
    expirationRange.id = "expiration-range";
    expirationRange.date = expirationObject.toDate();
    expirationRange.grid.stroke = "#42647F";
    expirationRange.grid.strokeWidth = 2;
    expirationRange.grid.strokeOpacity = 1;
}

export { addExpirationGuide, removeExpirationGuide };