<template>
    <div>
        <basic-chart v-bind:ref="namespace" 
                     v-bind:namespace="namespace"  
                     v-bind:div-name="namespace+'-chartDiv'"
                     v-bind:parsedData="parsedData"></basic-chart>  

    </div>
</template>

<script>

    import basicChart from '@/components/am4charts-basic-chart';
    import {spreads} from "@/js/am4charts-plugins/am4charts.spread-bars.js";
    import moment from 'moment';
    import $ from "jquery";
   // import * as fb from "@/firebase";

    export default {
        components: {
            basicChart
        },
        mounted() {
           // console.log("stock-chart-output.vue mounted() starting. this.namespace=", this.namespace);
            this.$store.commit(this.namespace + '/setTabTitle', this.ticker);          

            if (this.activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                   // console.log("symbolArray=", this.symbolArray);
                    this.symbolArray.slice(0, this.$store.state[this.namespace].legs).forEach((x, i) => {
                       // console.log("x=", x);
                        this.getData(x, i);
                    });
            }
        },
        props: ['namespace'],
        data: function () {
            return{
                parsedData: null,
                parsedDataArray: Array(2),
                initialized: false
            }
        },
        computed: {
            ticker() {
                return this.$store.getters[this.namespace + "/ticker"];
            },
            legs() {
                return this.$store.state[this.namespace].legs;
            },
            symbolArray() {
                return this.$store.state[this.namespace].stockArray.slice(0).map(x => x.split(',')[0].replace(/\s/g, ''));
                // return this.ticker.replace(/\s+/g, '').split('+').join(', ').split('-').join(', ').split(', ');
            },
            symbol1() {
                return this.symbolArray[0];
            },
            symbol2() {
                return this.symbolArray[1];
            },
            mult1() {
                return this.$store.state[this.namespace].mult[0];
            },
            mult2() {
                return this.$store.state[this.namespace].mult[1];
            },
            activeModuleName() {
                let activeModuleName = this.$store.getters['activeModuleName'];
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            },
            truncate() {
                return this.$store.state[this.namespace].truncate;
            },
            stockDataSource(){
                return this.$store.state[this.namespace].stockDataSource;
            }
        },
        watch: {
            truncate() {
                console.log("watching truncate");
                //this.truncateData();
                console.log("symbolArray=", this.symbolArray);
                    this.symbolArray.slice(0, this.$store.state[this.namespace].legs).forEach((x, i) => {
                        console.log("x=", x);
                        this.getData(x, i);
                    });            },
            ticker: function (ticker) {
                console.log("watch ticker=", ticker);
                this.$store.commit(this.namespace + '/setTabTitle', ticker);
            },
            symbol1: function (newSymbol1, oldSymbol1) {
                console.log("watch newSymbol1=", newSymbol1, " oldSymbol1=", oldSymbol1);
                let legIndex = 0;
                this.getData(newSymbol1, legIndex);
            },
            symbol2: function (newSymbol2, oldSymbol2) {
                console.log("watch newSymbol2=", newSymbol2, " oldSymbol2=", oldSymbol2);
                let legIndex = 1;
                if (typeof newSymbol2 !== 'undefined') {
                    this.getData(newSymbol2, legIndex);
                }
            },
            mult1: function (mult1) {
                console.log("watch mult1=", mult1);
                this.update();
            },
            mult2: function (mult2) {
                console.log("watch mult2=", mult2);
                this.update();
            },
            legs: function (legs) {
                console.log("watch legs=", legs);
                console.log("symbolArray=", this.symbolArray);

                let newArray = this.$store.state[this.namespace].stockArray.slice(0, 1);
                this.$store.commit(this.namespace + '/setStockArray', newArray);

                if (legs === 1) {
                    this.update();
                } else {
                    console.log("this.newSymbol2=", this.newSymbol2);
                    if (typeof this.newSymbol2 !== 'undefined') {
                        this.getData(this.newSymbol2, 1);
                    }
                }
            },
            activeModuleName: function (activeModuleName) {
               // console.log("activeModuleName=", activeModuleName, " namespace=", this.namespace, " this.initialized=", this.initialized);
                if (activeModuleName === this.namespace && !this.initialized) {
                    this.initialized = true;
                    //this.getData();
                    console.log("symbolArray=", this.symbolArray);
                    this.symbolArray.slice(0, this.$store.state[this.namespace].legs).forEach((x, i) => {
                       // console.log("x=", x);
                        this.getData(x, i);
                    });
                }
            },
            stockDataSource: function (stockDataSource) {
                console.log("watch stockDataSource=", stockDataSource);
                console.log("symbolArray=", this.symbolArray);
                    this.symbolArray.slice(0, this.$store.state[this.namespace].legs).forEach((x, i) => {
                        console.log("x=", x);
                        this.getData(x, i);
                    });                   },
        },
        methods: {
            getData(ticker, legIndex) {
               // console.log("stock-chart-output.vue getData() starting. ticker=", ticker);
                this.$store.commit('resetNumberOfAjaxCalls');
                this.$store.commit('incrementNumberOfAjaxCalls');
               // console.log("symbolArray=", this.symbolArray);

               // let open = moment().subtract(this.$store.state[this.namespace].truncate, 'years').format("YYYY-MM-DD");
                // console.log("open=", open);

               // let url = "https://app.charts.alpha4all.it/rapi.php?symbols=" + ticker.split(',')[0].replace(/\s/g, '') + "&start=" + open;
                let url = "https://app.charts.alpha4all.it/history_y.php?symbol="+ticker;
                console.log("calling alpha4all.it server for ", ticker);
               // console.log("url=", url);
                let that = this;
                $.ajax({
                    url: url,
                    type: "POST",
                    success: function (data) {
                        that.$store.commit('decrementNumberOfAjaxCalls');
                       // console.log("data=", data);
                        let parsedData = JSON.parse(data);
                        parsedData.values = parsedData.data;
                       // console.log("parsedData=", parsedData);
                       // console.log("parsedData.values=", parsedData.values);
                        if (parsedData.values !== null) {
                            parsedData.values.forEach(x => {
                                x.date = moment(x.Date).format("YYYY-MM-DD");
                                x.open = x.Open;
                                x.high = x.High;
                                x.low = x.Low;
                                x.close = x.Close;
                                x.volume = x.Volume;

                                delete x.Date;
                                delete x["Adj Close"];
                                delete x.Open;
                                delete x.High;
                                delete x.Low;
                                delete x.Close;
                                delete x.Volume;
                                return x;
                            });

                           // parsedData.title = that.stock;
                           // console.log("parsedData=", parsedData);
                            that.parsedDataArray[legIndex] = parsedData;
                           console.log("that.parsedDataArray=", JSON.parse(JSON.stringify(that.parsedDataArray)));

                            that.update();
                        }
                    },
                    fail: function (data) {
                        that.$store.commit('decrementNumberOfAjaxCalls');
                        console.log("ajax call failed.");
                        $("#progress").hide();
                        $("#button").show();
                        console.log(data);
                    }
                });
            },
            update() {
                console.log("update() starting.");

                /*    console.log("this.$store.state[this.namespace].stockArray=", JSON.stringify(this.$store.state[this.namespace].stockArray));
                 console.log("this.oldStockArray=", JSON.stringify(this.oldStockArray));
                 if (JSON.stringify(this.$store.state[this.namespace].stockArray) !== JSON.stringify(this.oldStockArray)) {
                 console.log("this.$refs=", this.$refs);
                 let parsedDataArray = Object.values(this.$refs).slice(0, this.$store.state[this.namespace].legs).map(x => x[0].parsedData);
                 console.log("parsedDataArray=", JSON.parse(JSON.stringify(parsedDataArray))); */

                let ready = !this.parsedDataArray.some(x => typeof x.values === 'undefined');
               // console.log("ready=", ready);

                if (!ready) {
                   // console.log("Waiting for data from other leg.");
                } else {
                   // console.log("Calculating spread.");

                    let combinedSeries = [];
                    let series = this.parsedDataArray.slice(0, this.$store.state[this.namespace].legs).map(x => x.values);
                    console.log("series=", series);

                    series.forEach(function (x, index) {
                        console.log("index=", index);
                       // console.log("x=", x);

                        x.forEach(z => {
                            // console.log("z=", z);
                            let dailyBar = {...z};
                           // dailyBar.date = dailyBar.tradingDay;
                            if (index == 0) {
                                let open = [], high = [], low = [], close = [];

                                open.push(dailyBar.open);
                                high.push(dailyBar.high);
                                low.push(dailyBar.low);
                                close.push(dailyBar.close);

                                dailyBar["open"] = open;
                                dailyBar["high"] = high;
                                dailyBar["low"] = low;
                                dailyBar["close"] = close;

                                /*   if (series.length === 1) {
                                 dailyBar["volume"] = dailyBar.volume;
                                 dailyBar["openInterest"] = dailyBar.openInterest;
                                 }   */
                                combinedSeries.push(dailyBar);
                            } else {
                                let obj = combinedSeries.find(x => x.date === dailyBar.date);
                                if (typeof obj !== 'undefined') {
                                    obj.open.push(dailyBar.open);
                                    obj.high.push(dailyBar.high);
                                    obj.low.push(dailyBar.low);
                                    obj.close.push(dailyBar.close);
                                }
                            }
                        });
                    });
                   // console.log("series=", series);
                    console.log("combinedSeries=", combinedSeries);
                    let mult = this.$store.state[this.namespace].mult;
                    let p = this.$store.state[this.namespace].p;
                    let totalMult = [];
                    for (let leg = 0; leg < 2; leg++) {
                        totalMult[leg] = mult[leg] * p[leg];
                    }
                    let spreadSeries = spreads(combinedSeries, totalMult);
                   // console.log("spreadSeries=", spreadSeries);

                    let parsedData = {};
                    parsedData.values = spreadSeries;
                    parsedData.title = "spread title";
                    this.parsedData = parsedData;
                   // console.log("this.parsedData=", this.parsedData);

                    $('#chartdiv-unauthorized').empty();
                }
            }
        }
    };

</script>
