let tradeExplorerInitialState = {
    "sampleContract": ["S2020H", "C2020K", "C2020N", "C2020Z"],
    user: "A4A-user0",
    password: "M1-K8xQgmR",
    intracommodity: false,
    program: "TradeExplorer",
    tabTitle: "trade explorer",
    chartParameters: {
        "page": 1,
        chartsPerRow: 2,
        rowsPerPage: 20,
        chartsPerPage: 4,
        balloons: true,
    },
    "legs": 1,
    group: "grains",
    search: {
        sortParameter: "rrr",
        legs: "1 or 2",
        position: "either",
        direction: "desc",
        source: "group",
        minDays: 10,
        maxDays: 100,
        openStart: "",
        openEnd: "",
        closeStart: "",
        closeEnd: "",
        tradePeriodExcludedSelector: "endingRange",
        userConditions: [
            {
                parameter: "appd",
                comparator: ">",
                value: 0
            }
        ],
        query: "select SQL_CALC_FOUND_ROWS trade.* FROM data.trades2 AS trade INNER JOIN (SELECT ticker, max(rrr) AS rrr FROM data.trades2 where  open BETWEEN '2022-08-25' AND '2022-09-01' AND  DATEDIFF(close, open ) >= 10 and DATEDIFF(close, open ) <= 100 and (   ticker like 'C-%' or ticker like 'KW-%' or ticker like 'MW-%' or ticker like 'O-%' or ticker like 'SM-%' or ticker like 'BO-%' or ticker like 'S-%' or ticker like 'W-%' or ticker like 'RR-%' )  and appd > 0 GROUP BY ticker) AS trade2 ON trade.ticker = trade2.ticker AND trade.rrr = trade2.rrr HAVING  open BETWEEN '2022-08-25' AND '2022-09-01' AND  DATEDIFF(close, open ) >= 10 and DATEDIFF(close, open ) <= 100 and (   ticker like 'C-%' or ticker like 'KW-%' or ticker like 'MW-%' or ticker like 'O-%' or ticker like 'SM-%' or ticker like 'BO-%' or ticker like 'S-%' or ticker like 'W-%' or ticker like 'RR-%' )  and appd > 0 ORDER BY rrr desc LIMIT 0 , 20;"
    }
};

export {tradeExplorerInitialState};