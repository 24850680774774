import Vue from "vue";
import Vuex from "vuex";
import { vuexfireMutations } from 'vuexfire';

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import user from "./user";
import generalFormModulePlaybackPage from "./generalFormModule";

import siteData from "./siteDataModule";
import settings from './settings';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        htmlClass,
        config,
        breadcrumbs,
        user,
        settings,
        siteData,
        generalFormModulePlaybackPage
    },
    state: {
        activeModuleIndex: null,
        showStateEditor: false,
        tabsInitialized: false,
        loading: false,
        numberOfAjaxCalls: 0
    },
    getters: {
        moduleArray(state) {
            //  console.log("state=", JSON.parse(JSON.stringify(state)));
            //  console.log("Object.values(state)=", Object.values(JSON.parse(JSON.stringify(state))));
            let arr = Object.values(state)
                .filter(module => module !== null && typeof module.moduleName !== 'undefined'
                    && module.moduleName !== null && module.moduleName.includes("generalFormTab"));

            // console.log("arr=", arr);
            return arr;
        },
        activeModule(state, getters) {
            //  console.log("activeModule getter starting.");
            let moduleArray = getters['moduleArray'];

            if (typeof moduleArray[state.activeModuleIndex] !== 'undefined') {
                let activeModule = moduleArray[state.activeModuleIndex];
                // console.log("activeModule=", activeModule);
                return activeModule;
            } else {
                return null;
            }
        },
        activeModuleName(state, getters) {
            //  console.log("activeModuleName getter starting.");
            let moduleArray = getters['moduleArray'];

            if (typeof moduleArray[state.activeModuleIndex] !== 'undefined') {
                let activeModuleName = moduleArray[state.activeModuleIndex].moduleName;
                // console.log("activeModuleName=", activeModuleName);
                return activeModuleName;
            } else {
                return null;
            }
        },
        nextModuleName(state, getters) {
            let nextModuleId;
            let moduleArray = getters['moduleArray'];
            if (moduleArray.length === 0) {
                nextModuleId = 0;
            } else {
                let ids = moduleArray.map(x => parseInt(x.moduleName.replace('generalFormTab', '')));
                // console.log("ids=", ids);
                nextModuleId = Math.max(...ids) + 1;
                // console.log("nextModuleId=", nextModuleId);
            }
            return "generalFormTab" + nextModuleId;
        }
    },
    mutations: {
        setActiveModuleIndex: function (state, activeModuleIndex) {
            // console.log("setActiveModuleIndex() starting. activeModuleIndex=", activeModuleIndex);
            // console.trace();
            state.activeModuleIndex = activeModuleIndex;
        },
        setShowStateEditor: function (state, showStateEditor) {
            state.showStateEditor = showStateEditor;
        },
        setTabsInitialized: function (state, tabsInitialized) {
            state.tabsInitialized = tabsInitialized;
        },
        /*  setLoading: function (state, loading) {
              state.loading = loading;
          },  */
        incrementNumberOfAjaxCalls(state) {
            state.numberOfAjaxCalls++;
        },
        decrementNumberOfAjaxCalls(state) {
            // console.log("state.numberOfAjaxCalls=", state.numberOfAjaxCalls);
            let num = state.numberOfAjaxCalls > 0 ? state.numberOfAjaxCalls - 1 : 0;
            // console.log("num=", num);
            state.numberOfAjaxCalls = num;
        },
        resetNumberOfAjaxCalls(state) {
            state.numberOfAjaxCalls = 0;
        },
        ...vuexfireMutations
    },
    actions: {}
})
