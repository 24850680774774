let tradeMapsInitialState = {
    "instrument": "future",
    "p": [1, -1, 1, 1],
    "unitMove": [50, 50, 50, 50],
    "sampleContract": ["S2021H", "S2021U", "C2021H", "C2022K"],
    selected: [],
    "mult": [1, 1, 1, 1],
    "legs": 1,
    "y1": 15,
      user: "A4A-user0",
    password: "M1-K8xQgmR",
    program: "TradeMaps",
    constrainContractAligner: true,
    aligned: true,
    chartParameters: {
        balloons: true,
        parameterToMap: "percentUp",
        initialZoom: true,
        hiddenFeatures: {
            expiration: false,
            title: false,
            legends: false,
            horizontalScrollbar: true,
            verticalScrollbar: true
        },
    },
    search: {
        sortParameter: "rrr",
        legs: 2,
        position: "either",
        direction: "desc",
        source: "singleCommodity",
        minDays: 2,
        maxDays: 100,
        userConditions: [
            {
                parameter: "percentProfitable",
                comparator: ">",
                value: 70
            }
        ],
    },
    "language": "en",
    "addBollinger": false,
    "addRSIPanel": false,
    "addMACDPanel": false,
    "addATRPanel": false,
    "addVolumePanel": false,
    "addVolumeProfile": false,
    "addCCIPanel": false,
    "showTradingPeriod": true,
    "overwriteShow": false,
    "showBullets": true,
    "showSeasonals": true,
    "intracommodity": true,
    "stockGraphType": "line",
    "seasonals": [5, 15],
    balloons: false,
    truncate: 1  // Making this larger causes the trading period on the basic chart to be misplaced!
};

export {tradeMapsInitialState};