
function spreads(series, totalMult) {
    //  console.log("series=", series);
    console.log("totalMult=", totalMult);
    // let selected = programVue.$store.state.generalForm.selected[0];
    //  console.log("selected=", selected);
    let digits = 6; //spreadDigits(selected);
   // console.log("digits=", digits);

    let arr = [];
    series.forEach(x => {
        //console.log(x);
        let open = 0.0;
        let close = 0.0;
        let high = 0.0;
        let low = 0.0;

        let opens = [];
        let closes = [];
        let highs = [];
        let lows = [];

        let volume = 0;
        let openInterest = 0;

        let legs = series[0]["close"].length;
        // console.log("legs=", legs);

        for (var leg = 0; leg < legs; leg++) {
            opens[leg] = totalMult[leg] * x.open[leg];
            closes[leg] = totalMult[leg] * x.close[leg];

            if (totalMult[leg] > 0) {
                highs[leg] = totalMult[leg] * x.high[leg];
                lows[leg] = totalMult[leg] * x.low[leg];
            } else {
                lows[leg] = totalMult[leg] * x.high[leg];
                highs[leg] = totalMult[leg] * x.low[leg];
            }
            if (legs == 1) {
                volume = x.volume;
                openInterest = x.openInterest;
            }
        }
        open = opens.reduce((a, b) => a + b, 0);
        high = highs.reduce((a, b) => a + b, 0);
        low = lows.reduce((a, b) => a + b, 0);
        close = closes.reduce((a, b) => a + b, 0);
       // console.log(x.date, "  opens=", opens + " open=", open);

        let newHighs = [];
        let newLows = [];
        for (leg = 0; leg < legs; leg++) {
            newHighs[leg] = low - lows[leg] + highs[leg];
            newLows[leg] = high - highs[leg] + lows[leg];
        }

        let max = Math.max(...newHighs);
        let min = Math.min(...newLows);

        if (max > open && max > close) {
            high = max;
        } else {
            high = Math.max(open, close);
        }

        if (min < open && min < close) {
            low = min;
        } else {
            low = Math.min(open, close);
        }

        let obj = {
            date: x.date,
            open: open.toFixed(digits),
            high: high.toFixed(digits),
            low: low.toFixed(digits),
            close: close.toFixed(digits),
            volume: volume,
            openInterest: openInterest
        };
        arr.push(obj);
    });
    return arr;
}

export {spreads};
