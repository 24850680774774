import {heightControl, removeNaNs, spreadDigits} from '@/js/main';
import {md} from '@/js/am4charts-plugins/am4charts.main';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const cci = {
    mounted() {
       // console.log("mixins/cci.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        cciIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "cci");
        },
        cciVisible() {
            return typeof this.cciIndicator !== 'undefined' ? this.cciIndicator.visible : null;
        },
        cciPeriod() {
            return typeof this.cciIndicator !== 'undefined' ? this.cciIndicator.period : null;
        },
        cciSource() {
            return typeof this.cciIndicator !== 'undefined' ? this.cciIndicator.source : null;
        }
    },
    watch: {
        cciVisible: function (visible) {
            console.log("watch visible=", visible);
            if (visible) {
                this.addCciPanel();
            } else {
                this.removeCciPanel();
            }
        },
        cciPeriod: function (period) {
            console.log("watch period=", period);
            if(this.cciVisible){
                this.addCciPanel();
            }
        },
        cciSource: function (source) {
            console.log("watch source=", source);
            if(this.cciVisible){
                this.addCciPanel();
            }
        }
    },
    methods: {
        cci(objectArray, period) {
            console.log("cci() starting. period=", period);
            // console.log("objectArray[objectArray.length - 1]=", JSON.parse(JSON.stringify(objectArray[objectArray.length - 1])));

            let filteredKeys;
            md(objectArray, period, "typical");
            // console.log("objectArray=", JSON.parse(JSON.stringify(objectArray)));

            objectArray.forEach(x => {
                let cci = (x.typical - x["sma_" + period]) / (0.015 * x["typical_md_" + period]);
               // console.log("cci=", cci);
                if (!isNaN(cci) && isFinite(Math.abs(cci))) {
                    x.cci = cci;
                }

                let keys = Object.keys(x);
                //console.log("keys=", keys);
                filteredKeys = keys.filter(x => x.includes("typical"));
                //console.log("filteredKeys=", filteredKeys);
                filteredKeys.forEach(key => {
                    delete x[key];
                });
                delete x["sma_" + period];
            });
            // console.log("objectArray=", objectArray);
            return objectArray;
        },
        typicalPrice(objectArray, cciSource, legs) {
            // console.log("typicalPrice() starting.");
            // console.log("objectArray=", objectArray);
            // console.log("cciSource=", cciSource);
            for (let j = 0; j < objectArray.length; j++) {
                let obj = objectArray[j];
                let typical = (obj.high + obj.low + obj.close) / 3;
                obj["typical"] = (cciSource === "typical" && legs == 1) ? typical : obj.close;
            }
        },
        createCciGuides() {
            let valueAxis = this.$options.chart.map.getKey("cci-axis");

            let lowerRange = valueAxis.axisRanges.create();
            lowerRange.value = -100;
            lowerRange.grid.stroke = "#505050";
            lowerRange.grid.strokeWidth = 1;
            lowerRange.grid.strokeDasharray = "2,2";
            lowerRange.grid.strokeOpacity = 1;
            lowerRange.label.inside = true;
            lowerRange.label.text = "-100";
            lowerRange.label.fill = "#505050";
            lowerRange.label.align = "right";
            lowerRange.label.verticalCenter = "bottom";

            let upperRange = valueAxis.axisRanges.create();
            upperRange.value = 100;
            upperRange.grid.stroke = "#505050";
            upperRange.grid.strokeWidth = 1;
            upperRange.grid.strokeDasharray = "2,2";
            upperRange.grid.strokeOpacity = 1;
            upperRange.label.text = "100";
            upperRange.label.fill = "#505050";
            upperRange.label.align = "right";
            upperRange.label.verticalCenter = "bottom";
        },
        removeCciPanel() {
          //  console.log("removeCciPanel() starting.");
            this.$options.chart.series.removeIndex(this.$options.chart.series.indexOf(this.$options.chart.map.getKey("cci-series"))).dispose();
            this.$options.chart.yAxes.removeIndex(this.$options.chart.yAxes.indexOf(this.$options.chart.map.getKey("cci-axis"))).dispose();

            heightControl("removing", this.$options.chart);
        },
        addCciPanel() {
            console.log("AddCciPanel() starting.");
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));
           // console.log("generalForm=", generalForm);

            let digits = spreadDigits(generalForm.selected[0], generalForm.instrument);
            // console.log("digits=", digits);
            let formatString = "#." + "0".repeat(digits);

            let mainData = this.$options.chart.map.getKey("main-series").data;
            this.typicalPrice(removeNaNs(mainData), this.cciSource, generalForm.legs);
            console.log("this.cciPeriod=", this.cciPeriod);
            let cciData = this.cci(mainData, this.cciPeriod);
            console.log("cciData=", cciData);

            let valueAxis = this.$options.chart.map.getKey("cci-axis");
            let series = this.$options.chart.map.getKey("cci-series");
            let alreadyPresent = typeof valueAxis !== 'undefined';
             console.log("alreadyPresent=", alreadyPresent);

            if (!alreadyPresent) {
                valueAxis = this.$options.chart.yAxes.push(new am4charts.ValueAxis());
                let axisPercentage = heightControl("adding", this.$options.chart);
                valueAxis.height = am4core.percent(axisPercentage);

                series = this.$options.chart.series.push(new am4charts.LineSeries());
            }

            valueAxis.id = "cci-axis";
            valueAxis.tooltip.disabled = true;
            valueAxis.zIndex = 3
            // this makes gap between panels
            valueAxis.marginTop = 16;
            valueAxis.renderer.baseGrid.disabled = true;
            valueAxis.renderer.inside = true;
            valueAxis.renderer.labels.template.verticalCenter = "bottom";
            valueAxis.renderer.labels.template.padding(2, 2, 2, 2);
            //valueAxis.renderer.maxLabelPosition = 0.95;
            valueAxis.renderer.fontSize = "0.8em"
            valueAxis.renderer.gridContainer.background.fillOpacity = 0.05;
            valueAxis.title.text = this.cciPeriod + "-day CCI";
            valueAxis.title.fontWeight = "bold";
            valueAxis.zoomable = false;

            series.id = "cci-series";
            series.data = cciData;
            series.dataFields.dateX = "date";
            series.clustered = false;
            series.dataFields.valueY = "cci";
            series.yAxis = valueAxis;
            series.tooltipText = generalForm.chartParameters.balloons ? "cci: {valueY.value.formatNumber('" + formatString + "')}" : "";
            series.name = "CCI";
            series.hiddenInLegend = true;
            series.legendSettings.labelText = "[{color}] cci: {valueY.value.formatNumber('" + formatString + "')}";
            series.stroke = "blue";
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = "blue";

            this.createCciGuides();
        },
        setCciTooltips() {
            console.log("setCciTooltips() starting.");
            let formatString = "#." + "0".repeat(2);
            console.log("formatString=", formatString);
        
            let series = this.$options.chart.map.getKey("cci-series");
            // console.log("rsiSeries=", series);
            if (typeof series !== 'undefined') {
                series.tooltipText = this.balloons ? "cci: {valueY.value.formatNumber('" + formatString + "')}" : "";
            }
        }                

    }
};

export default cci;