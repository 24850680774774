import moment from 'moment';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const vp = {
    mounted() {
       // console.log("mixins/volumeProfile.js mounted() starting. this.namespace=", this.namespace);
    },
    computed: {
        vpIndicator() {
            return this.$store.state[this.namespace].indicators.indicators.find(x => x.id === "vp");
        },
        vpVisible() {
            return typeof this.vpIndicator !== 'undefined' ? this.vpIndicator.visible : null;
        },
        vpColumns() {
            return typeof this.vpIndicator !== 'undefined' ? this.vpIndicator.columns : null;
        }
    },
    watch: {
        vpVisible: function (visible) {
            console.log("watch visible=", visible);
            visible ? this.addVolumeProfileIndicator() : this.removeVolumeProfile();
        },
        vpColumns: function (columns) {
            console.log("watch columns=", columns);
            this.updateVolumeProfile();
        }
    },
    methods: {
        updateVolumeProfile() {
            console.log("updateVolumeProfile() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            if (this.vpVisible && generalForm.legs == 1) {
                let volumeProfileSeries = chart.map.getKey("volumeProfile-series");
                if (typeof volumeProfileSeries !== 'undefined') {
                    volumeProfileSeries.data = this.generateVolumeProfileData(chart, generalForm);
                }
            }
        },
        generateVolumeProfileData() {
            console.log("generateVolumeProfileData() starting.");
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            if (this.vpVisible && generalForm.legs == 1) {
                let dateAxis = chart.map.getKey("date-axis");
                // console.log("dateAxis=", dateAxis);
                // console.log("moment(dateAxis.minZoomed)=", moment(dateAxis.minZoomed).format("YYYY-MM-DD"));
                //  console.log("dateAxis.maxZoomed=", new Date(dateAxis.maxZoomed));
                // console.log("generalForm.chartParameters.start=", generalForm.chartParameters.start);
                //  console.log("generalForm.chartParameters.end=", generalForm.chartParameters.end);

                let start, end;
                if (dateAxis.minZoomed !== null) {
                    start = new Date(dateAxis.minZoomed);
                }
                if (dateAxis.maxZoomed !== null) {
                    end = new Date(dateAxis.maxZoomed);
                }
                // console.log("range: " + start + " -- " + end);

                if (dateAxis.minZoomed === null) {
                    start = moment(generalForm.chartParameters.start);
                    //    console.log("generalForm.chartParameters.start=", generalForm.chartParameters.start);
                }
                if (dateAxis.maxZoomed === null) {
                    end = moment(generalForm.chartParameters.end);
                    //    console.log("generalForm.chartParameters.end=", generalForm.chartParameters.end);
                    //  console.log("New range: " + start + " -- " + end);
                }
                // console.log("range: " + moment(start).toString() + " -- " + moment(end).toString());

                let series = chart.map.getKey("main-series");
                let truncatedData;
                if (start !== "" && end !== "" && typeof start !== 'undefined' && typeof end !== 'undefined' && !isNaN(start) && !isNaN(end)) {
                    // console.log("start=", start);
                    // console.log("end=", end);
                    //  console.log("series.data=", JSON.parse(JSON.stringify(series.data)));

                    truncatedData = series.data.filter(x => !moment(x.date).isBefore(moment(start)) && !moment(x.date).isAfter(moment(end)));
                } else {
                    truncatedData = series.data;
                }
                // console.log("truncatedData=", JSON.parse(JSON.stringify(truncatedData)));

                let filteredData = truncatedData.filter(x => typeof x.volume !== 'undefined');
                if (filteredData.length > 0) {
                    // let totalVolume = filteredData.reduce(this.getSum, 0);
                    //  console.log("totalVolume=", totalVolume);

                    let maxPrice = filteredData.reduce((max, bar) => Math.max(max, bar.close), filteredData[0].close) + 0.000000000001;
                    let minPrice = filteredData.reduce((min, bar) => Math.min(min, bar.close), filteredData[0].close);
                    //  console.log("maxPrice=", maxPrice, "   minPrice=", minPrice);
                    let priceRange = maxPrice - minPrice;
                    let priceIncrement = priceRange / this.vpColumns;

                    let volumeProfileData = [];
                    for (let n = 0; n < this.vpColumns; n++) {
                        let obj = {};
                        obj.lowerBound = minPrice + n * priceIncrement;
                        obj.upperBound = obj.lowerBound + priceIncrement;
                        volumeProfileData.push(obj);
                    }

                    volumeProfileData.forEach(function (obj) {
                        let binData = filteredData.filter(x => x.close >= obj.lowerBound && x.close < obj.upperBound);
                        let binVolume = binData.reduce((sum, bar) => sum + bar.volume, 0);
                        obj.volume = binVolume;
                    });
                    console.log("volumeProfileData=", volumeProfileData);
                    // let sumOfBinVolumes = volumeProfileData.reduce((sum, bar) => sum + bar.volume, 0);
                    // console.log("sumOfBinVolumes=", sumOfBinVolumes);

                    return volumeProfileData;
                }
            }
        },
        removeVolumeProfile() {
            console.log("removeVolumeProfile() starting.");
            let chart = this.$options.chart;

            let series = chart.map.getKey("volumeProfile-series");
            let volumeProfileAxis = chart.map.getKey("volumeProfile-axis");

            if (typeof series !== 'undefined') {
                series.hide();
                volumeProfileAxis.hide();
            }
        },
        addVolumeProfileIndicator() {
            console.log("addVolumeProfileIndicator() starting.");
            // console.trace();
            let chart = this.$options.chart;
            let generalForm = JSON.parse(JSON.stringify(this.$store.state[this.namespace]));

            if (this.vpVisible && generalForm.legs == 1) {
                let formatString = "#,###.";
                let volumeProfileData = this.generateVolumeProfileData();

                let mainAxis = chart.map.getKey("main-axis");
                let volumeProfileAxis = chart.map.getKey("volumeProfile-axis");

                let alreadyPresent = typeof volumeProfileAxis !== 'undefined';
                console.log("alreadyPresent=", alreadyPresent);

                let volumeProfileSeries = chart.map.getKey("volumeProfile-series");

                if (!alreadyPresent) {
                    volumeProfileAxis = chart.xAxes.push(new am4charts.ValueAxis());
                    volumeProfileAxis.renderer.inversed = true;
                    volumeProfileAxis.id = "volumeProfile-axis";
                    volumeProfileAxis.width = am4core.percent(30); // how much of the plot area the volume axis will occupy
                    volumeProfileAxis.align = "right";
                    volumeProfileAxis.renderer.inside = true;
                    volumeProfileAxis.renderer.grid.template.disabled = true;
                    volumeProfileAxis.renderer.labels.template.disabled = false;
                    volumeProfileAxis.tooltip.disabled = true;
                    // volumeProfileAxis.events.disable();
                    volumeProfileAxis.zoomable = false;

                    volumeProfileAxis.renderer.line.strokeOpacity = 1;
                    volumeProfileAxis.renderer.line.strokeWidth = 2;
                    volumeProfileAxis.renderer.line.stroke = am4core.color("#A2B5CD");
                    volumeProfileAxis.renderer.opposite = true;
                    volumeProfileAxis.renderer.fontSize = "0.8em";

                    volumeProfileAxis.renderer.ticks.template.disabled = false;
                    volumeProfileAxis.renderer.ticks.template.strokeOpacity = 1;
                    volumeProfileAxis.renderer.ticks.template.stroke = am4core.color("#A2B5CD");
                    volumeProfileAxis.renderer.ticks.template.strokeWidth = 2;
                    volumeProfileAxis.renderer.ticks.template.length = 10;

                    volumeProfileAxis.renderer.grid.template.strokeOpacity = 1;
                    volumeProfileAxis.renderer.grid.template.stroke = am4core.color("#A2B5CD");
                    volumeProfileAxis.renderer.grid.template.strokeWidth = 1;

                    //volumeProfileAxis.numberFormatter.numberFormat = "#,###.";  // This causes bad numbering of the mainAxis labels.

                    volumeProfileSeries = chart.series.push(new am4charts.ColumnSeries())
                    volumeProfileSeries.id = "volumeProfile-series";
                    volumeProfileSeries.data = volumeProfileData;
                    volumeProfileSeries.baseAxis = mainAxis;
                    volumeProfileSeries.xAxis = volumeProfileAxis;
                    volumeProfileSeries.dataFields.valueX = "volume"
                    volumeProfileSeries.dataFields.openValueY = "lowerBound"
                    volumeProfileSeries.dataFields.valueY = "upperBound"
                    volumeProfileSeries.columns.template.column.paddingTop = 0;
                    volumeProfileSeries.columns.template.column.paddingBottom = 0;
                    volumeProfileSeries.fill = "#A2B5CD";
                    volumeProfileSeries.stroke = "#50729F";
                    volumeProfileSeries.fillOpacity = 0.3;
                    volumeProfileSeries.columns.template.tooltipText = generalForm.chartParameters.balloons ? "volume:  {valueX.value.formatNumber('" + formatString + "')}[/]" : "";
                    volumeProfileSeries.hiddenInLegend = true;
                } else {
                    volumeProfileSeries.show();
                    volumeProfileAxis.show();
                    volumeProfileSeries.invalidateRawData();
                }
            }
        },
        setVolumeProfileTooltips() {
            let chart = this.$options.chart;
            let formatString = "#,###.";
            let series = chart.map.getKey("volumeProfile-series");
            console.log("series=", series);
            if (typeof series !== 'undefined') {
                series.columns.template.tooltipText = this.balloons ? "volume: {valueX.value.formatNumber('" + formatString + "')}[/]" : "";
            }
        }
    }
};

export default vp;






